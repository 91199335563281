import React, { useState, useEffect } from "react";
import {
  Radio,
  Row,
  message,
  Button,
  Popconfirm,
  Card,
  Dropdown,
  Menu,
  Empty,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import moment from "moment";
import axios from "axios";
import { connect, useDispatch , useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import NotificationSettingModal from "../NotificationSettingModal/NotificationSettingModal"
import SelectAssignee from "components/shared/SelectAssignee/SelectAssignee";
import {
  primaryColor,
  totalNumberBackgroundColor,
  totoalNumberTextColor,
} from "colors-config";
import { addReadCount, changeCommentBoxState,updateReadCount } from "services/redux/actions/labActions";
import { staffDropDowSelector } from "services/redux/selectors/labStaff.selector";
import Search from "./Search";
import "../../../assets/styles/components/notificationsComponent.scss";
import { ReactComponent as Settings } from "assets/images/Settings.svg";
import { getStaffMemberByPatientId, assignStaffMember } from "api/staff";
import { assignStaffToNotificationAPI, unAssignStaffToNotificationAPI } from "api/notifications";

const NotificationsComponent = ({
  notificationsArray,
  lab,
  setFilterVal,
  getNotifications,
  totalNotifications,
  setClinicIds,
  clinicList,
  filterVal,
  setSecondaryFilter,
}) => {
  const history = useHistory();
  const [readNotifications, setReadNotifications] = useState([]);
  const [notifications, setNotifications] = useState(notificationsArray || []);
  const [openMarkAllButton, setOpenMarkAllButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  let [deleteFileOpen, setDeleteFileOpen] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [assigneeLoading, setAssigneeLoading] = useState(false);
  const labStaffMembers = useSelector(staffDropDowSelector);


  const handleFileOk = () => {
    updateAllReadStatus();
    setDeleteFileOpen(false);
    dispatch(updateReadCount(0));
  };


  const handleFileCancel = () => {
    setDeleteFileOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setNotifications(notificationsArray);
  }, [notificationsArray]);

  const markRead = (index, createdDate, id, cause, message) => {
    var arr = [...readNotifications];
    if (!arr.includes(index)) {
      arr.push(index);
      setReadNotifications(arr);
    }
    updateReadStatus(createdDate);
    dispatch(updateReadCount())
    if (cause === "partner") {
      if (notifications[index].actionFlag === "PARTNERSHIP_REQUEST") {
        history.push({
          pathname: "/lab/partners",
          state: { activeTab: "REQUESTS" },
        });
      } else {
        history.push("/lab/partners");
      }
    } else if (
      cause === "treatment_setup" ||
      message.includes("refinement requested")
    ) {
      // history.push(`/lab/patient/${id}`);
      const location = {
        pathname: `/lab/patient/${id}`,
        state: { currentActiveTab: "treatment" },
      };
      history.push(location);
    } else if (cause === "prescription") {
      const location = {
        pathname: `/lab/patient/${id}`,
        state: { currentActiveTab: "prescription" },
      };
      history.push(location);
    } else if (cause === "order") {
      const location = {
        pathname: `/lab/patient/${id}`,
        state: { currentOrderTypeMenuSelected: "aligners" },
      };
      history.push(location);
    } else if (cause === "comment") {
      dispatch(changeCommentBoxState(true));
      history.push(`/lab/patient/${id}`);
    }
    else{
      history.push(`/lab/patient/${id}`);
    }
  };
  const updateReadStatus = async (date, status = 1) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_NOTIFICATION_URL}notifications/updateReadStatus`,
        {
          user_id: lab.labId,
          notification_created_date: date,
          status: status,
        }
      );
      getNotifications();
    } catch (error) {
      console.log(error);
    }
  };

  const updateAllReadStatus = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_NOTIFICATION_URL}notifications/updateAllReadStatus`,
        { user_id: lab.labId }
      );
      getNotifications();
    } catch (error) {
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleMenuClick = (event, createdDate) => {
    const name = event?.domEvent?.target?.textContent;
    if (name === "Mark As UnRead") {
      dispatch(addReadCount())
      updateReadStatus(createdDate, 0);

    } else if (name === "Mark As Read") {
    
      updateReadStatus(createdDate, 1);
      dispatch(updateReadCount())
    } else if (name == "Mark All As Read") {
     
      setDeleteFileOpen(true);
    }
  };

  const onNotificationCancel = () => {
    setOpenSettingsModal(false);
  };

  const showNotificationModal = () => {
    setOpenSettingsModal(true);
  };

  const showPopconfirm = () => {
    setOpenMarkAllButton(true);
  };

  const handleCancel = () => {
    setOpenMarkAllButton(false);
  };

  const handleMarkAsReadConfirm = () =>{

    handleFileOk()
  }

  const reassignPatient = async (value, notification) => {
    const {patient_id, notification_created_date} = notification
    try {
      // setAssigneeLoading(true);
      message.loading(`Patient ${value.toString()!=0 ? "assigned" : "un assigned"} started`);
      const params={
        patientId:patient_id,
        staffId: value,
        userId:lab.labId,
        createdDate: notification_created_date
      }
      if(value.toString()!=="0"){
        await assignStaffToNotificationAPI(params);
      }
      else{
        await unAssignStaffToNotificationAPI(params);
      }
     
      message.success(`Patient ${value.toString()!=0  ? "assigned" : "un assigned"} successfully`);
      getNotifications();
    } catch (error) {
      console.log(error);
    } finally {
      setAssigneeLoading(false);
    }
  };


  return (
    <div className="notifications-wrapper">
     {openSettingsModal && (
        <NotificationSettingModal
          labId={lab.labId}
          openSettingsModal={openSettingsModal}
          onNotificationCancel={onNotificationCancel}
        />
      )}
    
      <Row className="notification-header">
        <div className="notification-wrapper">
          {totalNotifications > 0 && (
            <p
              className="notification-counter"
              style={{
                color: totoalNumberTextColor,
                backgroundColor: totalNumberBackgroundColor,
              }}
            >
              {totalNotifications}
            </p>
          )}
          <PageHeader
            className="site-page-header"
            title="Notifications"
            style={{ marginLeft: "0px" }}
          />
              <Search
          labId={lab.labId}
          setChecked={setChecked}
          setClinicIds={setClinicIds}
          setFilterVal={setFilterVal}
          open={open}
          setOpen={setOpen}
          checked={checked}
          setSecondaryFilter={setSecondaryFilter}
        />
              <div style={{ marginLeft: "10px" }}>
            <Popconfirm
              title="Are you sure you want to Mark all as read?"
               onConfirm={handleMarkAsReadConfirm}
              onCancel={handleCancel}
              okButtonProps={{
                style: {
                  width: "5rem",
                  borderRadius: "30px",
                  color: "#fff",
                  height: "30px",
                  padding: "4px 15px",
                  marginTop: "10px",
                },
              }}
              cancelButtonProps={{
                style: {
                  width: "5rem",
                  borderRadius: "30px",
                  height: "30px",
                  padding: "4px 15px",
                  marginTop: "10px",
                },
              }}
            >
              <Button
                style={{
                  marginLeft: "-5px",
                  fontSize: "14px",
                  color: primaryColor,
                }}
                type="link"
                size="large"
                onClick={showPopconfirm}
              >
                Mark All As Read
              </Button>
            </Popconfirm>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Radio.Group
            defaultValue={"ALL"}
            buttonStyle="solid"
            className="lab-notification-filter"
            style={{ height: "100%" }}
            value={filterVal}
            onChange={(e) => {
              if (e.target.value == "ALL") {
                setClinicIds([]);
                setChecked([]);
              }
              setFilterVal(e.target.value);
            }}
          >
            <Radio.Button className="status-radio-buttons" value="ALL">
            Clinic
            </Radio.Button>
            <Radio.Button
              className="status-radio-buttons clinic-filter"
              value="MYCASES"
            >
              My Cases
            </Radio.Button>
          </Radio.Group>
          <div onClick={showNotificationModal} style={{ cursor: "pointer" }}>
            <Settings />
          </div>
        </div>
      </Row>
      <div className="notifications-container">
        {notifications.length > 0 ? (
          notifications.map((notification, index) => {
            return (
              <Card className="notifications-card">
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={(e) =>
                          handleMenuClick(
                            e,
                            notification.notification_created_date
                          )
                        }
                        style={{
                          paddingTop: "0.5rem",
                          marginTop: "0.5rem",
                          width: "10rem",
                        }}
                        mode="horizontal"
                      >
                        <Menu.Item
                          key={1}
                          name="asd"
                          value={notification.notification_created_date}
                          style={{ textAlign: "right" }}
                        >
                          {readNotifications.includes(index) ||
                          notification.isViewed
                            ? "Mark As UnRead"
                            : "Mark As Read"}
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <div
                      className={
                        readNotifications.includes(index) ||
                        notification.isViewed
                          ? "notifications-status-read"
                          : "notifications-status"
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Dropdown>
                  <button
                    className="button-notifications"
                    onClick={() => {
                      markRead(
                        index,
                        notification.notification_created_date,
                        notification.patient_id,
                        notification.cause,
                        notification.notification_message
                      );
                    }}
                  >
                    <div
                      className={
                        readNotifications.includes(index) ||
                        notification.isViewed
                          ? "notifications-text-read"
                          : "notifications-text"
                      }
                    >
                      <p> {notification.notification_message}</p>
                    </div>
                  
                  </button>
                  <div className="assignee-row">
                      <SelectAssignee
                        loading={assigneeLoading}
                        options={[
                          { label: "Unassign", value: "0" },
                          ...labStaffMembers,
                        ]}
                      value={notification?.staffId ? notification?.staffId:null}
                        onChange={(value)=>reassignPatient(value,notification)}
                        labelProps={"label"}
                      />
                      <div
                        className={
                          readNotifications.includes(index)
                            ? "notifications-timestamp-read"
                            : "notifications-timestamp"
                        }
                      >
                        <p className="time-stamp">
                          {" "}
                          {moment
                            .duration(
                              Date.now() -
                                notification.notification_created_date
                            )
                            .humanize()}
                        </p>
                      </div>
                    </div>
                </div>
              <div className="border-line"/>
              </Card>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{
              height: 60,
            }}
            description={<span>No new notifications</span>}
          ></Empty>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  lab: state.lab,
});
export default connect(mapStateToProps)(NotificationsComponent);
