import { useState, useEffect, useRef } from "react";
import { Form, Modal, Button, message, Typography, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import ResourceForm from "../ResourceForm/ResourceForm";
import ResourceDetail from "./ResourceDetail";

import { editResource } from "api/resource";
const { Title } = Typography;

const ResourceViewEditModal = ({
  showDetailModal,
  setShowDetailModal,
  resource,
  lab,
  resources,
  SetResources,
}) => {
  const [addResourceForm] = Form.useForm();
  const [showEditForm, setShowEditForm] = useState(false);
  const [resourceFileList, setResourceFileList] = useState([]);

  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddResourceCancel = () => {
    setShowDetailModal(false);
  };

  const handleSubmit = () => {
    addResourceForm.submit();
  };

  const onResourceSubmit = async (values) => {
    const allUrls = values.videoLinks.map(obj=> obj.resourceUrl ? obj.resourceUrl : "")
    values.videoLinks = values.videoLinks.filter(obj=>obj.resourceUrl)
    if(!values.videoLinks.length &&  !resourceFileList.length){
      return message.error("Please add atleast one resource")
     }
  
    const hasEmptyString = allUrls.some(str => str === "");
    if (hasEmptyString && allUrls.length >1) {
      return message.error("Resource link can not be empty")
    }
    const isDuplicate = allUrls.length !== new Set(allUrls).size;
    if (isDuplicate && allUrls.length >1) {
      return message.error("Duplicate resource link")
    } 
    // console.log("values", values);
    // console.log("resourceFileList", resourceFileList);
    setLoading(true);
    try {
      const formData = new FormData();
      console.log("file", resourceFileList);
      const notDeletedFiles=[];
      if (resourceFileList.length) {
        for (let i = 0; i < resourceFileList.length; i++) {
          if(!resourceFileList[i].id){
            formData.append("files", resourceFileList[i]);
          }
          else{
            notDeletedFiles.push(resourceFileList[i].id);
          }
        }
      }

      formData.append("notDeletedFiles", notDeletedFiles.length?JSON.stringify(notDeletedFiles):"");
      formData.append("name", values.name);
      formData.append("labId", lab.labId);
      formData.append("category", values.category);
      formData.append(
        "description",
        values.description ? values.description : ""
      );
      formData.append(
        "videoLinks",
        values.videoLinks.length ? JSON.stringify(values.videoLinks) : ""
      );
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      console.log("formData", formData);
      const { data } = await editResource(
        resource.id,
        lab.labId,
        formData,
        config
      );
      // console.log("ressasssssssdata", data.data);
       const {id, name,shared,category,createdAt,description, files,allClinic} = data?.data;
       const prevResources =resources.map(resource=>{
        if(resource.id==id){
          return  {
            name: { resourceName: name, resourceId: id },
            shareList: {
              clinics: shared,
              resourceId: id,
              allClinic: allClinic,
            },
            category: category,
            uploaded: createdAt.split("T")[0],
            files: files.length ? files.filter((file) => file.mimeType !== "link") : [],
            id: id,
            description: description ? description: "",
            videoLinks: files.filter((file) => file.mimeType === "link"),
          }
        }
        return resource
       })
        SetResources(prevResources);
      message.success("Resource updated successfully");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);


       setShowDetailModal(false);
    }
  };

  // useEffect(() => {
  //

  // }, [resource]);

  const handleResourceEdit = () => {
    setShowEditForm(true);
    const formData = {
      name: resource.name?.resourceName,
      // labId: resource.labId,
      category: resource.category,
      description: resource.description,
      // videoLinks: [{ resourceUrl: "" }],
      videoLinks: resource.videoLinks.length ?  resource.videoLinks.map((videoLink) => {
        return {
          resourceUrl: videoLink.url,
          id: videoLink.id,
        };
      }): [{ resourceUrl: "" }],
    };
    // console.log("formData", formData)
     setResourceFileList(resource.files);
    addResourceForm.setFieldsValue(formData);
  };

  return (
    <div>
      <Modal
        className="addResourceModal"
        title={
          <div className="titleSpan">
            <Title level={5} style={{ margin: "0px" }}>
              {showEditForm ? "Edit Resource" : "Resource Detail"}
            </Title>
          </div>
        }
        width={800}
        open={showDetailModal}
        onCancel={handleAddResourceCancel}
        footer={
          showEditForm
            ? [
                <Button
                  key="cancel"
                  onClick={handleAddResourceCancel}
                  shape="round"
                  style={{ width: "7rem", borderRadius: "30px" }}
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  shape="round"
                  onClick={handleSubmit}
                  loading={loading}
                  style={{ width: "7rem", borderRadius: "30px" }}
                >
                  Save
                </Button>,
              ]
            : null
        }
      >
        <Spin spinning={loading}>
          {showEditForm ? (
            <ResourceForm
              addResourceForm={addResourceForm}
              onResourceSubmit={onResourceSubmit}
              touched={touched}
              setTouched={setTouched}
              resourceFileList={resourceFileList}
              setResourceFileList={setResourceFileList}
            />
          ) : (
            <ResourceDetail
              resource={resource}
              handleResourceEdit={handleResourceEdit}
            />
          )}
        </Spin>
      </Modal>
    </div>
  );
};

export default ResourceViewEditModal;
