import { Button } from "antd";

const Footer = ({ handleCancel, handleOk, handleSaveDraft, treatment, draftLoader, treatmentLoader }) => {
  return (
    <div>
      <Button shape="round" onClick={handleCancel}>
        Cancel
      </Button>
      <Button loading={draftLoader} onClick={handleSaveDraft} shape="round" style={{margin:"0px 10px"}}>
        Save as Draft
      </Button>
      <Button shape="round" data-testid="save-form-details"  type="primary" onClick={handleOk} loading={treatmentLoader}>
        {Object.keys(treatment).length ? "Update" : "Submit"}
      </Button>
    </div>
  );
};

export default Footer;
