import { useState } from "react";
import { Form, Input, Row, Col, Button, Popconfirm } from "antd";
import ResourceUpload from "../ResourceUpload/ResourceUpload";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
import { DeleteOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import { validURL } from "utils/commonMethod";
const { TextArea } = Input;
const AddResourceForm = ({
  addResourceForm,
  onResourceSubmit,
  setTouched,
  resourceFileList,
  setResourceFileList,
}) => {
  const onChange = (info) => {
    // setIprFile(info.file);
  };

  const beforeUpload = (file) => {
    return false;
  };
  return (
    <div>
      <Form
        form={addResourceForm}
        layout="vertical"
        name="addResourceForm"
        className="addResource-form"
        onFinish={onResourceSubmit}
        onFieldsChange={(changedValue, allFields) => {
          setTouched(true);
        }}
      >
        <Form.Item
          name="name"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please add title",
            },
          ]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: "Please add category",
            },
          ]}
        >
          <Input placeholder="Enter Category" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={3} placeholder="Description" />
        </Form.Item>
        <label>Upload Documents and Images</label>
        <ResourceUpload
          multiple={true}
          beforeUpload={beforeUpload}
          onChange={onChange}
          resourceFileList={resourceFileList}
          setResourceFileList={setResourceFileList}
        />
        <Form.List name="videoLinks">
          {(fields, { add, remove }) => (
            <>
              {fields?.map(({ key, name, fieldKey, ...restField }, index) => {
                const formValues = addResourceForm.getFieldsValue();
                const linkValue = Object.keys(formValues).length
                  ? formValues?.videoLinks[index]?.resourceUrl
                  : null;
                return (
                  <div key={key} style={{ position: "relative" }}>
                    <Row gutter={[16, 16]} align="middle">
                      <Col xs={22} md={22}>
                        <Form.Item
                          {...restField}
                          fieldKey={[fieldKey, "resourceUrl"]}
                          name={[name, "resourceUrl"]}
                          label="Video Link"
                          style={{
                            textAlign: "left",
                          }}
                          rules={[
                            // {
                            //   type: "url",
                            //   message:
                            //     "This field must be a valid URL (start with https://) and end with a domain.",
                            // },
                            () => ({
                              validator(_, value) {
                                if (validURL(value)) {
                                  return Promise.resolve();
                                }
                                if (value) {
                                  return Promise.reject("Invalid URL. Please include 'http://' or 'https://' at the beginning.");
                                }
                                return Promise.resolve();
                              },
                            }),

                          ]}
                        >
                          <Input placeholder="https://www.example.com" />
                        </Form.Item>
                      </Col>
                      <Col xs={2} md={2} style={{ textAlign: "right" }}>
                        {fields.length > 1 && linkValue && (
                          <Popconfirm
                            title="Delete Link"
                            description="Are you sure to delete this video link?"
                            onConfirm={() => {
                              remove(name);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              type="link"
                              className="delete-btn"
                              icon={<DeleteOutlined />}
                            />
                          </Popconfirm>
                        )}
                        {fields.length > 1 && !linkValue && (
                          <Button
                            type="link"
                            className="delete-btn"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              remove(name);
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Button
                icon={<AddIcon style={{ fill: primaryColor }} />}
                className="addOn-btn"
                onClick={() => {
                  add();
                }}
                block
              >
                Add Another Link
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default AddResourceForm;
