import { Button, Checkbox, Col, Row, Space, Typography, Tooltip } from "antd";

import css from "./styles.module.scss";

const { Paragraph } = Typography;



const CustomFooter = ({
  onCancel,
  onOk,
  generatedLink,
  okLoading,
  haveConsent,
  setHaveConsent,
  generateButtonDisabled
}) => {



    
  return (
    <Row>
      <Col md={16}>
        {generatedLink && (
          <div
            className={css["confirm-wrapper"]}
            style={{ display: "flex", gap: "5px", alignItems: "baseline" }}
          >
            <Checkbox
              onChange={e => setHaveConsent(e.target.checked)}
              value={haveConsent}
            >
              <Paragraph
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeigh: "1.4",
                  textAlign: "left",
                }}
              >
                I confirm that the Treatment URL is correct as per the files uploaded
              </Paragraph>
            </Checkbox>
          </div>
        )}
      </Col>
      <Col md={8}>
        <Space>
          <Button onClick={onCancel} shape="round">
            Cancel
          </Button>
          <Tooltip placement="top" title={!generatedLink && generateButtonDisabled ? "Kindly review some files missing!" : ""}>
          <Button
            disabled={!generatedLink ? generateButtonDisabled : generatedLink && !haveConsent}
            loading={okLoading}
            type="primary"
            onClick={onOk}
            shape="round"
          >
            {generatedLink ? "Save" : "Generate"}
          </Button>
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};

export default CustomFooter;
