import React, { useMemo, useState } from "react";
import UserIcon from "../../assets/images/user_icon.svg";
import { connect } from "react-redux";
import moment from "moment";
import { Radio, Typography, Form, Modal } from "antd";
import { primaryColor, companyType } from "colors-config";
import AppTag from "components/shared/AppTag/AppTag";
import "../../assets/styles/components/patientsDetail.scss";
import { ReactComponent as EditIcon } from "assets/images/edit_icon.svg";
import EditPatientModal from "components/shared/modals/EditPatientModal";
import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";

import InfoDisplay from "./InfoDisplay/InfoDisplay";
const { Title } = Typography;

const PATIENT = "patient";
const CLINIC = "clinic";

const PatientsDetail = ({
  patientInfo,
  currentAlignerNumber,
  lab,
  totalAlignerState,
  clinicInfo,
  getPatientInformation,
}) => {
  const [activeTab, setActiveTab] = useState(PATIENT);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editPatientForm] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [touched, setTouched] = useState(false);
  // const getClinicTime = () => {
  //   const dummyDate = "2017-03-13";
  //   if (clinicDetails?.clinic_start_time) {
  //     return moment(dummyDate + " " + clinicDetails?.clinic_start_time).format(
  //       "HH:mm"
  //     )
  //   }
  //   return "Not available";

  // }

  const onTypeChanged = event => {
    const selectedMenu = event.target.value;
    setActiveTab(selectedMenu);
  };

  const patientAddress = useMemo(() => {
    if (patientInfo) {
      const address = `${patientInfo?.patient_address || ""}${
        patientInfo?.patient_address && patientInfo?.patient_city ? "," : ""
      } ${patientInfo?.patient_city || ""}${
        patientInfo?.patient_county && patientInfo?.patient_city ? "," : ""
      } ${patientInfo?.patient_county || ""}${
        patientInfo?.patient_county && patientInfo?.patient_postal_code
          ? ","
          : ""
      } ${patientInfo?.patient_postal_code || ""}${
        patientInfo?.patient_country && patientInfo?.patient_postal_code
          ? ","
          : ""
      } ${patientInfo?.patient_country || ""}`;

      if (address === "" || !address.replace(/\s/g, "").length) return "-";

      return address;
    }

    return "-";
  }, [patientInfo]);

  const clinicAddress = useMemo(() => {
    let clinicAddress;
    if (patientInfo?.addressId) {
      const findClinicAddress = clinicInfo?.multipleAdresses.find(
        obj => obj.id === patientInfo.addressId
      );
      clinicAddress = findClinicAddress;
    }

    if (clinicAddress) {
      const address = `${clinicAddress?.address || ""}${
        clinicAddress?.address && clinicAddress?.city ? "," : ""
      } ${clinicAddress?.city || ""}${
        clinicAddress?.county && clinicAddress?.city ? "," : ""
      } ${clinicAddress?.county || ""}${
        clinicAddress?.county && clinicAddress?.zipCode ? "," : ""
      } ${clinicAddress?.zipCode || ""}${
        clinicAddress?.country && clinicAddress?.zipCode ? "," : ""
      } ${clinicAddress?.country || ""}`;
      if (address === "" || !address.replace(/\s/g, "").length) return "-";

      return address;
      // return "-";
    } else {
      if (clinicInfo) {
        const address = `${clinicInfo?.clinic_address || ""}${
          clinicInfo?.clinic_address && clinicInfo?.clinic_city ? "," : ""
        } ${clinicInfo?.clinic_city || ""}${
          clinicInfo?.clinic_county && clinicInfo?.clinic_city ? "," : ""
        } ${clinicInfo?.clinic_county || ""}${
          clinicInfo?.clinic_county && clinicInfo?.clinic_postal_code ? "," : ""
        } ${clinicInfo?.clinic_postal_code || ""}${
          clinicInfo?.clinic_country && clinicInfo?.clinic_postal_code
            ? ","
            : ""
        } ${clinicInfo?.clinic_country || ""}`;
        if (address === "" || !address.replace(/\s/g, "").length) return "-";

        return address;
      }
    }
    return "-";
  }, [clinicInfo, patientInfo]);

  const getClinicName = useMemo(() => {
    let clinicAddress;
    if (patientInfo?.addressId) {
      const findClinicAddress = clinicInfo?.multipleAdresses.find(
        obj => obj.id === patientInfo.addressId
      );
      clinicAddress = findClinicAddress;
    }

    if (clinicAddress) {
      return clinicAddress.label;
      // return "-";
    } else {
      if (clinicInfo) {
        return "Address";
      }
    }
    return "-";
  }, [clinicInfo, patientInfo]);

  const getClinicTime = () => {
    const dummyDate = "2017-03-13";
    if (clinicInfo?.clinic_start_time) {
      return (
        moment(dummyDate + " " + clinicInfo?.clinic_start_time).format(
          "HH:mm"
        ) +
        " - " +
        moment(dummyDate + " " + clinicInfo?.clinic_end_time).format("HH:mm")
      );
    }
    return "-";
  };

  const getAlignerSTatusText = () => {
    const suffix = companyType === 4 ? "Stage" : "Aligner";
    const alignerNum = currentAlignerNumber
      ? `${currentAlignerNumber}/${totalAlignerState}`
      : "0";
    return `Currrent ${suffix} ${alignerNum}`;
  };

  const cancelSubmit = () => {
    setShowEditModal(false);
  };

  const handleCancel = () => {
    console.log("handleCancel patient",editPatientForm.isFieldsTouched());
    
    if (touched) {
      handleConfirmCancel()
    } else {
      setShowEditModal(false);
    }
  };

  const handleConfirmCancel = () => {
    const cancelConfig = createConfirmConfig({
      title: "Close edit patient form",
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      onOk: () => cancelSubmit(),
      onCancel: () => {},
      okText: "Ok",
    });
    modal.confirm(cancelConfig);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="patient-detail-container-patientInfo">
        <div className="patient-detail-topRow">
          <div className="patient-detail-topRow-profile">
            <div className="profile-icon">
              {" "}
              <img src={UserIcon} className="user-icon-image" alt="Edit" />
            </div>
            <div className="patient-name">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  className={`${
                    activeTab === "patient"
                      ? "subheading sub-highlight"
                      : "subheading"
                  } ${companyType === 5 ? "subheading-smilealign" : ""}`}
                >
                  {activeTab === "patient"
                    ? `${patientInfo?.patient_first_name} ${patientInfo?.patient_last_name}`
                    : `${clinicInfo?.clinic_name}`}
                </div>
                {activeTab === "patient" && (
                  <EditIcon
                    className="patient-edit"
                    onClick={() => {
                      setShowEditModal(true);
                    }}
                    style={{
                      fill: primaryColor,
                    }}
                  />
                )}
              </div>

              <div className="caption">
                {activeTab === "patient"
                  ? patientInfo?.patient_id
                  : clinicInfo.clinic_id}
              </div>
            </div>
          </div>
          {showEditModal && (
            <EditPatientModal
              title={
                <div className="titleSpan">
                  <img
                    src={require("../../assets/images/editUser.png")}
                    style={{ width: "30px" }}
                    alt=""
                  />
                  <Title level={5} style={{ margin: "0px" }}>
                    EDIT PATIENT
                  </Title>
                </div>
              }
              IsEdit={true}
              content={patientInfo}
              showEditModal={showEditModal}
              handleCancel={handleCancel}
              setShowEditModal={setShowEditModal}
              editPatientForm={editPatientForm}
              patientInfo={patientInfo}
              clinicInfo={clinicInfo}
              getPatientInformation={getPatientInformation}
              touched={touched}
              setTouched={setTouched}
            />
          )}
          <div style={{ display: "flex" }}>
            {lab.labServiceId !== 1 && (
              <div className="aligner">
                <AppTag text={getAlignerSTatusText()} />
              </div>
            )}
            <Radio.Group
              defaultValue={PATIENT}
              value={activeTab}
              buttonStyle="solid"
              style={{ marginTop: "20px", height: "40px", marginRight: "10px" }}
              onChange={onTypeChanged}
            >
              <Radio.Button
                className="patient-details-radio-buttons"
                value={PATIENT}
              >
                Patient
              </Radio.Button>
              <Radio.Button
                className="patient-details-radio-buttons"
                value={CLINIC}
              >
                Clinician
              </Radio.Button>
            </Radio.Group>
            {/* </div> */}
          </div>
        </div>
        <div className="patient-detail-bottomRow patient-detail-middleRow ">
          <InfoDisplay
            activeTab={activeTab}
            patientInfo={patientInfo}
            clinicInfo={clinicInfo}
            primaryColor={primaryColor}
            clinicName={getClinicName}
            clinicAddress={clinicAddress}
            time={getClinicTime()}
            patientAddress={patientAddress}
          />
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps)(PatientsDetail);
