import React, { useState, useEffect } from "react";
import { message } from "antd";
import AppTable from "components/shared/AppTable/AppTable";
import { getFirstOrderLabInvoices } from "api/invoices";
import { createInvoicesTableData, fileDownloadHandler } from "utils/invoices";
import { getFirstTableColumns } from "./TableColumns";
import UpdatePaymentStatusModal from "components/shared/modals/updatePaymentStatusModal";
import "assets/styles/components/invoiceList.scss"
import LabInvoiceModal from "components/private/LabInvoiceModal/LabInvoiceModal";
import dayjs from "dayjs";


const FirstInvoices = ({
  labId,
  paymentStatusList,
  confirmUpdatePaymentStatus,
  searchKey,
  setFirstInvoiceCounter,
  fromDate,
  endDate,
  setFromFirstDate,
  setEndFirstDate,
  queryParamStatus
}) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  // const [fromDate, setFromDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paginationObj, setPaginationObj] = useState({});

  const getAllLabInvoices = async (labId) => {
    try {
        let start = ""
        let end = ""
        let storedDates = localStorage.getItem("dashboardFilter");
        if (storedDates && queryParamStatus) {
          storedDates = JSON.parse(storedDates).map((date) => dayjs(date))
          start = storedDates[0].format("YYYY-MM-DD");
          end = storedDates[1].format("YYYY-MM-DD");
          setFromFirstDate(start)
          setEndFirstDate(end)
        }

      setLoading(true);
      const { data } = await getFirstOrderLabInvoices(labId, {
        start: queryParamStatus ? start: fromDate,
        end: queryParamStatus ? end : endDate,
        searchKey: searchKey
      });
      if (data) {
        //  console.log("labOrdersRes", data);
        const finalTableData = createInvoicesTableData(data?.data);
        setFirstInvoiceCounter(finalTableData?.length || 0)
        // console.log("finalTableData", finalTableData);
        setTableData(finalTableData);
        const obj = {
          // pageSize: 10,
          showSizeChanger: true,
          // defaultCurrent: 1,
          // defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50],
          // size: "small",
          // total: finalTableData.length,
        };
        setPaginationObj(obj);
      }
    } catch (error) {
      message.error("Some Error occured!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (labId) {
      getAllLabInvoices(labId);
    }
  }, [labId, searchKey, fromDate,endDate ]);

  const onDownloadAllClick = (shippingFiles) => {
    shippingFiles.forEach((file) => {
      fileDownloadHandler(file);
    });
  };

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromFirstDate(start);
    setEndFirstDate(end);
    // setPaginationPage(1);
    confirm({ closeDropdown: true });
  };
  const showPaymentModal = (record) => {
    setSelectedOrder(record);
    setIsModalVisible(true);
  };

  const confirmUpdateStatus = ({ paymentStatus }) => {
    const [{ payment_status_id: paymentStatusId }] = paymentStatusList.filter(
      (paymentStatusDetails) =>
        paymentStatusDetails.payment_status_name === paymentStatus
    );
    const orderId = selectedOrder.invoiceNo;
    confirmUpdatePaymentStatus(orderId, paymentStatusId);
  };
  return (
    <div className="first-table-container">
      <AppTable
       className="invoice-table"
        columns={getFirstTableColumns(
          handleDateFilter,
          showPaymentModal,
          setOrderInfo,
          setShowInvoiceModal,
          onDownloadAllClick,
          fromDate,
          endDate
        )}
        tableData={tableData}
        loading={loading}
        pagination={paginationObj}
        scroll={{ x: "max-content" }}
        // pagination={pagination}
        // onChange={onChange}
      />
      {isModalVisible && (
        <UpdatePaymentStatusModal
          title="Update Payment status"
          paymentStatusList={paymentStatusList}
          selectedOrder={selectedOrder}
          showModal={isModalVisible}
          onConfirmation={confirmUpdateStatus}
          setShow={setIsModalVisible}
        ></UpdatePaymentStatusModal>
      )}
     {showInvoiceModal && (
        <LabInvoiceModal
          showInvoiceModal={showInvoiceModal}
          setShowInvoiceModal={setShowInvoiceModal}
          orderInfo={orderInfo}
          setOrderInfo={setOrderInfo}
        />
      )}
    </div>
  );
};

export default FirstInvoices;
