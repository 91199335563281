import { Route, Switch } from "react-router-dom";
import Profile from "../Tabs/Profile/Profile";
import Products from "../Tabs/Products/Products";
import ManageStaff from "../Tabs/ManageStaff/ManageStaff";
import Treatments from "../Tabs/Treatments/Treatments";

const SettingRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/lab/settings" component={Profile} />
        <Route path="/lab/settings/manageStaff" component={ManageStaff} />
        <Route path="/lab/settings/products" component={Products} />
        <Route path="/lab/settings/treatments" component={Treatments} />

        <Route path="*">
          <div>404 Not Found</div>
        </Route>
      </Switch>
    </div>
  );
};

export default SettingRoutes;
