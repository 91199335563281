import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography, Input } from "antd";
import { connect } from "react-redux";
import { loadLab } from "services/redux/actions/labActions";
import "assets/styles/routes/resources.scss";
import AddResourceModal from "components/private/Resources/components/AddResourceModal/AddResourceModal";
import ResourcesList from "components/private/Resources/components/ResourcesList/ResourcesList";
import AppButton from "components/shared/AppButton/AppButton";
const { Title } = Typography;

const Resources = ({ lab }) => {
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [resources, SetResources] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const onSearchValue=(value)=>{
    setSearchValue(value);
  }
  return (
    <div className="resources-container">
      {showAddResourceModal && (
        <AddResourceModal
          showAddResourceModal={showAddResourceModal}
          setShowAddResourceModal={setShowAddResourceModal}
          lab={lab}
          resources={resources}
          SetResources={SetResources}
        />
      )}

<Row style={{ justifyContent: "space-between" }}>
        <Col style={{ textAlign: "start" }} lg={10} md={{ span: 24}} sm={{ span: 24}} xs={{ span: 24}}>
          <Title level={3}>Resources</Title>
        </Col>
        <Col lg={10} md={{ span: 24}} sm={{ span: 24}} xs={{ span: 24}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Input.Search
              allowClear={true}
              enterButton={true}
              onChange={(e)=>onSearchValue(e.target.value)}
              loading={searchLoading}
              size="large"
              // enterButton={true}
              // prefix={<SearchOutlined />}
              addonAfter={null}
              className="searchBox"
              placeholder="Search Resource"
              onSearch={onSearchValue}
              // style={{
              //   width: 331,
              // }}
            />
            <AppButton
              shape="round"
              type="primary"
              onClick={() => setShowAddResourceModal(true)}
              style={{ marginLeft: "1rem" }}
            >
              Add New Resource
            </AppButton>
          </div>
        </Col>
      </Row>

      <ResourcesList
        lab={lab}
        resources={resources}
        SetResources={SetResources}
        searchValue={searchValue}
        setSearchLoading={setSearchLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps, { loadLab })(Resources);
