import { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Input, message, Button } from "antd";
import TreatmentUpload from "components/shared/TreatmentUpload/TreatmentUpload";
import { CloseOutlined } from "@ant-design/icons";
import { updateTreatmentLinkAPI } from "api/treatment";
import { uploadAppMediaApi } from "api/lab";
import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";
import { companyType, primaryColor } from "colors-config";
import GenerateThreeDModal from "../../TreatmentModal/GenerateThreeDModal/GenerateThreeDModal";
import { v4 as uuidv4 } from "uuid";
import { save3DLinkToTreatment, update3DLinkToTreatment } from "../../TreatmentModal/GenerateThreeDModal/services";
import { useSelector } from "react-redux";
import { validURL } from "utils/commonMethod";


const TreatmentLinkUpdateModal = ({
    treatmentLinkModalOpen,
    setTreatmentLinkModalOpen,
    treatment,
    treatmentPlans
}) => {
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();
    const [treatmentFile, setTreatmentFile] = useState(null);
    const [treatmentLinkModalLoading, setTreatmentLinkModalLoading] = useState(false);

    const [showGenerate3dModal, setShowGenerate3dModal] = useState(false);
    const [isGenerated3DLink, setIsGenerated3DLink] = useState(false);
    const [generated3DDraftData, setGenerated3DDraftData] = useState(null);
    const [draftUniqueId, setDraftUniqueId] = useState(uuidv4());

    const { labEmail } = useSelector(state => state.lab)

    const isTpViewer = () => {
        if(companyType === 5){
          return true;
        }
        return false;
      }

    useEffect(() => {
        if (treatment?.planType === "FILE") {
            setTreatmentFile(treatment?.treatment_media);
        }
        else {
            setTreatmentFile(null);
            form.setFieldsValue({
                planUrl: treatment.treatment_plan_link
            });
        }
        if(treatment?.tpViewerId){
            setDraftUniqueId(treatment.tpViewerId)
        }
    }, [treatment])


    const handleCancel = () => {
        const planUrlValue = form.getFieldValue("planUrl");
        if (planUrlValue !== treatment.treatment_plan_link) {
            handleConfirmCancel()
        }
        else {
            setTreatmentLinkModalOpen(false);
        }

    };


    const handleConfirmCancel = () => {
        const cancelConfig = createConfirmConfig({
            title: "Close update link form",
            content: "Any unsaved changes will be discarded. Do you want to proceed?",
            onOk: () => setTreatmentLinkModalOpen(false),
            onCancel: () => { },
            okText: "Ok",
        });
        modal.confirm(cancelConfig);
    };




    const beforeUploadTreatment = (file) => {
        // console.log("beforeUploadTreatment", file)

        setTreatmentFile(file);
        return false;
    };

    const onChange = (info) => {
   
    };

    const uploadTreatmentFile = async (file, labTreatmentId, patientId, clinicId) => {
        try {
            let formData = new FormData();

            formData.append("file", file);
            formData.append("labTreatmentId", labTreatmentId);
            formData.append("patientId", patientId);
            formData.append("clinicId", clinicId);
            formData.append("uploadedBy", "lab");
            formData.append("model", "treatment");
            const { data } = await uploadAppMediaApi(formData);
            if (data) {
                return data.data;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onFinish = async (values) => {
        try {
            setTreatmentLinkModalLoading(true)
            const { clinic_id, lab_id, patient_id, lab_treatment_id } = treatment;

            if (!treatmentFile) {
                let updateValues = {
                    clinicId: clinic_id,
                    labId: lab_id,
                    patientId: patient_id,
                    treatmentPlanId: lab_treatment_id,
                    planType: "LINK",
                    treatmentPlanLink: values.planUrl?values.planUrl:"http://www.test.com"
                }

                await updateTreatmentLinkAPI(updateValues)
            }
            else {
                await uploadTreatmentFile(treatmentFile, lab_treatment_id, patient_id, clinic_id)
            }

            if (isGenerated3DLink && generated3DDraftData?.draftId && lab_treatment_id) {
                const treatmentLink = `${process.env.REACT_APP_TPVIEWER_FRONTEND_URL}?link_id=${generated3DDraftData?.draftId}&treatmentId=${lab_treatment_id}`
                await save3DLinkToTreatment({
                    patientId: patient_id,
                    treatmentId: lab_treatment_id,
                    draftUniqueId: generated3DDraftData?.draftId,
                    treatmentLink: treatmentLink
                });
            }
            if (!isGenerated3DLink && treatment?.tpViewerId) {
                const treatmentLink = `${process.env.REACT_APP_TPVIEWER_FRONTEND_URL}?link_id=${treatment?.tpViewerId}&treatmentId=${lab_treatment_id}`
                await update3DLinkToTreatment({
                    patientId: patient_id,
                    treatmentId: lab_treatment_id,
                    draftUniqueId: treatment?.tpViewerId,
                    treatmentLink: treatmentLink
                });
            }
            setTreatmentLinkModalLoading(false);
            message.success("Treatment plan updated");
            setTreatmentLinkModalOpen(false);
            await treatmentPlans();


        } catch (error) {
            console.log("error", error);
            setTreatmentLinkModalLoading(false);
            message.error("Some error occured")
        }
    }

    const onGenerateButtonClick = async () => {
        setShowGenerate3dModal(true);
    }

    const addLink = ({ generatedLink, draftId }) => {
        if (draftId && generatedLink) {
            setIsGenerated3DLink(true);
            setGenerated3DDraftData({
                generatedLink,
                draftId
            });
            form.setFieldValue("planUrl", generatedLink);
        }
    };

    return (
        <>
            <Modal
                open={treatmentLinkModalOpen}
                width={750}
                title="Update Treatment plan 3D model link"
                onOk={() => form.submit()}
                onCancel={handleCancel}
                confirmLoading={treatmentLinkModalLoading}
                okText="Update"
                okButtonProps={{
                    className: "app-button-class",
                }}
                cancelButtonProps={{
                    className: "app-button-class",
                }}
            >
                <Form
                    form={form}
                    className="treatment-setup-form treatment-setup-update-link-form"
                    name="treatment-setup"
                    layout={"vertical"}
                    onFinish={onFinish}
                >
                    <Row align="middle" justify="space-between" style={{ width: "98%" }}>
                        <Col md={18}>
                            {
                                isGenerated3DLink ? <div>
                                    <label className="required">
                                        Treatment plan 3D model link
                                    </label>
                                    <Input
                                        style={{ marginTop: 8 }}
                                        data-testid="model-link"
                                        placeholder="Enter Model Link"
                                        value={generated3DDraftData?.generatedLink}
                                    />
                                </div> :
                                    treatmentFile ? (
                                        <div>
                                            <label className="required">Treatment plan 3D model link</label>
                                            <div className="treatment-file">
                                                <span>
                                                    {treatmentFile?.name}
                                                    <CloseOutlined
                                                        style={{ marginLeft: "20px" }}
                                                        onClick={() => setTreatmentFile(null)}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <Form.Item
                                            name="planUrl"
                                            label="Treatment plan 3D model link"
                                            style={{
                                                textAlign: "left",
                                                marginBottom: 0
                                            }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "A URL is required",
                                                    whitespace: true,
                                                },
                                                {
                                                    type: "url",
                                                    message:
                                                        "This field must be a valid url (start with https://) and end with a domain.",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter Model Link"></Input>
                                        </Form.Item>
                                    )}
                            {isTpViewer() &&<div style={{ textAlign: "end", marginRight: "-15px" }}>
                                <span>
                                    {isGenerated3DLink
                                        ? "Want to change?"
                                        : "Don't have 3D link?"}
                                </span>
                                <Button
                                    onClick={onGenerateButtonClick}
                                    style={{ color: primaryColor, padding:"0 3px" }}
                                    type="link"
                                >
                                    {isGenerated3DLink
                                        ? "Regenerate 3D link"
                                        : "Generate 3D link"}
                                </Button>
                            </div>}
                        </Col>
                        <Col md={6}>
                            <div style={{ marginTop: "12px" }}>
                                <TreatmentUpload
                                    maxCount={1}
                                    multiple={false}
                                    beforeUpload={beforeUploadTreatment}
                                    onChange={onChange}
                                    treatmentFile={treatmentFile}
                                    setTreatmentFile={setTreatmentFile}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>

            </Modal>
            {contextHolder}
            {showGenerate3dModal && (
                <GenerateThreeDModal
                    upperAligner={treatment.num_of_upper_aligners}
                    lowerAligner={treatment.num_of_lower_aligners}
                    showGenerate3dModal={showGenerate3dModal}
                    setShowGenerate3dModal={setShowGenerate3dModal}
                    patientId={treatment.patient_id}
                    draftId={draftUniqueId}
                    addLink={addLink}
                    isDraft={treatment?.tpViewerId ? false : true}
                    oldTpViewerId={treatment?.tpViewerId}
                    setDraftUniqueId={setDraftUniqueId}
                />
            )}
        </>
    );
};

export default TreatmentLinkUpdateModal;
