import { useState, useEffect } from "react";
import { Modal, Typography, Checkbox, Spin } from "antd";
import AppTable from "components/shared/AppTable/AppTable";
import AppButton from "components/shared/AppButton/AppButton";
import { showConfirmationModal } from "components/shared/ShowConfirmModal/index";
import { getPartnerInvitesColumns } from "../PartnerTables/TableColumns";
import styles from "../../labPartners.module.scss";
import {
  deleteBulkOnBoardingClinic,
  resendEmailBulkOnBoardingClinic,
} from "api/lab";
import { getOnBoardingClinics } from "api/lab";
import { message } from "antd";
import { ONBOARDING_STATUS } from "constants";
import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";


const { Title } = Typography;

const BulkInvitesTable = ({
  // onBoardingRequestedClinics,
  inviteActionType,
  setInviteActionType,
  labId,
  getLabPartners,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [onBoardingRequestedClinics, setOnBoardingRequestedClinics] = useState([]);
  const [modal, contextHolder] = Modal.useModal();


  useEffect(() => {
    if (labId) {
      (async () => {
        try {
          const [onBoardingData] = await Promise.all([
            getOnBoardingClinics({
              labId,
              pageSize: 10000,
              page: 1,
              partnershipStatus: ONBOARDING_STATUS.ONBOARDING_REQUESTED,
            }),
          ]);
          if (onBoardingData) {
            setOnBoardingRequestedClinics(onBoardingData?.data?.items);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [labId]);

  const handleSelect = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === onBoardingRequestedClinics.length
        ? []
        : onBoardingRequestedClinics.map((r) => r.id)
    );
  };

  const cancelSubmit = () => {
    setInviteActionType(null);
  };

  const handleBulkInviteCancel = () => {
    if (selectedRowKeys.length) {
      handleConfirmCancel()
    } else {
      setInviteActionType(null);
    }
  };

  const handleConfirmCancel = () => {
    const cancelConfig = createConfirmConfig({
      title: "Close invites modal",
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      onOk: () => cancelSubmit(),
      onCancel: () => {},
      okText: "Ok",
    });
    modal.confirm(cancelConfig);
  };

  const resendConfirmationContent =
    "All the selected clinics will receive the registration email again to the same email IDs again. Please uncheck the clinics in the current selection if you want to change the email IDs before sending the registration link again.";
  const deleteConfirmationContent =
    "Are you sure you want to delete these invites?";

  const handleBulkInviteSubmit = () => {
    showConfirmationModal(
      async () => {
        if (inviteActionType === "resend") {
          await callBulkSendEmailApi();
        } else {
          await callBulkDeleteApi();
        }

        handleBulkInviteCancel();
      },
      "Confirmation",
      inviteActionType === "resend"
        ? resendConfirmationContent
        : deleteConfirmationContent,
      "Cancel",
      inviteActionType === "resend" ? "Send Emails" : "Ok"
    );
  };

  const callBulkSendEmailApi = async () => {
    try {
      message.loading("Sending invites", 0);
      const finalData = selectedRowKeys
        .map((id) => {
          const findData = onBoardingRequestedClinics.find(
            (obj) => obj.id == id
          );
          if (findData) {
            return {
              labId: findData.labId,
              clinicEmail: findData.clinicEmail,
            };
          }
          return false;
        })
        .filter((obj) => obj);

      message.destroy();
      if (finalData.length) {
        await resendEmailBulkOnBoardingClinic(finalData);

        message.success(`Invites send successfully!`);
      } else {
        message.success(`Something went wrong`);
      }
    } catch (error) {
      message.destroy();
      message.error("Something didn't go right");
    }
  };

  const callBulkDeleteApi = async () => {
    try {
      message.loading("Deleting invites", 0);
      const finalData = {
        labId: labId,
        onboardingIds: selectedRowKeys,
      };
      await deleteBulkOnBoardingClinic(finalData);
      message.destroy();
      message.success(`Invites deleted successfully!`);
      getLabPartners();
    } catch (error) {
      message.destroy();
      message.error("Something didn't go right");
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 &&
        selectedRowKeys.length < onBoardingRequestedClinics.length
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    fixed: true,
    onChange: handleSelect,
    // onSelect: handleSelect,
    columnTitle: <></>,
  };

  return (
    <>
        <Modal
      title={
        <div>
          <Title level={5} style={{ margin: "0px" }}>
            {inviteActionType === "resend"
              ? "Resend Invites"
              : "Delete Invites"}
          </Title>
        </div>
      }
      width={900}
      open={!!inviteActionType}
      onCancel={handleBulkInviteCancel}
      footer={[
        <AppButton onClick={() => handleBulkInviteCancel()} type="default">
          Cancel
        </AppButton>,
        <AppButton
          onClick={() => handleBulkInviteSubmit()}
          htmlType="submit"
          disabled={!selectedRowKeys.length}
        >
          {inviteActionType === "resend" ? "Resend invites" : "Delete Invites"}
        </AppButton>,
      ]}
    >
      <Spin spinning={loading}>
        <div className={styles.invitesContainer}>
          <div className={styles.invitesHeader}>
            <div className={styles.selectAll}>{headerCheckbox} Select All</div>
            <div className={styles.fileTags}>
              <div className={`${styles.fileTag}`}>
                {selectedRowKeys.length}/{onBoardingRequestedClinics.length}{" "}
                Selected
              </div>
            </div>
          </div>
          <AppTable
            columns={getPartnerInvitesColumns()}
            tableData={onBoardingRequestedClinics}
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
            scroll={{ x: "max-content" }}
          />
        </div>
      </Spin>
    </Modal>
    {contextHolder}
    </>

  );
};

export default BulkInvitesTable;
