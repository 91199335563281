import { Row, Col } from "antd";
import classNames from "classnames";
import styles from "../treatments.module.scss";
import AppButton from "components/shared/AppButton/AppButton";
import { ReactComponent as ArrowRight } from "assets/images/ArrowRight.svg";
import { ReactComponent as Aligners } from "assets/images/treatments/Aligners.svg";
import { ReactComponent as Dentures } from "assets/images/treatments/Dentures.svg";
import { ReactComponent as Endodontics } from "assets/images/treatments/Endodontics.svg";
import { ReactComponent as Implant } from "assets/images/treatments/Implant.svg";
import { ReactComponent as Restoration } from "assets/images/treatments/Restoration.svg";
import { ReactComponent as Splint } from "assets/images/treatments/Splint.svg";

const TreatmentCard = ({onProceedClick}) => {
  return (
    <div className={classNames(styles["treatment-cards-container"])}>
      <Row align="middle" gutter={[32, 32]}>
        <Col lg={6} md={12} sm={24}>
          <div className={styles["treatment-card"]}>
            <div className={styles["treatment-img"]}>
            <Restoration/>
            </div>
        
         <h2 className={classNames(styles["treatment-name"])}>Restoration</h2>
            <AppButton
               icon={<ArrowRight />}
              type="primary"
               onClick={onProceedClick}
              className={classNames(styles["treatment-btn"])}
            >
              Proceed
            </AppButton>
          </div>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <div className={styles["treatment-card"]}>
            <div className={styles["treatment-img"]}>
            <Dentures/>
            </div>
        
         <h2 className={classNames(styles["treatment-name"])}>Dentures</h2>
            <AppButton
               icon={<ArrowRight />}
              type="primary"
               onClick={onProceedClick}
              className={classNames(styles["treatment-btn"])}
            >
              Proceed
            </AppButton>
          </div>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <div className={styles["treatment-card"]}>
            <div className={styles["treatment-img"]}>
            <Aligners/>
            </div>
        
         <h2 className={classNames(styles["treatment-name"])}>Aligners</h2>
            <AppButton
               icon={<ArrowRight />}
              type="primary"
               onClick={onProceedClick}
              className={classNames(styles["treatment-btn"])}
            >
              Proceed
            </AppButton>
          </div>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <div className={styles["treatment-card"]}>
            <div className={styles["treatment-img"]}>
            <Implant/>
            </div>
        
         <h2 className={classNames(styles["treatment-name"])}>Implant</h2>
            <AppButton
               icon={<ArrowRight />}
              type="primary"
               onClick={onProceedClick}
              className={classNames(styles["treatment-btn"])}
            >
              Proceed
            </AppButton>
          </div>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <div className={styles["treatment-card"]}>
            <div className={styles["treatment-img"]}>
            <Splint/>
            </div>
        
         <h2 className={classNames(styles["treatment-name"])}>Splint</h2>
            <AppButton
               icon={<ArrowRight />}
              type="primary"
               onClick={onProceedClick}
              className={classNames(styles["treatment-btn"])}
            >
              Proceed
            </AppButton>
          </div>
        </Col>
        <Col lg={6} md={12} sm={24}>
          <div className={styles["treatment-card"]}>
            <div className={styles["treatment-img"]}>
            <Endodontics/>
            </div>
        
         <h2 className={classNames(styles["treatment-name"])}>Endodontics</h2>
            <AppButton
               icon={<ArrowRight />}
              type="primary"
               onClick={onProceedClick}
              className={classNames(styles["treatment-btn"])}
            >
              Proceed
            </AppButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TreatmentCard;
