import { useState } from "react";
import { ReactComponent as Pdf } from "assets/images/Pdf.svg";
import { ReactComponent as PdfBrushbar } from "assets/images/PdfBrushbar.svg";
import { ReactComponent as Image } from "assets/images/Image.svg";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import styles from "./appFileList.module.scss";
import FileViewModal from "components/shared/FileViewModal/FileViewModal";
import { companyType } from "colors-config";

const AppFileList = ({ files }) => {
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [iframeFile, setIframeFile] = useState(null);

  const fileClickHandler = (e, file) => {
    setIframeFile(file);
    setShowIframeModal(true);
  };

  const handleOk = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  const handleCancel = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  const downloadHandler = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={styles.fileListContainer}>
      {files.length && (
        <>
          {files.map((file, i) => {
            return (
              <div
                className={styles.file}
                key={i}
              >
                <div className={styles.fileImage}>
                  {["image/jpeg", "image/png"].includes(file.meta.mimeType) ? (
                    <Image />
                  ) : (
                    companyType === 8 ? <PdfBrushbar /> : <Pdf />
                  )}

                  <div className={styles.fileMask}>
                    <DownloadOutlined
                    className={styles.downIcon}
                      style={{ marginRight: "1rem", fontSize: "20px" }}
                      onClick={() => downloadHandler(file)}
                    />
                    <EyeOutlined
                      className={styles.viewIcon}
                      onClick={(e) => fileClickHandler(e, file)}
                      style={{ fontSize: "20px" }}
                    />
                  </div>
                </div>

                <div key={i} className={styles.resourceName}>
                  <p>{file.name}</p>
                </div>
              </div>
            );
          })}
        </>
      )}
      {showIframeModal && (
        <FileViewModal
          isModalVisible={showIframeModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          iframeFile={iframeFile}
        />
      )}
    </div>
  );
};

export default AppFileList;
