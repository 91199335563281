import { Spin, Upload, Tooltip } from "antd";
// import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";

import css from "./uploadFile.module.scss";
import WarningIcon from "components/shared/Icon/WarningIcon";

const Uploader = ({
  CustomRender,
  boxName,
  onDelete,
  uploading,
  index,
  ...restProps
}) => {

  return (
    <>
      <Upload {...restProps}>
        <Spin spinning={uploading}>
          <div className={css["uploader-container"]}>
            <UploadOutlined />
            <div style={{ textAlign: "center" }}>
              Click or drag file to this area to upload .obj file
            </div>
            <div style={{ fontWeight: "700" }}>{`Aligner ${boxName} ${
              index
            } `}</div>
          </div>
        </Spin>
      </Upload>
      {CustomRender &&
        restProps.fileList.map(file => (
          <><CustomRender file={file} onDelete={onDelete} />
            {!file.isFilenameVaild && <Tooltip title="Please verify that the correct file is added for this stage." placement="right">
              <WarningIcon style={{ color: 'transparent', fontSize: '22px' }} />
            </Tooltip>}</>
        ))}
    </>
  );
};

export default Uploader;
