import { useState, useEffect } from "react";
import { Button, Dropdown, Menu, message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { UPPER_JAW, LOWER_JAW } from "./data";

import { ReactComponent as AddIcon } from "../../../assets/images/teeths/addIcon.svg";

import Tooth from "./Tooth/Tooth";
import Aligners from "./Aligners/Aligners";

import { primaryColor } from "colors-config";

const IprGuide = ({
  upperAligners,
  setUpperAligners,
  lowerAligners,
  setLowerAligners,
  upperLength,
  lowerLength,
  showDownload,
  chartValue,
  updateAlignersErrorsArray,
}) => {
  //   const [teethStructure, setTeethStructure] = useState(FDI_CHART);
  const [alignerIndex, setAlignerIndex] = useState(null);
  const [upperAlignerOptions, setUpperAlignerOptions] = useState([]);
  const [lowerAlignerOptions, setLowerAlignerOptions] = useState([]);
  const [alignersLabel, setAlignersLabel] = useState([]);

  const generateAlignerArray = (num, type) => {
    const result = [];

    // const prefix = type === "upper" ? "U" : "L";
    const prefix = type === "upper" ? "Before Aligner " : "Before Aligner ";

    for (let i = 1; i <= num; i++) {
      result.push({
        label: `${prefix}${i}`,
        key: i,
      });
    }

    return result;
  };

  useEffect(() => {
    const upperOptions = generateAlignerArray(upperLength, "upper");
    // console.log("upperOptions", upperOptions)
    setUpperAlignerOptions(upperOptions);
    const lowerOptions = generateAlignerArray(lowerLength, "lower");
    setLowerAlignerOptions(lowerOptions);
  }, [lowerLength, upperLength]);

  //Done
  const handleMenuClick = (key, type) => {
    // console.log("key", key, type);
    let aligners;
    let newData;
    const UId = uuidv4();
    if (type === "upper") {
      aligners = [...upperAligners];
    } else {
      aligners = [...lowerAligners];
    }
    // console.log("aligners", aligners)
    const newAligners = aligners.map((aligner) => {
      return {
        ...aligner,
        selected: false,
      };
    });
    const prefix = type === "upper" ? "U" : "L";
    newAligners.unshift({
      name: `${prefix}${key}`,
      selected: true,
      uniqueId: UId,
      aligner_count: key,
      alignerValues: Array.from({ length: 15 }, (_, index) => ({
        value: null,
      })),
    });

    if (type === "upper") {
      newData = newAligners.sort((a, b) => b.aligner_count - a.aligner_count);
      newData = newData.reverse().map((obj, index) => {
        obj.index = index + 1;
        return obj;
      });
      setUpperAligners(newData.reverse());
      setLowerAligners((prev) =>
        prev.map((aligner) => {
          return { ...aligner, selected: false };
        })
      );
    } else {
      newData = newAligners.sort((a, b) => a.aligner_count - b.aligner_count);
      setLowerAligners(newData);
      setUpperAligners((prev) =>
        prev.map((aligner) => {
          return { ...aligner, selected: false };
        })
      );
    }
    // console.log(type, UId, 0)
    // console.log("newData", newData)
    // type === "upper" ? newData.reverse()
    const index = newData.findIndex((obj) => obj.uniqueId === UId);
    // console.log("index", index)
    setAlignerIndex({
      type,
      activeAligner: UId,
      value: index,
    });
  };

  //Done
  const handleAlignerChange = (value, index, type) => {
    // console.log("value, index, type", value, index, type)
    // console.log(alignerIndex)
    let newData;
    if (alignerIndex == null) {
      return message.error("No aligner session is selected");
    }
    if (type !== alignerIndex.type) {
      return message.error("No aligner is selected");
    }
    if (type === "upper") {
      newData = [...upperAligners];
    } else {
      newData = [...lowerAligners];
    }
    // console.log("val,index", value, index);
    // console.log("newData", newData);
    const myIndex = alignerIndex.value;
    //  console.log("alignerIndex", alignerIndex, newData);
    //    console.log( newData[myIndex]["alignerValues"][index]["value"])
    newData[myIndex]["alignerValues"][index]["value"] = value == "none" ? null : value;
    // console.log("newData", newData);
    if (type === "upper") {
      setUpperAligners(newData);
    } else {
      setLowerAligners(newData);
    }
  };

  //Done
  const filteredOptions = (type) => {
    let filteredOptions,
      totalOptions,
      targetJaw = [];

    if (type === "upper") {
      targetJaw = upperAligners;
      totalOptions = upperAlignerOptions;
    } else {
      targetJaw = lowerAligners;
      totalOptions = lowerAlignerOptions;
    }

    const alignerNumbersArray = targetJaw.map((item) => item.aligner_count);

    const filtered = totalOptions.filter(
      (option) => !alignerNumbersArray.includes(Number(option.key))
    );
    
    filteredOptions = filtered.map((aligner, index) => {
      return <Menu.Item data-testid={`select-aligner-${index}`} key={aligner.key}>{aligner.label}</Menu.Item>;
    });
    return filteredOptions;
  };

  // const handleUnSelect = () => {
  //   let aligners;
  //   // setAlignerIndex(null);
  //   if (alignerIndex.type === "upper") {
  //     aligners = [...upperAligners];
  //   } else {
  //     aligners = [...lowerAligners];
  //   }
  //   const updatedAligner = aligners.map((aligner) => {
  //     return { ...aligner, selected: false };
  //   });

  //   if (alignerIndex.type === "upper") {
  //     setUpperAligners(updatedAligner);
  //   } else {
  //     setLowerAligners(updatedAligner);
  //   }
  //   setAlignerIndex(null);
  // };

  const Print = () => {
    //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  useEffect(() => {
    // if (upperAligners.length && lowerAligners.length) {
    const mergeAligners = upperAligners.concat(lowerAligners);
    mergeAligners.sort((a, b) => {
      return a.aligner_count - b.aligner_count;
    });
    let uniqueSessions = uniqByKeepLast(mergeAligners, (obj) => obj.aligner_count);
    uniqueSessions.map((obj, index) => {
      obj.session = index + 1;
      return obj;
    });
    setAlignersLabel(uniqueSessions);
    // console.log("uniqueSessions",uniqueSessions);
    // }
  }, [upperAligners, lowerAligners]);

  const uniqByKeepLast = (data, key) => {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  };

  return (
    <div>
      <div className="teethChart-container" style={{ padding: "0px 35px" }}>
        {/* {alignerIndex == null ? null : (
              <div className="overlay" onClick={handleUnSelect}></div>
            )} */}
        <Dropdown
          disabled={!upperLength || upperLength === upperAligners.length}
          overlay={
            <Menu
              className={upperLength > 6 ? "scroll-ipr-chart ipr-chart-drop" : "ipr-chart-drop"}
              onClick={(e) => handleMenuClick(+e.key, "upper")}
              data-testid="upper-ipr-chart-drop"
              mode="horizontal"
              selectable={true}
            >
              <Menu.Item key="" disabled>
                Before:
              </Menu.Item>
              {filteredOptions("upper")}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button icon={<AddIcon style={{ fill: primaryColor }} />} className="addOn-btn">
            Add IPR Session
          </Button>
        </Dropdown>
        <Aligners
          secondAligners={lowerAligners}
          alignersArray={upperAligners}
          setUpperAligners={setUpperAligners}
          setLowerAligners={setLowerAligners}
          setAlignerIndex={setAlignerIndex}
          alignerType="upper"
          alignersLabel={alignersLabel}
          updateAlignersErrorsArray={updateAlignersErrorsArray}
        />

        <div className="teethRow-container" style={{ marginTop: "4px" }}>
          <div className="teethRow-bgOne" />
          <div className="teethRow-one ipr-row">
            {UPPER_JAW.map((teeth, index) => {
              return (
                <div key={index}>
                  <Tooth
                    alignersArray={upperAligners}
                    type="upper"
                    index={index}
                    teeth={teeth}
                    chartValue={chartValue}
                    handleAlignerChange={handleAlignerChange}
                  />
                </div>
              );
            })}
            <div className="tooth-sides">
              <h2>Right</h2>
              <h2>Left</h2>
            </div>
            {LOWER_JAW.map((teeth, index) => {
              return (
                <div key={index}>
                  <Tooth
                    alignersArray={lowerAligners}
                    type="lower"
                    index={index}
                    teeth={teeth}
                    chartValue={chartValue}
                    handleAlignerChange={handleAlignerChange}
                  />
                </div>
              );
            })}
          </div>
          <div className="teethRow-bgTwo"></div>
        </div>
        <Aligners
          secondAligners={upperAligners}
          alignersArray={lowerAligners}
          setUpperAligners={setUpperAligners}
          setLowerAligners={setLowerAligners}
          setAlignerIndex={setAlignerIndex}
          alignerType="lower"
          alignersLabel={alignersLabel}
          updateAlignersErrorsArray={updateAlignersErrorsArray}
        />
        <Dropdown
          disabled={!lowerLength || lowerLength === lowerAligners.length}
          overlay={
            <Menu
              className={lowerLength > 6 ? "scroll-ipr-chart ipr-chart-drop" : "ipr-chart-drop"}
              onClick={(e) => handleMenuClick(+e.key, "lower")}
              mode="horizontal"
              data-testid="lower-ipr-chart-drop"
              selectable={true}
            >
              <Menu.Item key="" disabled>
                Before:
              </Menu.Item>
              {filteredOptions("lower")}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button icon={<AddIcon style={{ fill: primaryColor }} />} className="addOn-btn">
            Add IPR Session
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default IprGuide;
