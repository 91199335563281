import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, InputNumber, Form, DatePicker } from "antd";
import { ReactComponent as Pdf } from "assets/images/Pdf.svg";
import { ReactComponent as Image } from "assets/images/Image.svg";
import dayjs from "dayjs";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import LabInvoiceUpload from "components/private/LabInvoice/components/LabInvoiceUpload";
import { validURL } from "utils/commonMethod";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 8,
      offset: 2,
    },
    lg: {
      span: 8,
      offset: 0,
    },
    xl: { span: 8, offset: 0 },
    xxl: { span: 8, offset: 0 },
  },
  wrapperCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 24,
      offset: 0,
    },
    xl: { span: 24, offset: 0 },
    xxl: { span: 24, offset: 0 },
  },
};

const DeliveryUpdateModal = ({
  title,
  onConfirmation,
  showModal,
  setShow,
  content,
  updateData,
  setUpdateData,
  updateTrackingDetails,
  resourceFileList,
  setResourceFileList,
  orderDetails,
  removeInvoiceFiles,
  setRemoveInvoiceFiles,
  okLoading
}) => {
  //  console.log("orderDetails", orderDetails)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef();
  const [form] = Form.useForm();

  const [createMode, setCreateMode] = useState(true);


  useEffect(() => {
    if (orderDetails?.shippingFiles) {
    
      setResourceFileList([...orderDetails?.shippingFiles])
    }
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    if (updateData) {
      setCreateMode(false);
      form.setFieldsValue({
        link: updateData.trackingUrl,
        number: updateData.trackingNo,
        arrival_date: updateData.arrivalDate ? dayjs(updateData.arrivalDate) : null,
      })
    }
    else {
      setCreateMode(true);
    }
  }, [updateData]);

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
    setUpdateData(null);
    setCreateMode(true);
    setRemoveInvoiceFiles([])
    setResourceFileList([])
  };

  const onFinish = ({ link, number, arrival_date }) => {
    // console.log("arrival_date", arrival_date)
    setIsModalVisible(false);
    setShow(false);
    if (createMode) {
      onConfirmation(link, number, arrival_date);
    }
    else {
      // update data
      updateTrackingDetails(link, number, arrival_date);
      setCreateMode(true);
    }

    setUpdateData(null);
  }

  const disabledDate = (current) => {
    // Disable all dates before today (inclusive)
    return current && current < dayjs().startOf('day');
  };
  const onChange = (info) => {
    // setIprFile(info.file);
  };

  const beforeUpload = (file) => {
    setResourceFileList((prevList) => [...prevList, file]);
    return false;
  };

  const deleteInvoiceHandler = (id) =>{
    setRemoveInvoiceFiles(prevDeletedIds => [...prevDeletedIds, id]);
  }
  const filteredFiles = orderDetails?.shippingFiles?.filter(file => !removeInvoiceFiles.includes(file.id));

  return (
    <div>
      <Modal
        width={700}
        title={title}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { minWidth: "7rem", borderRadius: "30px" },
          loading: okLoading, // Set loading state
        }}
        // okButtonProps={{
        //   style: { minWidth: "7rem", borderRadius: "30px" },
        // }}
        cancelButtonProps={{ style: { minWidth: "7rem", borderRadius: "30px" } }}
        okText="Save"
      >
        <div className="delivery-modal-container">
        <p> {content}, please add Tracking Link and number</p>
        <Form
          className="login-form"
          {...formItemLayout}
          layout={"vertical"}
          name="register"
          scrollToFirstError
          form={form}
          onFinish={onFinish}
        >
          <Form.Item name="link" label="Tracking Link"
            rules={[
              // {
              //   required: true,
              //   message: "Please input the Tracking link!",
              // },
              () => ({
                validator(_, value) {
                  if (validURL(value)) {
                    return Promise.resolve();
                  }
                  if(value){
                    return Promise.reject("Invalid URL. Please include 'http://' or 'https://' at the beginning.");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input ref={inputRef} />
          </Form.Item>

          <Form.Item
            name="number"
            label="Tracking Number"
            rules={[
              // {
              //   pattern: /^[0-9]*$/,
              //   message: 'Please enter only numbers!',
              // },
              {
                max: 20,
                message: 'Maximum 20 characters allowed!',
              },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              min={1}
              max
            />
          </Form.Item>

          <Form.Item
            name="arrival_date"
            label="Est. Arrival date"
          >
            <DatePicker
              style={{ width: "100%" }}
              className="inputTypeClass dob-input"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Form>
        <label for="register_arrival_date" class="" title="Est. Arrival date">Upload Invoice PDF</label>
        <LabInvoiceUpload
          multiple={true}
          beforeUpload={beforeUpload}
          onChange={onChange}
          resourceFileList={resourceFileList}
          setResourceFileList={setResourceFileList}
          orderDetails={orderDetails}
          setRemoveInvoiceFiles={setRemoveInvoiceFiles}
        />
       {/* <div className="resources-container">
        {filteredFiles.map((file,i) =>{
          return(
            <div key={i} className="resource">
              <div className="resource-wrapper">
               <div className="icons">
               <a href={file.url} target="_blank" rel="noreferrer" key={i}>
               <DownloadOutlined  style={{
                  marginRight: "1rem",
                  fontSize: "20px",
                }}/>
                </a>
               <DeleteOutlined onClick={() => deleteInvoiceHandler(file.id)} style={{
                  marginRight: "1rem",
                  fontSize: "20px",
                }}/>

               </div>
              </div>
            {["image/jpeg", "image/png"].includes(file.meta.mimeType) ? <Image /> : <Pdf />}
              <p>{file.name}</p>  
            </div>
  
        
          )
        })}
        </div> */}
        </div>
   

      </Modal>
    </div>
  );
};

export default DeliveryUpdateModal;
