import { Layout, Spin } from "antd";
import SettingsMenu from "./pageComponents/shared/SettingsMenu/SettingsMenu";
import classNames from "classnames";
import styles from "./settings.module.scss";
import SettingRoutes from "./pageComponents/SettingRoutes/SettingRoutes";
// import { getClinicSettingsLoaderSelector } from "services/redux/selectors/clinicSelectors";
// import { useSelector } from "react-redux";
const { Sider, Content } = Layout;

const Settings = () => {
  // const clinicLoader = useSelector(getClinicSettingsLoaderSelector);

  return (
    <div className={classNames(styles["profile-layout-container"])}>
      <Layout>
        <Sider theme="light">
          <SettingsMenu />
        </Sider>
        <Layout className={classNames(styles["profile-content-container"])}>
          <Spin spinning={false}>
            <Content>
              <SettingRoutes />
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </div>
  );
};

export default Settings;
