import { Row, Col, Card } from "antd";
import dayjs from 'dayjs';
import { companyType } from "colors-config";

const Duration = ({ duration, detail, estimated_date }) => {
  return (
    <div>
      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Duration & Prescription Details</h1>
        <Row gutter={[8, 8]}>
          <Col lg={12} md={12} sm={24}>
            <h2 className="caption">Duration per Aligner</h2>
            <p className="content">{duration}</p>
          </Col>
          <Col lg={12} md={12} sm={24}>
            <h2 className="caption">Est. delivery date</h2>
            <p className="content">
              {estimated_date
                ? dayjs(estimated_date).format("DD-MMM-YYYY")
                : "--"}
            </p>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col lg={24}>
            <div>
              <h2 className="caption">
                {companyType === 8 ? "Treatment Notes" : "Prescription Details"}
              </h2>
              <p className="content">{detail === "" ? "--" : detail}</p>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Duration;
