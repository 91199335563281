import { useEffect, useState } from "react";
import { Col, InputNumber, Form, message, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import AppModal from "components/shared/AppModal/AppModal";
import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";
import { ReactComponent as UpperAlignerIcon } from "assets/images/UpperAligner.svg";
import { ReactComponent as LowerAlignerIcon } from "assets/images/LowerAligner.svg";
import { companyType } from "colors-config";
import FileUploader from "./FileUploader/FileUploader";
import LinkPreview from "./LinkPreview";
import CustomFooter from "./Footer";
import css from "./styles.module.scss";
import { convertObjToGLB, getTPDraftDetails, getTPViewDetails, getUploadSignedUrl, saveFiles, uploadService } from "./services";
import BulkFileUploader from "./FileUploader/BulkFileUploader";

const AlignerTypes = {
  UPPER: "upper",
  LOWER: "lower",
};

const DisabledALignerForm = ({ upperAligner, lowerAligner }) => {
  return (
    <Form
      initialValues={{
        upperAligner,
        lowerAligner,
      }}
      name="DisabledALignerForm"
      layout="vertical"
    >
      <Row gutter={[8, 8]}>
        <Col lg={12} md={12} sm={12}>
          <Form.Item
            label={
              <span>
                No. of upper {companyType === 4 ? "stages" : "aligners"}{" "}
                <UpperAlignerIcon width="2em" />
              </span>
            }
            name="upperAligner"
            style={{
              textAlign: "left",
            }}
            rules={[
              {
                required: true,
                message: "A valid number must be entered",
                pattern: new RegExp(/^[0-9]+$/),
              },
            ]}
          >
            <InputNumber
              disabled
              max={99}
              style={{ width: "100%" }}
              placeholder="Enter Number"
              data-testid="upper-aligner"
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Form.Item
            label={
              <span>
                No. of lower {companyType === 4 ? "stages" : "aligners"}{" "}
                <LowerAlignerIcon width="2em" />
              </span>
            }
            name="lowerAligner"
            className="inline-form-right"
            style={{
              textAlign: "left",
            }}
            rules={[
              {
                required: true,
                message: "A valid number must be entered",
                pattern: new RegExp(/^[0-9]+$/),
              },
            ]}
          >
            <InputNumber
              disabled
              max={99}
              style={{ width: "100%" }}
              placeholder="Enter Number"
              data-testid="lower-aligner"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

function generateLoadingObject(count) {
  const loadingObject = {};

  for (let i = 0; i <= count; i++) {
    loadingObject[i] = false;
  }

  return loadingObject;
}

function generateFileNameCheck(count) {
  const fileNameCheck = {};

  for (let i = 0; i <= count; i++) {
    fileNameCheck[i] = true;
  }

  return fileNameCheck;
}

const GenerateThreeDModal = ({
  showGenerate3dModal,
  setShowGenerate3dModal,
  upperAligner,
  lowerAligner,
  patientId,
  draftId,
  addLink,
  isDraft,
  oldTpViewerId,
  setDraftUniqueId
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [upperImages, setUpperImages] = useState(upperAligner ?  Array(upperAligner+1).fill([]) : []);
  const [lowerImages, setLowerImages] = useState(lowerAligner ? Array(lowerAligner+1).fill([]) : []);
  const [generatedLink, setGeneratedLink] = useState("");
  const [okLoading, setOkLoading] = useState(false);
  const [haveConsent, setHaveConsent] = useState(false);
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(true);
  const [upperUploaders, setUpperUploaders] = useState(
    generateLoadingObject(upperAligner)
  );

  const [lowerUploaders, setLowerUploaders] = useState(
    generateLoadingObject(lowerAligner)
  );

  const [checkUpperFilename, setCheckUpperFilename] = useState(
    generateFileNameCheck(upperAligner)
  )

  const [checkLowerFilename, setCheckLowerFilename] = useState(
    generateFileNameCheck(lowerAligner)
  )

  
  useEffect(() => {
    if (!draftId) return;
    getDraftUniqueDetails(draftId);
  }, [draftId]);

  const getDraftUniqueDetails = async (draftId) => {
    try {
      const { data: tpDraftDetails } = isDraft?  await getTPDraftDetails(draftId): await getTPViewDetails(draftId);
      if (!tpDraftDetails?.data) return;
      const { upperAlignerFiles, lowerAlignerFiles } = tpDraftDetails.data;
      const mapAlignerFiles = (alignerFiles, images, type) =>{
        return images.map((_, index) => {
          const file = alignerFiles.find(obj => obj?.alignerCount === index);
          if(!file) return [];
          let fileName =  file.fileName? file.fileName : file.name;
          fileName = fileName.toLowerCase();
          const numberMatch = fileName.match(/\d+/); // Matches one or more digits
          const fileNumber = numberMatch ? parseInt(numberMatch[0], 10) : null;
          const expectedNumber = index;

          let isFilenameVaild=true;

          if(type === 'upper' && (fileNumber !== expectedNumber || !fileName.includes('upper'))){
            isFilenameVaild=false;
          }

          if(type === 'upper' && (fileNumber === expectedNumber && fileName.includes('upper'))){
            isFilenameVaild=true;
          }
          if(type === 'lower' && (fileNumber !== expectedNumber || !fileName.includes('lower'))){
            isFilenameVaild=false;
          }
          if(type === 'lower' && (fileNumber === expectedNumber && fileName.includes('lower'))){
            isFilenameVaild=true;
          }

          return file ? [{
            uid: file.hash,
            name: file.fileName ? file.fileName : file.name,
            status: 'done',
            url: file.url,
            isFilenameVaild: isFilenameVaild,
            converted: true,
            fileHash: file.hash,
            newName: file.name
          }] : [];
        })
      };
      if(!upperAlignerFiles[0].alignerCount|| !lowerAlignerFiles[0].alignerCount){
        upperAlignerFiles.unshift(null)
        lowerAlignerFiles.unshift(null)
      }
      if (upperAlignerFiles?.length) setUpperImages(mapAlignerFiles(upperAlignerFiles, upperImages,'upper'));
      if (lowerAlignerFiles?.length) setLowerImages(mapAlignerFiles(lowerAlignerFiles, lowerImages,'lower'));


    } catch (error) {
      console.log("error", error);

    }
  }


  const fileMapper = (alignerFile, uniqueId, index, type) => {
    const nameSplitted = alignerFile[0].name.split(".");
    const extension = nameSplitted[nameSplitted.length - 1];
    const preSignedRequestBody = {
      patientId: patientId,
      draftUniqueId: uniqueId,
      fileName: alignerFile[0]?.newName ? alignerFile[0]?.newName : `${type}-${index}.${extension}`,
      originalFileName: alignerFile[0]?.name,
      fileType: alignerFile[0]?.newType ? alignerFile[0]?.newType : alignerFile[0]?.type || "model/obj",
      converted: alignerFile[0]?.converted ? alignerFile[0]?.converted : false,
      fileHash:  alignerFile[0].fileHash
    };

    return {
      ...preSignedRequestBody,
      type,
      alignerCount: index,
      extension: alignerFile[0]?.newType ? "glb" : extension,
    };
   
    
  };

  const missingFileValidations = files => {
    const check = files.every(file => file.length);

    return check;
  };

  const generateImages = async () => {
    if (
      !missingFileValidations(upperImages) ||
      !missingFileValidations(lowerImages)
    ) {
      return message.error("File(s) missing, please check and try again");
    }
    try {
      setOkLoading(true);
      const uniqueId = uuidv4();

      const files = [];
      upperImages.forEach((file, index) => {
        if (file.length) {
          files.push(fileMapper(file, uniqueId, index, AlignerTypes.UPPER));
        }
      });

      lowerImages.forEach((file, index) => {
        if (file.length) {
          files.push(fileMapper(file, uniqueId, index, AlignerTypes.LOWER));
        }
      });

     
      const saveImageData = {
        patientId,
        draftUniqueId: draftId,
        upperAligners: upperAligner,
        lowerAligners: lowerAligner,
      };
      

      const lowerAlignerFiles = [];
      const upperAlignerFiles = [];

      // Check converted files
      const checkAllConverted = files.every(obj=>obj.converted);
      if(!checkAllConverted){
        message.info("System busy, please try again in a moment.")
        return;
      }

      files.forEach(el => {
        const obj = {
          name: el.fileName,
          fileName: el.originalFileName,
          alignerCount: el.alignerCount,
          hash: el.fileHash,
          meta: {
            mimetype: el.fileType,
            extension: el.extension,
          },
        };
        if (el.type === AlignerTypes.LOWER) {
          lowerAlignerFiles.push(obj);
        }

        if (el.type === AlignerTypes.UPPER) {
          upperAlignerFiles.push(obj);
        }
      });

      saveImageData.lowerAlignerFiles = lowerAlignerFiles;
      saveImageData.upperAlignerFiles = upperAlignerFiles;
      const newDraftId = uuidv4();
      let updateNewFilesCase = false; 
      if(oldTpViewerId){
        saveImageData.draftUniqueId = newDraftId;
        saveImageData.oldDraftId = draftId;
        updateNewFilesCase = true;
        setDraftUniqueId(newDraftId)
      }
      
      const dbResponse = await saveFiles(saveImageData);

      const draftUrl = `${process.env.REACT_APP_TPVIEWER_FRONTEND_URL}?link_id=${updateNewFilesCase ? newDraftId : draftId}&isDraft=true`;
      
      setGeneratedLink(draftUrl);
      message.success("3D link successfully generated")
    } catch (error) {
      console.log(error);
    } finally {
      setOkLoading(false);
    }
  };

  const onOk = () => {
    if (!generatedLink) {
      return generateImages();
    }

    if (addLink) {
      addLink({ generatedLink, draftId });

      /**  todo: add link save api here */
      message.success("link saved successfully");
    }

    onClose();
  };

  const confirmDataLossCheck = files => {
    return files.some(file => file.length);
  };

  const onClose = () => {
    setShowGenerate3dModal(false);
  };

  const onCancel = () => {
    if (
      confirmDataLossCheck(upperImages) ||
      confirmDataLossCheck(lowerImages)
    ) {
      const cancelConfig = createConfirmConfig({
        title: "Close generate 3D link form",
        content:
          "Any unsaved changes will be discarded. Do you want to proceed?",
        onOk: () => onClose(),
        onCancel: () => {},
        okText: "Ok",
      });
      modal.confirm(cancelConfig);
      return;
    }
    onClose();
  };

  useEffect(()=>{
    let tempGenerateButtonDisabled= true;
    
    if(Object.values(upperUploaders).length && Object.values(lowerUploaders).length){
      
      const isUpperImages = Object.values(upperUploaders).some(value => value);
      const isLowerImages = Object.values(lowerUploaders).some(value => value);

      const isUpperImagesEmpty = upperImages.some(obj => !obj.length)
      const isLowerImagesEmpty = lowerImages.some(obj => !obj.length)
      tempGenerateButtonDisabled = (isUpperImages || isUpperImagesEmpty || isLowerImages || isLowerImagesEmpty) ? true : false
    }
    setGenerateButtonDisabled(tempGenerateButtonDisabled);
  },[upperUploaders,lowerUploaders, upperImages, lowerImages])

  const modalProps = {
    open: showGenerate3dModal,
    onOk,
    onCancel,
    okText: "Generate",
    title: "Generate 3D link",
    width: 800,
    wrapClassName: css["modal-wrapper"],
    className:"generate-3d-modal",
    showFooter: true,
    modalFooter: (
      <CustomFooter
        onCancel={onCancel}
        onOk={onOk}
        generatedLink={generatedLink}
        okLoading={okLoading}
        haveConsent={haveConsent}
        setHaveConsent={setHaveConsent}
        generateButtonDisabled={generateButtonDisabled}
      />
    ),
  };

  const handleCustomRequest = async (
    { file, onProgress },
    index, fileType
  ) => {
    if (file.type !== "model/obj" && !file.name.includes(".obj")) {
      return;
    }
    const nameSplitted = file.name.split(".");

    const extension = nameSplitted[nameSplitted.length - 1];

    try {
      if(fileType === 'upper'){
        setUpperUploaders(prev => ({
          ...prev,
          [index]: true,
        }));
      } else {
        setLowerUploaders(prev => ({
          ...prev,
          [index]: true,
        }));
      }
      
      const fileUniqueId = uuidv4();
      const fileName = `${fileUniqueId}_${fileType}-${index}.${extension}`;
      const preSignedRequestBody = {
        patientId: patientId,
        draftUniqueId: draftId,
        fileName: fileName,
        fileType: file?.type || "model/obj",
      };
      const preSignedResponse = await getUploadSignedUrl(preSignedRequestBody);

      await uploadService(
        preSignedResponse.data.data.signedUrl,
        file,
        onProgress
      );

      convertObjToGLB({
        patientId: preSignedRequestBody.patientId,
        draftUniqueId: preSignedRequestBody.draftUniqueId,
        fileName: preSignedRequestBody.fileName,
      }).then(()=>{
        if(fileType === "upper"){
          setUpperImages(prev => {
            const updated = [...prev];
            updated[index][0].converted = true;
            return updated;
          });
        } else {
          setLowerImages(prev => {
            const updated = [...prev];
            updated[index][0].converted = true;
            return updated;
          });
        }
        
        
      })

      const modalExists = document.querySelector(".generate-3d-modal");
      if(modalExists){
        message.success(`${fileType === "upper" ? "Upper" : "Lower"} Aligner ${index} uploaded successfully`);
      }
      file.newType='model/gltf-binary'
      file.newName = `${fileUniqueId}_${fileType}-${index}.glb`
      file.isFilenameVaild = fileType === "upper" ? checkUpperFilename[index] : checkLowerFilename[index];
      file.fileHash = fileUniqueId;
      file.converted = false;
      if(fileType === "upper"){
        setUpperImages(prev => {
          const updated = [...prev];
          updated[index] = [file];
          return updated;
        });
      } else {
        setLowerImages(prev => {
          const updated = [...prev];
          updated[index] = [file];
          return updated;
        });
      }
      
      // onSuccess("Upload successful");
    } catch (error) {
      console.log("error", error);
      const modalExists = document.querySelector(".generate-3d-modal");
      if(modalExists){
        message.error(`${file.name} does not uploaded successfully`);
      }
      // onError("upload failed");
    } finally {
      if(fileType === 'upper'){
        setUpperUploaders(prev => ({
          ...prev,
          [index]: false,
        }));
      } else {
        setLowerUploaders(prev => ({
          ...prev,
          [index]: false,
        }));
      }
      
    }
  };


  return (
    <AppModal {...modalProps}>
      {contextHolder}
      <DisabledALignerForm
        upperAligner={upperAligner}
        lowerAligner={lowerAligner}
      />
      <BulkFileUploader
        upperAligner={upperAligner}
        lowerAligner={lowerAligner}
        setUpperImages={setUpperImages}
        setLowerImages={setLowerImages}
        upperImages={upperImages}
        lowerImages={lowerImages}
        patientId={patientId}
        draftId={draftId}
        setGeneratedLink={setGeneratedLink}
        handleCustomRequest={handleCustomRequest}
      />
      <FileUploader
        setLowerImages={setLowerImages}
        setUpperImages={setUpperImages}
        upperImages={upperImages}
        lowerImages={lowerImages}
        upperAligner={upperAligner}
        lowerAligner={lowerAligner}
        patientId={patientId}
        draftId={draftId}
        setGeneratedLink={setGeneratedLink}
        handleCustomRequest={handleCustomRequest}
        setCheckLowerFilename={setCheckLowerFilename}
        setCheckUpperFilename={setCheckUpperFilename}
        upperUploaders={upperUploaders}
        lowerUploaders={lowerUploaders}
      />
      <div className={css["link-preview-wrapper"]}>
        <LinkPreview generatedLink={generatedLink} />
      </div>
    </AppModal>
  );
};

GenerateThreeDModal.propTypes = {
  showGenerate3dModal: PropTypes.bool.isRequired,
  setShowGenerate3dModal: PropTypes.func.isRequired,
  upperAligner: PropTypes.number.isRequired,
  lowerAligner: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default GenerateThreeDModal;
