import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import store from "services/redux/store";
import "./index.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { datadogRum } from '@datadog/browser-rum';
dayjs.extend(utc);
dayjs.extend(timezone);

if (process.env.REACT_APP_STAGE === 'prod') {
  datadogRum.init({
    applicationId: '041b2bb7-ce5e-403a-a813-88064a8063d2',
    clientToken: 'pubbdc06a733cd70e5e79354593af8e5ca2',
    service: 'smilelign-lab-portal',
    env: 'production',
    site: 'datadoghq.eu',
    version: '1.0.0',
    trackUserInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
