import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Spin, message } from "antd";
import { useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { staffDropDowSelector } from "services/redux/selectors/labStaff.selector";
import PatientListComponent from "./PaientListTable/Table";
import { fetchLOV } from "services/redux/actions/labActions";
import { getPatientList } from "api/lab";
import { getLabStaff, assignStaffMember } from "api/staff";
import {
  getDurationStatusFilter,
  getEndDateFilter,
  getOrderStatusFilter,
  getStartDateFilter,
} from "utils/Lab";
import { colOptions } from "./constants";
// import { capitalizeFirstLetter } from "utils/commonMethod";
function PatientList({ lab, fetchLOV }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusParams = queryParams.get("status");

  const [patientsList, setPatientsList] = useState([]);
  const [isPatientTableSpinning, setIsPatientTableSpinning] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState(50);
  const [paginationPage, setPaginationPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [fromDate, setFromDate] = useState(getStartDateFilter(statusParams));
  const [endDate, setEndDate] = useState(getEndDateFilter(statusParams));
  const [selectedCols, setSelectedCols] = useState([]);

  const [orderStatus, setOrderStatus] = useState(() => {
    const filter = getOrderStatusFilter(statusParams);
    return filter;
  });
  const [slaDaysFilter, setSlaDaysFilter] = useState(
    getDurationStatusFilter(statusParams)
  );
  const [clinicNamesFilter, setClinicNamesFilter] = useState("");
  const [fromDateOrdered, setFromDateOrdered] = useState("");
  const [endDateOrdered, setEndDateOrdered] = useState("");

  const [phasingDates, setPhasingDates] = useState({
    startDate: "",
    endDate: "",
  });
  const abortControllerRef = useRef(null);
  // const [staffMembers, setStaffMembers] = useState([]);
  const labStaffMembers = useSelector(staffDropDowSelector);

  const handlePhasingQuery = useCallback(params => {
    console.log(params);

    const startDate = params.get("startDate") || "";
    const endDate = params.get("endDate") || "";

    if (startDate && endDate) {
      setPhasingDates({
        startDate,
        endDate,
      });
    }
  }, []);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   if (params?.size) {
  //     const colKey = params.get("column");
  //     if (colKey === "phasingDate") handlePhasingQuery(params);
  //   }
  // }, [location, handlePhasingQuery]);

  const _getPatientList = useCallback(
    async (phStart = "", phEnd = "") => {
      setIsPatientTableSpinning(true);

      try {
        const params = {
          limit: 5000,
          page: 1,
          //   clinicId: lab.clinicId,
          labId: lab.labId,
          search: searchKey,
          startDate: fromDate,
          endDate: endDate,
          fromDateOrdered: fromDateOrdered,
          endDateOrdered: endDateOrdered,
          orderStatusName: orderStatus.length ? `"${orderStatus}"` : "",
          clinicName: clinicNamesFilter !== "" ? `"${clinicNamesFilter}"` : "",
          slaDaysFilter: slaDaysFilter !== "" ? `"${slaDaysFilter}"` : "",
          phasingStartDate: phStart || phasingDates.startDate,
          phasingEndDate: phEnd || phasingDates.endDate,
          // labId:"LAB94912731"
        };

        const resData = await getPatientList(
          params,
          abortControllerRef.current
        );

        const list = resData?.data?.body?.data;

        setIsPatientTableSpinning(false);
        setPatientsList(list);
        setTotalRecords(list.length);
        //   setPatientsList(list.body.data);
      } catch (error) {
        console.log("error", error);
        setIsPatientTableSpinning(false);
        message.error("Failed to load patients list");
      }
    },
    [
      clinicNamesFilter,
      endDate,
      endDateOrdered,
      fromDate,
      fromDateOrdered,
      lab.labId,
      orderStatus,
      searchKey,
      slaDaysFilter,
      phasingDates,
    ]
  );

  const handlePatientAssignee = async (staffId, row) => {
    try {
      setIsPatientTableSpinning(true);
      await assignStaffMember({
        labStaffId: row?.staffPatientId || "",
        patientId: row.patientDetails.patientID,
        staffId: staffId ? staffId : 0,
      });
    } catch (error) {
      setIsPatientTableSpinning(false);
    } finally {
      if (abortControllerRef.current) {
        abortControllerRef.current = new AbortController(); // Create a new one for the next request
      }
      await _getPatientList();
    }
  };

  const rearrangePatientTableCols = (colList, cols) => {
    const colOrderMap = new Map(cols.map(col => [col.value, col.order]));

    return [...colList].sort((a, b) => {
      const orderA = colOrderMap.get(a) || Infinity;
      const orderB = colOrderMap.get(b) || Infinity;
      return orderA - orderB;
    });
  };

  useEffect(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    let sDate, eDate;
    const params = new URLSearchParams(location.search);
    if (params?.size) {
      const colKey = params.get("column");
      if (colKey === "phasingDate") {
        sDate = params.get("startDate") || "";
        eDate = params.get("endDate") || "";

        setSelectedCols(prevCols => {
          if (prevCols.includes("phasingDate")) {
            return prevCols;
          }

          const newCols = rearrangePatientTableCols(
            [...prevCols, "phasingDate"],
            colOptions
          );

          return newCols;
        });
      }
    }
    if (lab.labId && (searchKey.length >= 3 || searchKey === "")) {
      _getPatientList(sDate, eDate);
    }

    return () => {
      if (abortControllerRef.current) {
        // abortControllerRef.current.abort();
      }
    };
  }, [lab.labId, searchKey, _getPatientList, location]);

  useEffect(() => {
    if (lab?.reloadPatientList) {
      _getPatientList();
    }
  }, [lab.reloadPatientList, _getPatientList]);

  useEffect(() => {
    if (lab.labId) {
      fetchLOV();
    }
  }, [lab.labId, fetchLOV]);

  const onSearch = searchKey => {
    setSearchKey(searchKey);
    setPaginationPage(1);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationPage(pagination.current);
    setPaginationLimit(pagination.pageSize);
    if (filters && filters.orderStatus?.length > 0)
      setOrderStatus(filters.orderStatus.toString());
    else setOrderStatus("");
    if (filters && filters.clinicName?.length > 0)
      setClinicNamesFilter(filters.clinicName.toString());
    else setClinicNamesFilter("");

    if (filters && filters?.sla?.length > 0) {
      setSlaDaysFilter(filters.sla.toString());
    }
    if(statusParams){
      const filter = getOrderStatusFilter(statusParams);
      setOrderStatus(filter)
    }
  };

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromDate(start);
    setEndDate(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };
  const handleDateOrderedFilter = (confirm, start = "", end = "") => {
    setFromDateOrdered(start);
    setEndDateOrdered(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };

  const handlePhaseDates = (confirm, startDate = "", endDate = "") => {
    setPhasingDates({
      startDate,
      endDate,
    });
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };

  return (
    <div>
      <Spin spinning={isPatientTableSpinning}>
        <Row justify="center" style={{ width: "100%" }}>
          <PatientListComponent
            selectedCols={selectedCols}
            setSelectedCols={setSelectedCols}
            paginationLimit={paginationLimit}
            patientsList={patientsList}
            paginationPage={paginationPage}
            onSearch={onSearch}
            onPagination={handleTableChange}
            handleDateFilter={handleDateFilter}
            handlePhaseDates={handlePhaseDates}
            totalRecords={totalRecords}
            handleDateOrderedFilter={handleDateOrderedFilter}
            statusParams={statusParams}
            setTotalRecords={setTotalRecords}
            fromDate={fromDate}
            endDate={endDate}
            fromDateOrdered={fromDateOrdered}
            endDateOrdered={endDateOrdered}
            staffMembers={labStaffMembers}
            handlePatientAssignee={handlePatientAssignee}
            getPatients={_getPatientList}
            phasingDates={phasingDates}
            setPhasingDates={setPhasingDates}
          />
        </Row>
      </Spin>
    </div>
  );
}

const mapStateToProps = state => ({
  lab: state.lab,
});

export default connect(mapStateToProps, { fetchLOV })(PatientList);
