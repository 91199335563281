import React, { useState, useEffect } from "react";

import { message, Button, Typography, Row, Spin, Col, Form } from "antd";

import { connect, useSelector } from "react-redux";
import axios from "axios";
import { labApiTasks } from "models/lab";
import { loadLab } from "services/redux/actions/labActions";
import { saveProducts, getCurrencies, getProducts } from "api/lab";
import errorModal from "components/shared/modals/errorModal";
import ProfileForm from "./ProfileForm/ProfileForm";
import { labFormResources } from "api/lab";
import {
  getProfileButtonText,
  getUniquePrescriptionProducts,
  productServicesFormInitialValues,
  productsServiceMapper,
} from "utils/Lab";
import {
  confirmWhiteLabelFileAPI,
  getWhiteLabelling,
  saveWhiteLabelling,
  uploadWhiteLabelFileAPI,
} from "api/clinic";
import AppButton from "components/shared/AppButton/AppButton";
import { userRoleSelector } from "services/redux/selectors/lab.selectors";
import { isSuperAdmin } from "utils/auth";
import styles from "./profile.module.scss";
const { Title } = Typography;

function Profile({ lab, loadLab }) {
  const userRole = useSelector(userRoleSelector);
  const [loading, setLoading] = useState(false);
  const [isButtonSpinning, setIsButtonSpinning] = useState(false);
  const [labDetails, setLabDetails] = useState({});
  const [fileListTermsAndConditions, setFileListTermsAndConditions] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [productsAndServices, setProductAndServices] = useState([]);
  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] = useState([]);

  const [fileListForm, setFileListForm] = useState([]);
  const [mode, setMode] = useState("view");
  const [productList, setProductList] = useState([]);
  const [prescriptionProductList, setPrescriptionProductList] = useState([]);

  const [profileForm] = Form.useForm();
  const [productAndServicesForm] = Form.useForm();
  const [productFormData, setProductFormData] = useState([]);
  const [whiteLabelData, setWhiteLabelData] = useState(null);
  const [loginImageFile, setLoginImageFile] = useState([]);
  const [splashImageFile, setSplashImageFile] = useState([]);
  const [treatmentImageFile, setTreatmentImageFile] = useState([]);

  const [whiteLabelForm] = Form.useForm();
  const parseFiles = (files) => {
    let termsAndConditionFiles = [];
    let formFiles = [];
    files.forEach((file) => {
      if (file.lab_document_type === "Lab Terms & Conditions") {
        termsAndConditionFiles.push({
          name: file.file_name,
          status: "done",
          type: file.file_type,

          url: file.resource_url,
          documentType: file.lab_document_type,
        });
      } else if (file.lab_document_type === "Data Acquisition Form")
        formFiles.push({
          name: file.file_name,
          status: "done",
          type: file.file_type,

          url: file.resource_url,
          documentType: file.lab_document_type,
        });
    });

    return [termsAndConditionFiles, formFiles];
  };

  useEffect(() => {
    const fetchLabProfile = async () => {
      try {
        setLoading(true);

        const currencyRes = await getCurrencies();
        setProductOrServiceCurrencyOptions(currencyRes?.data?.data);

        productServicesValueSetter(null, currencyRes?.data?.data, null);
        const res = await labApiTasks.getAll("lab/getLabDetailsByLabId", {
          labId: lab?.labId,
        });

        const labDetailsFromDB = res.body.labServices[0];
        const labDetailsObject = {
          labName: labDetailsFromDB.lab_name,
          labAdminName: labDetailsFromDB.lab_admin_name,
          labServiceID: labDetailsFromDB.lab_service_id,
          businessRegistrationNo: labDetailsFromDB.lab_registration_number,
          email: labDetailsFromDB.lab_email,
          county: labDetailsFromDB.lab_county,
          country: labDetailsFromDB.lab_country,
          city: labDetailsFromDB.lab_city,
          address: labDetailsFromDB.lab_address,
          postalCode: labDetailsFromDB.lab_postal_code,
          phone: labDetailsFromDB.lab_phone_number,
          prefix: labDetailsFromDB.lab_country_code_name,
        };
        setLabDetails(labDetailsObject);

        await getLabProducts(currencyRes?.data?.data, labDetailsFromDB.refinementSeries);

        const resourceRes = await labFormResources(lab?.labId);
// console.log(resourceRes)
        // labApiTasks.getAll("lab/getResource", {
        //   labId: lab.labId,
        // });
        // const [termsAndConditionFiles, formFiles] = parseFiles(resourceRes.body.lab_resources);
        if(resourceRes?.data){
          setFileListForm(resourceRes.data);
        }
     
        // setFileListTermsAndConditions(termsAndConditionFiles);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (lab?.labId) {
      fetchLabProfile();
    }
  }, [lab?.labId]);

  useEffect(() => {
    const fetchLabWhiteLabel = async () => {
      try {
        setLoading(true);
        const { data } = await getWhiteLabelling(lab?.labId);
        setWhiteLabelData(data?.data?.settings);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (lab?.labId) {
      fetchLabWhiteLabel();
    }
  }, [lab?.labId]);

  const handleSubmit = () => {
    if (activeTab === "1") {
      profileForm.submit();
    }
    if (activeTab === "2") {
      productAndServicesForm.submit();
    } else {
      whiteLabelForm.submit();
    }
  };

  const productServicesValueSetter = (ps, def, pres, refinementSeries = 1) => {
    let formData;
    if (ps?.length > 0) {
      formData = productsServiceMapper(ps);
    } else {
      formData = productServicesFormInitialValues(def[0]?.currency_id);
    }

    productAndServicesForm.setFieldsValue({
      ...formData,
      prescriptionProducts: pres,
      refinementSeries,
    });

    setProductFormData(formData.productsServices);
  };


  const getLabProducts = async (tempProductServices, refinementSeries) => {
    const productsRes = await getProducts(lab?.labId);
    setProductAndServices(productsRes?.data?.labProducts);
    setPrescriptionProductList(productsRes?.data?.prescriptionPrducts);

    productServicesValueSetter(
      productsRes?.data?.labProducts,
      tempProductServices ? tempProductServices : productOrServiceCurrencyOptions[0]?.currency_id,
      productsRes?.data?.prescriptionPrducts,
      refinementSeries
    );
  };


  const onProfileFormSubmit = async (labDetails) => {
    const addLabBody = {
      labName: labDetails.labName,
      labAdminName: labDetails.labAdminName,
      labServiceID: labDetails.labServiceID,
      labRegistrationNumber: labDetails.businessRegistrationNo,
      labID: lab?.labId,
      labCountry: labDetails.country,
      labCity: labDetails.city,
      labAddress: labDetails.address,
      labCounty: labDetails.county ? labDetails.county : "",
      labZipcode: labDetails.postalCode,
      labPhoneNumber: labDetails.phone,
      labCountryCode: labDetails.prefix,
    };

    try {
      setIsButtonSpinning(true);
      await labApiTasks.post("lab/addLab", addLabBody);
    } catch (error) {
      errorModal(error.response.data.err.message);

      console.log(error);
    } finally {
      message.success("Lab details updated.");
      setMode("view");
      loadLab();
      // setLoading(false);
      setIsButtonSpinning(false);
    }
  };

  const onCancelProductClick = async () => {
    setMode("view");
    await getLabProducts();
  };

  const uploadService = async (preSignedResponse, file) => {
    // console.log("preSignedResponse", preSignedResponse, file);
    const config = {
      headers: {
        "content-type": file.type,
      },
    };
    // const accessUrl = preSignedResponse?.data.body.success.accessUrl;
    const uploadUrl = preSignedResponse?.data?.uploadUrl;
    const response = await axios.put(uploadUrl, file, config);
    return response;
  };

  const confirmService = async (preSignedResponse, labId, filename) => {
    const { url, fileName } = preSignedResponse?.data;
    const confirmRequestBody = {
      file_url: url,
      resource_type: "file",
      scan_type: fileName,
      case_id: 1,
      notify: false,
    };
    await confirmWhiteLabelFileAPI(confirmRequestBody);
  };

  const whiteLabelFileMapper = (fileObj, labId) => {
    const { name, type, imageType } = fileObj;
    const preSignRequestBody = {
      fileName: name,
      mimeType: fileObj.type,
    };
    return preSignRequestBody;
  };

  const uploadSingleFile = async (clinicalFile, labId, index) => {
    const preSignedRequestBody = whiteLabelFileMapper(clinicalFile, labId);
    // console.log("preSignedRequestBody", preSignedRequestBody);
    const preSignedResponse = await uploadWhiteLabelFileAPI(preSignedRequestBody, labId);
    await uploadService(preSignedResponse, clinicalFile);
    await confirmService(preSignedResponse, clinicalFile.filename);

    return {
      uploadedFile: preSignedResponse?.data.fileName,
      imageType: clinicalFile.imageType,
    };
  };

  return (
    <Spin spinning={loading}>
      <div className={styles["profile-container"]}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className={styles["heading"]}> Profile & Settings</h2>
          </Col>
          <Col>
            {isSuperAdmin(userRole) && (
              <>
                {mode === "view" ? (
                  <AppButton
                    type="primary"
                    onClick={() => setMode("edit")}
                    style={{ width: "100px" }}
                  >
                    {mode === "view" && "Edit"}
                  </AppButton>
                ) : null}
              </>
            )}
          </Col>
        </Row>

        <ProfileForm
          onProfileFormSubmit={onProfileFormSubmit}
          labDetails={labDetails}
          fileListTermsAndConditions={fileListTermsAndConditions}
          fileListForm={fileListForm}
          mode={mode}
          setMode={setMode}
          profileForm={profileForm}
        />

        {mode !== "view" && (
          <Row justify="end">
            <Col>
              <div className="footer-buttons">
                {mode === "edit" && (
                  <AppButton
                    shape="round"
                    onClick={onCancelProductClick}
                    className={styles["footer-btn"]}
                    style={{ marginRight: "15px" }}
                  >
                    Cancel
                  </AppButton>
                )}
                <AppButton
                  type="primary"
                  shape="round"
                  // htmlType="submit"
                  loading={isButtonSpinning}
                  onClick={handleSubmit}
                  className={styles["footer-btn"]}
                >
                  {getProfileButtonText(lab?.isFirstLogin, activeTab)}
                </AppButton>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps, { loadLab })(Profile);
