import { useState, useEffect } from "react";
import { Button, message, Spin, Popconfirm, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as EmptyList } from "assets/images/EmptyList.svg";
import { ReactComponent as Users } from "assets/images/Users.svg";
import { ReactComponent as Eye } from "assets/images/Eye.svg";
import ResourceShareModal from "../ResourceShareModal/ResourceShareModal";
import ResourceViewEditModal from "../ResourceViewEditModal/ResourceViewEditModal";
import {
  getResources,
  deleteResource,
  onSearchResources,
  searchLabResources,
} from "api/resource";
import { labApiTasks } from "models/lab";
import { primaryColor } from "colors-config";

const ResourcesList = ({
  lab,
  resources,
  SetResources,
  searchValue,
  setSearchLoading
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [shareId, setShareId] = useState(null);
  const [sharedList, setSharedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partneredClinics, setPartneredClinics] = useState([]);
  const [sharedClinics, setShardClinics] = useState([]);

  const [resourceDetail, setResourceDetail] = useState(null);

  useEffect(() => {
    if (lab.labId) {
      _getPartnered();
    }
  }, [lab.labId]);

  useEffect(() => {
    if (lab.labId) {
      if (searchValue) {
        onSearchResourcesApi();
      } else {
        getLabResourcesApi();
      }
    }
  }, [searchValue, lab.labId]);

  const onSearchResourcesApi = async () => {
    try {
      setSearchLoading(true);
      const searchResponse = await searchLabResources(lab.labId, {
        search: searchValue,
      });
      // console.log("searchResponse", searchResponse);
      const finalRes = mapResourceData(searchResponse?.data?.data);
      SetResources(finalRes);
    } catch (error) {
      console.log("error", error);
    } finally {
      setSearchLoading(false);
    }
  };

  const getLabResourcesApi = async () => {
    setLoading(true);
    try {
      const res = await getResources(lab.labId);
      const finalRes = mapResourceData(res?.data?.resources);
      SetResources(finalRes);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const mapResourceData = (resourceList) => {
    return resourceList.map((resource) => {
      return {
        name: { resourceName: resource.name, resourceId: resource.id },
        shareList: {
          clinics: resource.shared,
          resourceId: resource.id,
          allClinic: resource.allClinic,
        },
        category: resource.category,
        uploaded: resource.createdAt.split("T")[0],
        files: resource.files.length
          ? resource.files.filter((file) => file.mimeType !== "link")
          : [],
        id: resource.id,
        description: resource.description,
        videoLinks: resource.files.length
          ? resource.files.filter((file) => file.mimeType === "link")
          : [],
      };
    });
  };

  const handleRowClick = record => {
    if(record?.name?.resourceId ){
      const filterResource = resources.find((resource) => resource.id === record?.name?.resourceId);
      setResourceDetail(filterResource);
      setShowDetailModal(true);
    }
  };
  

  const handleDeleteResource = async (id) => {
    // console.log("deleteResource", id);
    setLoading(true);
    try {
      const res = await deleteResource(id, lab.labId);
      // console.log("res", res);

      const prevResources = [...resources];
      const filterResource = prevResources.filter(
        (resource) => resource.id !== id
      );
      // console.log("filterResource", filterResource);
      SetResources(filterResource);
      message.success("Resource deleted successfully");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShareResource = (list, id, allClinic) => {
    let shares = [];
    if (allClinic) {
      shares = ["all"];
    } else {
      if (list.length) {
        shares = list.map((clinic) => clinic.clinicId);
      }
    }
    // console.log("shares", shares);
    setSharedList(shares);
    setShareId(id);
    setShardClinics(list);
    setShowShareModal(true);
  };

  const _getPartnered = async () => {
    try {
      const partneredRes = await labApiTasks.getAll("lab/clinicList", {
        limit: 50000,
        page: 1,
        labId: lab.labId,
        search: "",
        status: "Partnered",
      });
      if (partneredRes?.body) {
        const partnerOptions = partneredRes.body?.data.map((partner, index) => {
          return {
            label: partner.clinic_name
              ? `${partner.clinic_name} (${partner.clinic_email})`
              : `clinic ${index}`,
            value: partner.clinic_id,
            disabled: false,
          };
        });
        partnerOptions.unshift({
          label: "All Clinicians",
          value: "all",
        });
        setPartneredClinics(partnerOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (resource) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <p>{resource.resourceName}</p>
          </div>
        );
      },
    },
    {
      title: "Shared with",
      dataIndex: "shareList",
      key: "shareList",
      render: (resource) => {
        const { clinics, resourceId, allClinic } = resource;
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: 'flex-start' }}
            onClick={(e) =>{
              e.preventDefault();
              e.stopPropagation();
              handleShareResource(clinics, resourceId, allClinic)
            }}
          >
            {allClinic ? (
              <p className="share-tab" style={{ marginRight: "20px" }}>
                All Clinicians
              </p>
            ) : (
              <>
                {clinics.length ? (
                  <p
                    className="share-tab"
                    style={{ marginLeft: "30px", marginRight: "65px" }}
                  >
                    {clinics?.length}
                  </p>
                ) : (
                  <p
                    style={{
                      marginLeft: "35px",
                      marginRight: "85px",
                      display: "inline-block",
                    }}
                  >
                    --
                  </p>
                )}
                {/* {clinics.length
              ? clinics?.map((clinic, i) => {
                  return <p className="share-tab" key={i}>{clinic?.clinicDetails?.clinic_name}</p>;
                })
              : "---"} */}
              </>
            )}

            <Button
              shape="round"
              type="primary"
              style={{
                marginLeft: "1rem",
                display: "flex",
                alignItems: "center",
              }}
              icon={<Users />}
            >
              Share
            </Button>
          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Uploaded on",
      dataIndex: "uploaded",
      key: "uploaded",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => {
        return (
          <Popconfirm
            okButtonProps={{
              style: {
                width: "7rem",
                background: primaryColor,
                borderColor: primaryColor,
                borderRadius: "30px",
                color: "#fff",
                height: "32px",
                padding: "4px 15px",
                marginTop: "10px",
              },
            }}
            cancelButtonProps={{
              style: {
                width: "7rem",
                borderRadius: "30px",
                height: "32px",
                padding: "4px 15px",
                marginTop: "10px",
              },
            }}
            title="Delete Resource"
            description="Are you sure to delete this resource?"
            onConfirm={() => {
              handleDeleteResource(id)
            }}
            onCancel={(e) => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button
            style={{border:"none", boxShadow:"none"}}
              className="delete-btn"
              icon={<DeleteOutlined />}
              // onClick={() => handleDeleteResource(id)}
            ></Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div className="resources-list-container">
      <Spin spinning={loading}>
        {showShareModal && (
          <ResourceShareModal
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            lab={lab}
            shareId={shareId}
            resources={resources}
            SetResources={SetResources}
            sharedList={sharedList}
            partneredClinics={partneredClinics}
            sharedClinics={sharedClinics}
            setPartneredClinics={setPartneredClinics}
          />
        )}
        {showDetailModal && (
          <ResourceViewEditModal
            showDetailModal={showDetailModal}
            setShowDetailModal={setShowDetailModal}
            resource={resourceDetail}
            lab={lab}
            resources={resources}
            SetResources={SetResources}
          />
        )}
        
        <Table
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Handle row click
          })}
          dataSource={resources}
          columns={columns}
          scroll={{ x: "max-content" }}
        />
        {/* <div className="empty-resources-list">
            <EmptyList />
          </div> */}
      </Spin>
    </div>
  );
};

export default ResourcesList;
