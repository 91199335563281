import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Spin,
  Select,
  Tooltip,
  Checkbox,
  message,
  Typography,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import errorModal from "../../components/shared/modals/errorModal";
import SignUpConfirmation from "../../components/private/signUpConfirmation";
import axios from "axios";
import {
  LogInPageLogo,
  companyName,
  companyType,
  prefix,
  primaryColor,
  smilePrivacyUrl,
  smileTermsUrl,
} from "colors-config";
import { getPatientCountryCodesList } from "services/redux/selectors/commonSelectors.js";
import { Auth } from "aws-amplify";
import { AnchorTag } from "components/shared/AnchorTag/AnchorTag";
import { RedirectLink } from "components/shared/RedirectLink/RedirectLink";
import { passwordValidator } from "utils/Lab";
import { confirmPasswordErrorMessage } from "messages/messages";
import { addTempLabRegistrationApi } from "api/lab";
import { validateStaffEmail, updateLabStaffStatus } from "api/staff";

import { LAB_REGISTRATION_TYPES } from "../../constants";
import "../../assets/styles/routes/registration.scss";

const PUBLIC_API_BASE_URL = process.env.REACT_APP_CLINIC_BASE_URL;
const DELETE_USER_PUBLIC = process.env.REACT_APP_DELETE_USER_PUBLIC;

const { Title } = Typography;

export default function Registration() {
  const location = useLocation();
  const history = useHistory();
  const [patientEmail, setPatientEmail] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [isButtonSpinning, setIsButtonSpinning] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isButtonEnable, setIsButtonEnable] = useState(true);
  const containerRef = useRef();

  const [form] = Form.useForm();
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState("");
  const patientCountriesList = useSelector(getPatientCountryCodesList);

  const [disableEmail, setDisableEmail] = useState(false);

  const registrationRef = useRef(null);

  const updateStaffMemberStatus = async (email) => {
    try {
      await updateLabStaffStatus({ staffEmail: email });
    } catch (error) {
      console.log("error", error);
    }
  };

  const validateStaffEmailHandler = useCallback(
    async ({ labId, staffEmail }) => {
      setIsSpinning(true);
      try {
        const res = await validateStaffEmail({ labId, staffEmail });

        if (res?.data?.success) {
          setDisableEmail(true);
          form.setFieldsValue({
            email: staffEmail,
            prefix: res.data?.data.countryCode,
            phone: res.data?.data.phone,
          });
        }
      } catch (error) {
        let errorMessage = "access not allowed";
        if (error?.response?.data?.message) {
          errorMessage = error?.response?.data?.message;
        }
        message.error(errorMessage);
        history.push("/login");
      } finally {
        setIsSpinning(false);
      }
    },
    [form, history]
  );

  const handleKeyParamCases = useCallback(
    (data) => {
      if (data?.type === LAB_REGISTRATION_TYPES.STAFF_REGISTRATION) {
        validateStaffEmailHandler(data);
      }
    },
    [validateStaffEmailHandler]
  );

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const keyParam = queryParams.get("key");
      if (keyParam) {
        const data = JSON.parse(atob(keyParam));

        if (data) {
          registrationRef.current = data;
          handleKeyParamCases(data);
        }
      }
    } catch (error) {
      console.log(error?.message || "invalid operation");
    }
  }, [location, handleKeyParamCases]);

  useEffect(() => {
    // form.resetFields();
  }, [patientCountriesList, form]);

  useEffect(() => {
    setIsSpinning(true);

    _getPolicyLinks();
  }, []);

  const _getPolicyLinks = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_CLINIC_BASE_URL}/public/getGenericTC`);
      let termsUrl = smileTermsUrl;
      let privacyUrl = smilePrivacyUrl;
      if (res?.data?.body?.privacy_policy?.url && res?.data?.body?.terms_of_service?.url) {
        // if (companyType !== 1) {
          termsUrl = res?.data?.body?.terms_of_service?.url;
          privacyUrl = res?.data?.body?.privacy_policy?.url;
        // }
      }
      setPrivacyPolicyUrl(privacyUrl);
      setTermsOfServiceUrl(termsUrl);
      setIsSpinning(false);
    } catch (error) {
      console.log(error);
      setIsSpinning(false);
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        dropdownClassName="country-dropdown"
        className="country-select"
        size="large"
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        labelRender={(props) => {
          return props.label;
        }}
        options={patientCountriesList?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={(option) => {
          const { data } = option;
          return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );

  const _register = async ({ email, username, password, phone_number, phone, prefix }) => {
    try {
      try {
        const res = await axios.post(
          `${PUBLIC_API_BASE_URL}/public/checkLabEmailExists?email=${email}`
        );

        if (res?.data?.statusCode === 500) {
          throw new Error(res?.data?.body?.err?.msg);
        }
      } catch (error) {
        errorModal("The email is already registered");
        setIsButtonSpinning(false);

        throw error;
      }

      await Auth.signUp({
        username,
        password,
        attributes: {
          email, // optional
          phone_number, // optional - E.164 number convention
          // other custom attributes
        },
      });

      await addTempLabRegistrationApi({
        labEmail: username,
        phoneCode: prefix,
        phoneNo: phone,
      });
      if (
        registrationRef &&
        registrationRef?.current?.type === LAB_REGISTRATION_TYPES.STAFF_REGISTRATION
      ) {
        await updateStaffMemberStatus(username);
      }

      setIsRegistered(true);
      setPatientEmail(email);
      setIsButtonSpinning(false);
      history.push("/confirm-user?type=registered")
    } catch (error) {
      setIsButtonSpinning(false);

      if (error.code === "UsernameExistsException") {
        console.log(error, "error");

        try {
          await axios.get(DELETE_USER_PUBLIC, {
            params: { email, user: "Lab" },
          });
          // console.log(res);
          _register({ email, username, password, phone_number, phone, prefix });
        } catch (err) {
          console.log(err);
        }
      } else if (error.code && error.code !== "UsernameExistsException") {
        errorModal(error.message);
      }

      setIsRegistered(false);
    }
  };

  const onFinish = (values) => {
    const { email, password, phone, prefix } = values;
    const phone_number = prefix + phone;
    setIsButtonSpinning(true);

    const cognitoRegistrationData = {
      username: email,
      password,

      email,
      phone_number,
      phone,
      prefix,
    };
    _register(cognitoRegistrationData);
  };

  return (
    <div ref={containerRef} className="registration-container" style={{ fontFamily: "Mulish" }}>
      <Row>
        <Col lg={12}>
          <div className="registration-image-container">
            <img className="image" src={LogInPageLogo} alt="placeholder" />
          </div>
        </Col>

        <Col lg={12} md={24} sm={24} xs={24}>
          {isRegistered && <SignUpConfirmation username={patientEmail}></SignUpConfirmation>}
          {!isRegistered && (
            <div className="registration-form-container">
              <div className="register-form">
                <div className="login-title-container">
                  <Title
                    level={2}
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      marginBottom: "0.1rem",
                    }}
                  >
                    Lab Registration
                  </Title>

                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                    }}
                  >
                    Let’s take the first step to start your journey with {companyName}
                  </p>
                </div>
                <Spin spinning={isSpinning}>
                  <Form
                    className="registration-form"
                    form={form}
                    initialValues={{
                      prefix: prefix,
                      phone: "",
                    }}
                    layout={"vertical"}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="email"
                      label="Email address"
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email address!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input disabled={disableEmail} placeholder="Email address" size="large" />
                    </Form.Item>

                    <Form.Item
                      name="phone"
                      label="Phone number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                        () => ({
                          validator(_, value) {
                            const convertedValue = Number(value);
                            if (!value) {
                              return Promise.resolve();
                            } else if (
                              !isNaN(convertedValue) &&
                              (value.length === 9 || value.length === 10 || value.length === 8)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Invalid phone number");
                          },
                        }),
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        style={{
                          width: "100%",
                        }}
                        size="large"
                        placeholder="Phone Number"
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      label={
                        <span>
                          Password&nbsp;
                          <Tooltip
                            overlayClassName="registerInfo"
                            title={
                              <ul style={{ padding: "5px 10px" }}>
                                <li>At least 1 lowercase alphabetical character</li>
                                <li>At least 1 uppercase alphabetical character</li>
                                <li>At least 1 numeric character</li>
                                <li>At least 1 special character</li>
                                <li>Minimum 8 characters</li>
                              </ul>
                            }
                          >
                            <InfoCircleOutlined
                              style={{
                                color: primaryColor,
                                marginLeft: "0.5rem",
                              }}
                            />
                          </Tooltip>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()\-_+=!@#%^&*.,><’:;|~`])\S{8,99}$/,
                          message: "Invalid password",
                        },
                        {
                          validator: passwordValidator,
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="Password" size="large" />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      label="Confirm password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(confirmPasswordErrorMessage);
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Confirm Password" size="large" />
                    </Form.Item>
                    <Form.Item>
                      <div style={{ textAlign: "left" }}>
                        <p>
                          <Checkbox
                            onChange={() => {
                              setIsButtonEnable(!isButtonEnable);
                            }}
                          >
                            I agree with {companyName}
                            's{" "}
                            <AnchorTag
                              url={termsOfServiceUrl}
                              target="_blank"
                              rel="noreferrer"
                              text="Terms of Service"
                            />
                            &nbsp;and&nbsp;
                            <AnchorTag
                              url={privacyPolicyUrl}
                              target="_blank"
                              rel="noreferrer"
                              text="Privacy Policy"
                            />
                          </Checkbox>
                        </p>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div className="btn-container" style={{ marginTop: "-40px" }}>
                        <Button
                          loading={isButtonSpinning}
                          disabled={isButtonEnable}
                          type="primary"
                          className="registration-button"
                          htmlType="submit"
                        >
                          Create my account
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </Spin>
                <Row justify="start">
                  <Col
                    xs={{
                      span: 24,
                      offset: 0,
                    }}
                    sm={{
                      span: 16,
                      offset: 4,
                    }}
                    lg={{
                      span: 16,
                      offset: 2,
                    }}
                    xl={{ span: 16, offset: 2 }}
                    xxl={{ span: 16, offset: 2 }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#1D1D1D",
                        fontSize: "17px",
                        display: "inline",

                        width: "100%",
                      }}
                    >
                      Already have an account?
                    </p>
                    <p
                      style={{
                        fontSize: "17px",
                        display: "inline",
                        marginLeft: "0.5rem",
                        fontWeight: "bolder",

                        width: "100%",
                      }}
                    >
                      <RedirectLink text={"Log in"} url={"/login"} />
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
