import { memo } from "react";
import { Row, Col,Card} from "antd";
import { removeUnderscore } from "../utils";
const Treatment = ({overjet,overbite,crossBite, midline, openbite, openBiteValue, crossBiteValue }) => {

  return (
    <div>
        <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Treatment Instructions</h1>
             <Row gutter={[8,8]}>
                <Col lg={5} md={12} sm={24} className="border-right">
                  <h2 className="caption">Overjet</h2>
                  <p className="content">{removeUnderscore(overjet)}</p>
                </Col>
                <Col lg={5} md={12} sm={24} className="border-right">
                  <div className="paddingLeft">
                    <h2 className="caption">Overbite</h2>
                    <p className="content">{removeUnderscore(overbite)}</p>
                  </div>
                </Col>

                <Col lg={5} md={12} sm={24}  className="border-right">
                  <div className="paddingLeft">
                    <h2 className="caption">Openbite</h2>
                    <p className="content">{removeUnderscore(openbite)}</p>
                    {
                      openBiteValue && (
                        <p className="content">{removeUnderscore(openBiteValue) }</p>
                      )
                    }
                  
                  </div>
                </Col>
                <Col lg={5}  md={12} sm={24} className="border-right">
                  <div className="paddingLeft">
                    <h2 className="caption">CrossBite</h2>
                    <p className="content">{removeUnderscore(crossBite)}</p>
                    {
                      crossBiteValue && (
                        <p className="content">{ removeUnderscore(crossBiteValue)}</p>
                      )
                    }
                
                  </div>
                </Col>
                <Col lg={4} md={12} sm={24}>
                  <div className="paddingLeft">
                    <h2 className="caption">Midline</h2>
                    <p className="content">{removeUnderscore(midline)}</p>
                  </div>
                </Col>
              </Row>
      </Card>
    </div>
  );
};

export default memo(Treatment);
