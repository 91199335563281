import { useState, useEffect } from "react"
import { Upload, message, Modal } from "antd"
import {  ExclamationCircleFilled } from "@ant-design/icons"
import css from "./uploadFile.module.scss"
import { primaryColor } from "colors-config";
import { hexToHsl, hslToHex } from "utils/commonMethod";

const { confirm } = Modal;



const lightenColor = (hex, percent) => {
  const hsl = hexToHsl(hex);
  hsl.l = Math.min(100, hsl.l + percent); // Increase lightness
  return hslToHex(hsl.h, hsl.s, hsl.l);
};


const BulkFileUploader = ({
  upperAligner,
  lowerAligner,
  upperImages,
  lowerImages,
  handleCustomRequest
}) => {
  const [fileList, setFileList] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);

  // Function to parse file name and determine its type and number
  const parseFileName = (fileName) => {
    fileName = fileName.toLowerCase()

    // Check if it's an upper or lower file
    const isUpper = fileName.includes("upper")
    const isLower = fileName.includes("lower")

    // Extract the number from the file name
    const numberMatch = fileName.match(/\d+/)
    const fileNumber = numberMatch ? Number.parseInt(numberMatch[0], 10) : null

    return {
      isUpper,
      isLower,
      fileNumber,
      isValid: (isUpper && fileNumber !== null && fileNumber <= upperAligner) || (isLower && fileNumber !== null && fileNumber <= lowerAligner),
    }
  }

  // Process all files and distribute them to the correct sections
  const processFiles = async (file) => {
    const { isUpper, isLower, fileNumber, isValid } = parseFileName(file.name);

    if (!isValid) {
      setInvalidFiles((prev) => [...prev, file]);
      return;
    }

    setValidFiles((prev) => [...prev, file]);

    try {
      // Simulate file upload and processing
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Update the appropriate state array
      if (isUpper && (fileNumber <= upperAligner) && isValid) {
        await handleCustomRequest({ file }, fileNumber, "upper");
        setValidFiles((prev) => prev.filter((item) => item.uid !== file.uid))
      } else if (isLower && (fileNumber <= lowerAligner) & isValid) {
        await handleCustomRequest({ file }, fileNumber, "lower");
        setValidFiles((prev) => prev.filter((item) => item.uid !== file.uid))
      }
      setFileList((prev) => prev.filter((item) => item.uid !== file.uid))
      
    } catch (error) {
      message.error("Failed to upload file");
    }
  };

    // Validate file count and show messages
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
      };
    };
      
    // Validate file count and show only one message at a time
    const validateFileCount = debounce(() => {
      const totalRequiredFiles = upperAligner + lowerAligner + 2;
      const totalValidFiles = validFiles.length;
      const totalInvalidFiles = invalidFiles.length;
    
      // Clear previous messages
      message.destroy();
    
      const showMessageWithDelay = (msgFn, content, delay = 500) => {
        setTimeout(() => {
          msgFn(content);
        }, delay);
      };
    
      if (totalValidFiles === 0 && totalInvalidFiles > 0) {
        showMessageWithDelay(message.error, "All selected files have unrecognized file type/file name");
      } else if ((totalValidFiles + totalInvalidFiles) > totalRequiredFiles) {
        showMessageWithDelay(message.error, "The selected files exceed the total aligner count");
      } else if ((totalValidFiles < totalRequiredFiles)) {
        showMessageWithDelay(message.warning, "The selected files are fewer than the aligner count");
      } else if (totalValidFiles === totalRequiredFiles && totalInvalidFiles === 0) {
        showMessageWithDelay(message.success, "All files are uploading successfully");
      }
    
      if (totalValidFiles > 0 && totalInvalidFiles > 0) {
        showMessageWithDelay(message.warning, 
          `${totalInvalidFiles}/${fileList.length} files are not uploading due to unrecognized file type/file name`);
      }
    
      // Clear states after messages
      setTimeout(() => {
        setInvalidFiles([]);
        setValidFiles([]);
        setFileList([]);
      }, 1200);
    
    }, 800); 
  
    // Trigger validation when fileList, validFiles, or invalidFiles change
    useEffect(() => {
      if(fileList.length > 0 || validFiles.length > 0 || invalidFiles.length > 0)
        validateFileCount();
    }, [fileList, validFiles, invalidFiles]);

    const handleClick = (e) => {

      setFileList([])
      setInvalidFiles([])
      setValidFiles([])
      e.stopPropagation();
      const isOverwrite = upperImages.some(i => i.length) || lowerImages.some(i => i.length);
  
      if (isOverwrite) {
  
        confirm({
          title: "File Overwrite Warning",
          icon: <ExclamationCircleFilled />,
          content: "You are about to upload files again. This will overwrite your existing uploaded files. Are you sure you want to continue?",
          onOk() {
            setTimeout(() => {
              const input = document.querySelector(".tp-upload-dragger .ant-upload input[type='file']");
              if (input) {
                input.setAttribute("accept",".obj");// ✅ Force only .obj files
                input.setAttribute("multiple", "multiple"); // ✅ Allow multiple files
                input.click();
              }
            }, 200);
          },
          onCancel() {
          },
          okButtonProps: {
            style: {
                borderRadius: '50px',
                backgroundColor: primaryColor
            }
        },
        cancelButtonProps: {
          style: {
              borderRadius: '50px'
          }
      }
    
        });
        return; // Stop default behavior
      }
      setTimeout(() => {
        const input = document.querySelector(".tp-upload-dragger .ant-upload input[type='file']");
        if (input) {
          input.setAttribute("accept",".obj"); // ✅ Force only .obj files
          input.setAttribute("multiple", "multiple"); // ✅ Allow multiple files
          input.click();
        }
      }, 200);
  
    };

    return (
    <div className={css["bulk-upload-container"]}>
      <div
        style={{ background: lightenColor(primaryColor, 20), borderRadius: "10px", marginBottom: "20px" }}
      >
        <Upload.Dragger
          className="tp-upload-dragger"
          multiple
          fileList={fileList}
          showUploadList={false}
          beforeUpload={(file) => {
            
              setFileList((prev) => [...prev, file])
              processFiles(file);

            
              return new Promise((resolve) => {
                resolve(false); // Prevent default behavior
              });
          }}
          onRemove={(file) => {
            setFileList((prev) => prev.filter((item) => item.uid !== file.uid))
          }}
        >
          <div onClick={handleClick} style={{ cursor: "pointer" }}>
          <p className="ant-upload-drag-icon scan-upload-drag-icon">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="upload"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
            </svg>
          </p>
          <p className="ant-upload-text">Click or drag all .OBJ files to this area</p>
          <p className="ant-upload-hint">
            Bulk Upload
          </p>
          </div>
        </Upload.Dragger>
      </div>
    </div>
  )
}

export default BulkFileUploader