import { Radio, Row, Col, Input, Menu, Dropdown, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import TitleComponent from "components/shared/titleComponent";
import styles from "../../labPartners.module.scss";
import { PARTNER_TABS } from "constants";
import AppButton from "components/shared/AppButton/AppButton";
import { ShowTotalNumber } from "components/shared/ShowTotalNumber/ShowTotalNumber";
const { Title } = Typography;
const Header = ({
  onSearch,
  setShowClinicModal,
  setShowCsvUpload,
  setInviteActionType,
  setShowRegistraionLinkModal,
  tablePagination,
}) => {
  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)} className="partnersMenu">
      <Menu.Item key="manually">Add Manually</Menu.Item>
      <Menu.Item key="csv">Upload File</Menu.Item>
      <Menu.Item key="onboarding_link">Onboarding Link</Menu.Item>
      <Menu.Item key="resend">Resend Invites</Menu.Item>
      <Menu.Item key="delete">Delete Invites</Menu.Item>
    </Menu>
  );

  const handleMenuClick = (key) => {
    if (key === "manually" || key === "csv") {
      setShowCsvUpload(key !== "manually");
      setShowClinicModal(true);
    }
    if (key === "resend" || key === "delete") {
      setInviteActionType(key);
    }
    if (key === "onboarding_link") {
      setShowRegistraionLinkModal(true);
    }
  };
  return (
    <div className={styles.partnersContainer}>
      <div className={styles.headerRow}>
        <Row justify="space-between" align="center" gutter={[12,8]}>
          <Col lg={10} md={{ span: 24}} sm={{ span: 24}} xs={{ span: 24}}>
            <div className="headerListPatient">
              <Title level={3}>Clinician List</Title>
              <ShowTotalNumber total={tablePagination?.total} className={"patientListLength"} />
            </div>
          </Col>
          <Col lg={9} md={{ span: 24}} sm={{ span: 24}} xs={{ span: 24}}>
            <div className={styles.searchInput}>
              <Input.Search
                onSearch={(e) => onSearch(e)}
                allowClear={true}
                enterButton={true}
                addonAfter={null}
                className="searchBox"
                placeholder="Search by Clinician Name,Clinic Name, Clinic Email, Phone"
                size="large"
              />
              <div>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <AppButton data-testid="partner-action-button" shape="round" type="primary">
                    Actions <DownOutlined />
                  </AppButton>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
