import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Checkbox,
  Col,
  Divider,
  Modal,
  message,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { defaultAppCurrency } from "colors-config";
import { prescriptionProductMessage, productPriceErrorMessage } from "messages/messages";
import AppButton from "components/shared/AppButton/AppButton";
import DraggableList from "../DraggableProducts/DraggableProducts";
import AppToolTip from "components/shared/AppToolTip/AppToolTip";

const ProductServicesForm = ({
  mode,
  productOrServiceCurrencyOptions,
  productAndServicesForm,
  onProductsFormSubmit,
  setProductsHide,
  productsHide,
  defaultCurrency,
  lab,
  setProductList,
  productList,
  productFormData,
  setProductFormData,
  prescriptionProductsHide,
  setPrescriptionProductsHide,
  setPrescriptionProductList,
  prescriptionProductList,
}) => {
  const [productListSearch, setProductListSearch] = useState(productList);
  const isDisabled = mode === "view";
  const [productName, setProductName] = useState("");
  const [prescriptionProductName, setPrescriptionProductName] = useState("");
  let [deleteFileOpen, setDeleteFileOpen] = useState(false);
  let [deleteFileData, setDeleteFileData] = useState();

  let [deleteProductNameOpen, setDeleteProductNameOpen] = useState(false);
  let [deleteProductNameData, setDeleteProductNameData] = useState();
  const inputRef = useRef();

  const handleCheckboxChange = (index, value) => {
    const hideArray = [...productsHide];
    hideArray[index] = value;
    setProductsHide(hideArray);
  };

  const handlePrescriptionCheckboxChange = (index, value) => {
    const hideArray = [...prescriptionProductsHide];
    hideArray[index] = value;
    setPrescriptionProductsHide(hideArray);
  };

  const addItem = () => {
    let pName = productName.trim();
    if (pName === "") {
      return message.error("Please enter a product name");
    }
    const previousProducts = [...productList];
    const filteredProduct = previousProducts.filter(
      (obj) => obj.productName.toLowerCase() === pName.toLowerCase()
    );
    if (filteredProduct.length) {
      return message.error("Product name already exists");
    }
    setProductList([...productList, { productName: pName, value: pName }]);
    setProductName("");
    inputRef.current.focus();
  };

  useEffect(() => {
    setProductListSearch(productList);
  }, [productList]);

  useEffect(() => {
    if (productName) {
      setProductListSearch(
        productList.filter((obj) => {
          if (
            obj?.productName?.toLowerCase().includes(productName?.toLowerCase())
          ) {
            return true;
          }
        })
      );
    } else {
      setProductListSearch(productList);
    }
  }, [productName]);

  const checkPriceLabel = (index) => {
    if (productFormData[index]?.quantityType) {
      if (productFormData[index].quantityType == "Package") {
        return "Total Price";
      } else {
        return "Price per Unit";
      }
    }
    return "Price";
  };

  const checkFreeProduct = (index) => {
    return productFormData[index]?.free ? true : false;
  };

  const handleFileOk = () => {
    let tempProductFormData = [...productFormData];
    tempProductFormData.splice(deleteFileData.index, 1);
    setProductFormData(tempProductFormData);
    deleteFileData.remove(deleteFileData.index);
    setDeleteFileOpen(false);
  };

  const handleFileCancel = () => {
    setDeleteFileOpen(false);
  };

  const onLabProductDeleteClick = (e, item, index) => {
    e.stopPropagation();
    const productNameList =
      productAndServicesForm.getFieldValue("productsServices");
    if (productNameList[index]?.productName === item?.productName) {
      message.error("Kindly select another name and then delete.");
      return;
    }
    setDeleteProductNameData({ ...item, type: "product" });
    setTimeout(() => {
      setDeleteProductNameOpen(true);
    }, 500);
  };

  const handleProductNameOk = () => {
    let tempProductNameListData;
    if (deleteProductNameData.type === "prescription") {
      tempProductNameListData = prescriptionProductList.filter(
        (obj) => obj.treatmentName !== deleteProductNameData.treatmentName
      );
      setPrescriptionProductList(tempProductNameListData);
    } else {
      tempProductNameListData = productList.filter(
        (obj) => obj.productName !== deleteProductNameData.productName
      );
      setProductList(tempProductNameListData);
    }

    setDeleteProductNameOpen(false);
    setDeleteProductNameData();
  };

  const handleProductNameCancel = () => {
    setDeleteProductNameOpen(false);
    setDeleteProductNameData();
  };

  const handleFreeChange = (value, index) => {
    const tempProductFormData = [...productFormData];
    tempProductFormData[index].free = value;
    setProductFormData(tempProductFormData);
    updateFreeErrorsArray("price", index);
  };

  const updateFreeErrorsArray = (fieldName, index) => {
    productAndServicesForm.validateFields((errors, values) => {
      if (!errors) {
        const updatedFields = [
          ...productAndServicesForm.getFieldValue("productsServices"),
        ];
        updatedFields[index][fieldName] = undefined;
        productAndServicesForm.setFields([
          { name: "price", value: updatedFields },
        ]);
      }
    });
  };

  const numberValidator = (_, value) => {
    const floatValue = parseFloat(value);
    if (value < 1) {
      return Promise.reject(new Error(productPriceErrorMessage));
    }

    if (!value || /^[0-9]*[.,]?[0-9]+$/.test(value)) {
      if (floatValue > 0 && floatValue <= 50000) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          new Error("Price cannot be greater than 50,000!")
        );
      }
    }

    return Promise.reject(new Error("Please enter a valid number!"));
  };

  const numberFreeValidator = (_, value) => {
    return Promise.resolve();
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const fields = productAndServicesForm.getFieldValue("productsServices");
    const [reorderedItem] = fields.splice(result.source.index, 1);
    fields.splice(result.destination.index, 0, reorderedItem);
    productAndServicesForm.setFieldsValue({
      productsServices: fields,
    });
  };

  return (
    <div>
      <Form
        onFinish={onProductsFormSubmit}
        className="product-service-form"
        form={productAndServicesForm}
        name="products-form"
        layout={"vertical"}
      >
        <div className="product-form-section">
          <h1>Prescription Products and Services
          <AppToolTip
                  title={prescriptionProductMessage}
                  iconStyles={{ marginLeft: "10px" }}
                />
          </h1>
    
          <Form.List name="prescriptionProducts" disabled={mode === "view"}>
            {(fields, { add, remove }) => (
              <>
                {fields?.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div key={key} style={{ position: "relative" }}>
                    <Row
                      style={{
                        marginTop: "20px",
                        paddingBottom: "0.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      Product {index + 1}
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          display: mode === "view" ? "none" : "",
                        }}
                        onClick={() => {
                          setDeleteFileOpen(true);
                          setDeleteFileData({
                            index,
                            remove,
                          });
                        }}
                      >
                        <DeleteOutlined style={{ margin: "0 0.5rem" }} />
                        Remove
                      </span>
                      <span style={{ marginLeft: "1rem" }}>
                        <Checkbox
                          onChange={(e) =>
                            handlePrescriptionCheckboxChange(
                              index,
                              e.target.checked
                            )
                          }
                          checked={prescriptionProductsHide[index]}
                        >
                          {prescriptionProductsHide[index] ? "Unhide" : "Hide"}
                        </Checkbox>
                      </span>
                    </Row>
                    {!prescriptionProductsHide[index] && (
                      <>
                        <Row
                          gutter={[16, 16]}
                          align="middle"
                          className="form-row"
                        >
                          <Col lg={12} md={24} sm={24}>
                            <Form.Item
                              {...restField}
                              fieldKey={[fieldKey, "treatmentName"]}
                              name={[name, "treatmentName"]}
                              label="Product/Service Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Product/Service name is required",
                                },
                              ]}
                              style={{
                                textAlign: "left",
                              }}
                            >
                              <Input
                                style={{ width: "100%" }}
                                placeholder="Please Enter Product/Service Name"
                                ref={inputRef}
                                disabled={mode === "view"}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                ))}
          
                <div style={{ textAlign: "right", marginTop: "1rem" }}>
                  <Form.Item>
                    <AppButton
                      type="primary"
                      shape="round"
                      onClick={() => {
                        const addData = {};
                        add(addData);
                      }}
                      disabled={mode === "view"}
                    >
                      Add Product/Service
                    </AppButton>
                  </Form.Item>
                </div>
              </>
            )}
          </Form.List>
          <Divider />
        </div>
        <div className="product-form-section">
          <h1>Order Products and Services       <AppToolTip
                  title={prescriptionProductMessage}
                  iconStyles={{ marginLeft: "10px" }}
                /></h1>
          <Form.List name="productsServices" disabled={mode === "view"}>
            {(fields, { add, remove }) => (
              <>
                <DraggableList
                  fields={fields}
                  add={add}
                  remove={remove}
                  handleOnDragEnd={handleOnDragEnd}
                  handleCheckboxChange={handleCheckboxChange}
                  productsHide={productsHide}
                  mode={mode}
                  productName={productName}
                  setProductName={setProductName}
                  productListSearch={productListSearch}
                  productFormData={productFormData}
                  checkPriceLabel={checkPriceLabel}
                  checkFreeProduct={checkFreeProduct}
                  numberFreeValidator={numberFreeValidator}
                  numberValidator={numberValidator}
                  defaultAppCurrency={defaultAppCurrency}
                  productOrServiceCurrencyOptions={
                    productOrServiceCurrencyOptions
                  }
                  setProductFormData={setProductFormData}
                  inputRef={inputRef}
                  addItem={addItem}
                  onLabProductDeleteClick={onLabProductDeleteClick}
                  handleFreeChange={handleFreeChange}
                />
                <div style={{ textAlign: "right", marginTop: "1rem" }}>
                  <Form.Item>
                    <AppButton
                      type="primary"
                      shape="round"
                      onClick={() => {
                        const addData = {
                          currencyId: defaultAppCurrency,
                          arches: "Upper and Lower",
                          quantityType: "Package",
                        };
                        const tempProductFormData = [...productFormData];
                        tempProductFormData.push(addData);
                        setProductFormData(tempProductFormData);
                        add(addData); // Set default value for currencyId
                      }}
                      disabled={mode === "view"}
                    >
                      Add Product/Service
                    </AppButton>
                  </Form.Item>
                </div>
              </>
            )}
          </Form.List>
        </div>
        <Divider />
        <div className="product-form-section">
          <h1>Refinement Series Settings</h1>
          <div style={{ textAlign: "left", }}>
            <Row>
              <Col lg={8} md={24} sm={24}>
              <Form.Item Label="" name="refinementSeries">
              <Select
                disabled={isDisabled}
                placeholder="choose"
                options={[
                  {
                    label: "Restart Series",
                    value: 1,
                  },
                  {
                    label: "Resume Series",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
              </Col>
            </Row>

          </div>
        </div>
      </Form>
      <Modal
        open={deleteFileOpen}
        title={"Remove Product"}
        onOk={handleFileOk}
        onCancel={handleFileCancel}
      >
        <p>Are you sure you want to remove Product?</p>
      </Modal>

      <Modal
        open={deleteProductNameOpen}
        title={"Remove Product/Service Name"}
        onOk={handleProductNameOk}
        onCancel={handleProductNameCancel}
      >
        <p>Are you sure you want to remove Product/Service Name?</p>
      </Modal>
    </div>
  );
};

export default ProductServicesForm;