import { Menu } from "antd";
import styles from "./menu.module.scss";
import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";

const getItems = () => {
  const items = [
    {
      key: "/lab/settings",
      label: "Profile",
    },
    {
      type: "divider",
    },
    {
      key: "/lab/settings/treatments",
      label: "Treatments",
    },
    {
      type: "divider",
    },
    {
      key: "/lab/settings/products",
      label: "Order Products & Services",
    },
    {
      type: "divider",
    },
    {
      key: "/lab/settings/manageStaff",
      label: "Manage Staff",
    },
    {
      type: "divider",
    },
  ];

  return items;
};

const SettingsMenu = () => {
  const history = useHistory();
  return (
    <div className={styles["menu-container"]}>
      <h1 className={styles["menu-title"]}>Settings</h1>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[history.location.pathname]}
        onClick={({ key }) => history.push(key)}
        defaultSelectedKeys={["1"]}
        inlineCollapsed={false}
        items={getItems()}
      />
    </div>
  );
};
export default SettingsMenu;
