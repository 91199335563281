import React from "react";
import PatientInfo from "./PatientInfo";
import ClinicInfo from "./ClinicInfo";


const InfoDisplay = ({ patientAddress,activeTab, patientInfo, clinicInfo, primaryColor, clinicName, clinicAddress, time }) => {
  return (
    <div className="info-display">
      {activeTab === "patient" ? (
        <PatientInfo patientInfo={patientInfo} primaryColor={primaryColor} patientAddress={patientAddress}/>
      ) : (
        <ClinicInfo clinicInfo={clinicInfo} primaryColor={primaryColor} clinicName={clinicName} clinicAddress={clinicAddress} time={time}/>
      )}
    </div>
  );
};

export default InfoDisplay;
