import React, { useState, useEffect } from "react";
import { Layout, Menu, ConfigProvider } from "antd";
import HeaderComponent from "./headerComponent";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as Resources } from "assets/images/Resources.svg";

import {
  UserOutlined,
  UsergroupDeleteOutlined,
  WalletOutlined,
  SettingOutlined,
  DashboardOutlined,
} from "@ant-design/icons";

import {
  companyName,
  defaultActiveColor,
  primaryColor,
  sibarHoverColor,
  sidebarColor,
} from "colors-config";
import { userRoleSelector } from "services/redux/selectors/lab.selectors";
import { isAdmin } from "utils/auth";
import { navigationObject } from "utils/nabar";
import "../../assets/styles/components/layoutComponent.scss";
const { Content, Sider } = Layout;

const LayoutComponent = ({ children, user, lab }) => {
  // const companyType = process.env.REACT_APP_COMPANY_TYPE;
  const userRole = useSelector(userRoleSelector);

  const [selectedKey, setSelectedKey] = useState("");
  const [toggleIntercomChat, setToggleIntercomChat] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    console.log(collapsed);
    setCollapsed(!collapsed);
  };
  const history = useHistory();
  // console.log(user, "user == ");

  useEffect(() => {
    if (lab.labId) {
      enableIntercom(lab);
    }
  }, [lab]);

  const handleHash = (inputText) => {
    return new Promise((resolve, reject) => {
      const secretKey = "K8oKr6-cOgxwzx1U1cpG1-51ri5EYvLguisIVU4t";
      const encoder = new TextEncoder();
      const data = encoder.encode(inputText);
      const secretKeyBuffer = encoder.encode(secretKey);

      window.crypto.subtle
        .importKey("raw", secretKeyBuffer, { name: "HMAC", hash: "SHA-256" }, true, ["sign"])
        .then((key) => {
          return window.crypto.subtle.sign({ name: "HMAC" }, key, data);
        })
        .then((signature) => {
          const hashedValue = Array.from(new Uint8Array(signature))
            .map((byte) => ("0" + byte.toString(16)).slice(-2))
            .join("");
          resolve(hashedValue);
        })
        .catch((err) => {
          console.error("Error hashing:", err);
          reject(err);
        });
    });
  };

  const enableIntercom = async (lab) => {
    const userIdentifier = lab.labId;
    const hash = await handleHash(userIdentifier);
    const intercomData = {
      api_base: "https://api-iam.intercom.io",
      app_id: "j7p07692",
      name: lab.labName,
      email: lab.labEmail,
      PortalType: "lab",
      CompanyType: companyName,
      // created_at: lab.onboardedDate,
      user_id: userIdentifier,
      user_hash: hash,
      custom_launcher_selector: "#my_custom_link",
      Stage: process.env.REACT_APP_STAGE,
    };
    // console.log("intercom",process.env.REACT_APP_INTERCOM);
    if (process.env.REACT_APP_INTERCOM === "true") {
      window.Intercom("boot", intercomData);
    }
  };

  const toggleChatModal = () => {
    setToggleIntercomChat(!toggleIntercomChat);
    if (toggleIntercomChat) {
      window.Intercom("show");
    } else {
      window.Intercom("hide");
    }
  };

  const onSelect = (val) => {
    if (val.key == "6") {
      window.open("https://help.smilegeniusapp.com/en/collections/8759304-lab", "_blank");
    }
    history.push(navigationObject[val.key]);
    setSelectedKey(val.key);
  };

  useEffect(() => {
    pickMenuKey(history.location.pathname);
  }, [history.location]);

  const pickMenuKey = (pathname) => {
    if (pathname.includes("/lab/patient/")) {
      setSelectedKey("1");
      return;
    }
    if (pathname.includes("/onboarding")) {
      setSelectedKey("5");
      return;
    }
    const findMenuKey = Object.entries(navigationObject).find((obj) => obj[1] == pathname);
    if (findMenuKey) {
      setSelectedKey(findMenuKey[0]);
    }
  };

  return (
    <Layout>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 4,
          },
          components: {
            Button: {
              itemBg: primaryColor,
            },
          },
        }}
      >
        <HeaderComponent />
      </ConfigProvider>
      <Layout>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                itemBg: sidebarColor,
                itemHoverBg: sibarHoverColor,
                itemHoverColor: primaryColor,
                itemSelectedBg: defaultActiveColor,
                itemColor: "white",
              },
            },
          }}
        >
          <Sider
            width={100}
            className="sidebar-container"
            style={{
              minHeight: "100vh",
              position: "fixed",
              marginTop: "3.9rem",
              backgroundColor: sidebarColor, // dynamic theme color
            }}
          >
            <Menu
              className="custom-menu"
              mode="vertical"
              selectedKeys={[selectedKey]}
              onSelect={onSelect}
              style={{
                height: "100%",
                borderRight: 0,
                // color: "white",
              }}
            >
              <Menu.Item key="7" className="sidebar-menu-items" disabled={!lab.labName}>
                <div>
                  <DashboardOutlined style={{ paddingTop: "15px" }} className="sidebar-icon" />
                  {/* <DashboardIcon style={{ stroke: primaryColor }} /> */}
                </div>

                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Dashboard
                </p>
              </Menu.Item>

              <Menu.Item
                key="1"
                onClick={() => history.push("/lab/patients")}
                className="sidebar-menu-items"
                disabled={!lab.labName}
              >
                <UsergroupDeleteOutlined style={{ paddingTop: "15px" }} className="sidebar-icon" />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Patients
                </p>
              </Menu.Item>
              <Menu.Item key="2" className="sidebar-menu-items" data-testid="partner-sidebar-button" disabled={!lab.labName}>
                <UserOutlined style={{ paddingTop: "15px" }} className="sidebar-icon" />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Partners
                </p>
              </Menu.Item>
              <Menu.Item key="3" className="sidebar-menu-items" disabled={!lab.labName}>
                <WalletOutlined style={{ paddingTop: "15px" }} className="sidebar-icon" />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Billing
                </p>
              </Menu.Item>
              <Menu.Item key="4" className="sidebar-menu-items" disabled={!lab.labName}>
                <div style={{ paddingTop: "15px" }} className="sidebar-icon">
                  <Resources style={{ stroke: primaryColor }} />
                </div>

                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Resources
                </p>
              </Menu.Item>

              {isAdmin(userRole) && (
                <Menu.Item
                  key="5"
                  className="sidebar-menu-items"
                  // disabled={!lab.labName}
                >
                  <SettingOutlined style={{ paddingTop: "15px" }} className="sidebar-icon" />
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "0px",
                    }}
                  >
                    Settings
                  </p>
                </Menu.Item>
              )}
              {/* <Menu.Item
                key="6"
                className="sidebar-menu-items"
              >
                <QuestionCircleOutlined
                  style={{ paddingTop: "15px" }}
                  className="sidebar-icon"
                />
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "0px",
                  }}
                >
                  FAQs
                </p>
              </Menu.Item> */}
            </Menu>
          </Sider>
        </ConfigProvider>

        <Layout
          style={{
            padding: "0 24px 24px",
            backgroundColor: "rgba(182, 225, 239, 0.13)",
          }}
        >
          <Content
            // className="site-layout-background"
            style={{
              padding: 24,
              // margin: 0,
              minHeight: "100vh",
              // width: "100%",

              margin: "5rem 0 0 5rem ",
              backgroundColor: "#E9F0F4",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      {/* <Button
        // style={{ float: "right" }}
        className="chat-intercom-Btn"
        type="primary"
        shape="circle"
        icon={ <IntercomIcon />}
        onClick={toggleChatModal}
        size="large"
      /> */}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps)(LayoutComponent);
