import React, { useState, useEffect } from "react";
import { Table, Tag, Space, Input, Row, Col, Tooltip, Button } from "antd";

import UpdatePaymentStatusModal from "../shared/modals/updatePaymentStatusModal";
import FilterDataRangePicker from "../shared/filterDataRangePicker";
import axios from "axios";

import moment from "moment";
import "../../assets/styles/components/invoiceList.scss";

import {
  DownOutlined,
  FileOutlined,
  SearchOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";
import LabInvoiceModal from "./LabInvoiceModal/LabInvoiceModal";
import { columnTitleElement } from "utils/commonMethod";
import { companyType } from "colors-config";
import { calculateProductsTotal, getProductServiceName, getProductServiceNames, getProductServiceTotalQuantity } from "utils/treatment";

const InvoiceListComponent = ({
  paymentStatusList,
  paginationLimit,
  invoiceList,
  paginationPage,
  confirmUpdatePaymentStatus,
  onSearch,
  totalRecords,
  handleDateFilter,
  onPagination,
  fromDate,
  endDate
}) => {
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paginationObj, setPaginationObj] = useState({});
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);

  useEffect(() => {
    if (invoiceList?.length >= 0) {
      setTableData(createTableData());
    }
  }, [invoiceList]);

  useEffect(() => {
    const obj = {
      current: paginationPage,
      pageSize: paginationLimit,
      showSizeChanger: true,
      // defaultCurrent: 1,
      // defaultPageSize: 5,
      pageSizeOptions: [5, 10, 20, 50],
      // size: "small",
      total: totalRecords,
    };

    setPaginationObj(obj);
  }, [paginationPage, paginationLimit, totalRecords, onSearch]);

  const createTableData = () => {
    return invoiceList.map((dataObj, index) => {
      return {
        key: index,
        shippingFiles: dataObj?.shippingFiles || [],
        invoiceNo: dataObj.patient_order_id
          ? dataObj.patient_order_id
          : "Not Available",
        invoiceDate: dataObj.product_order_date
          ? moment(dataObj.product_order_date).format("DD/MM/YYYY")
          : "Not Available",
        patientId: dataObj.patient_id,
        clinicName: dataObj.clinic_name ? dataObj.clinic_name : "Not Available",
        productServiceName:  getProductServiceName(dataObj),
        productServiceNames:  getProductServiceNames(dataObj),
        paymentStatus: dataObj.payment_status_name
          ? dataObj.payment_status_name
          : "Not Available",
        amount: dataObj.product_price ? dataObj.product_price + " " + dataObj.currency_name : "Not Available",
        quantity: getProductServiceTotalQuantity(dataObj),
        labProductPrice: dataObj.free ? `${dataObj.currency_name} 0 (Free)` : dataObj.labProductPrice ? dataObj.labProductPrice : "Not Available",
        labProductTotalPrice:  calculateProductsTotal(dataObj?.labProductList),
        clinicianDetails: {
          clinicName: dataObj.clinic_name ? dataObj.clinic_name : "Not Available",
          clinicLabel: dataObj.clinicLabel ? dataObj.clinicLabel : "",
        },
      };
    });
  };

  const showPaymentModal = (record) => {
    setSelectedOrder(record);
    setIsModalVisible(true);
  };
  const onDownloadAllClick = (shippingFiles) => {
    shippingFiles.forEach((file) => {
      downloadHandler(file);
    });
  };

  const downloadHandler = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clinicianDetails = (details) => {
    return (
      <>
      <Row>
          <p style={{ fontWeight:'600', marginBottom:'0' }}>{details.clinicName}</p>
      </Row>
      <Row>
        <p style={{ fontWeight:'300', marginBottom:'0'}}> {details.clinicLabel}</p>
      </Row>
      </>
    );
  }

  const columns = [
    {
      title: columnTitleElement("INVOICE NO"),
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width: 170,
      render: (invoiceNo, DownloadOutlined) => {
        return (
          <div style={{ color: "#223032", display: "flex" }}>
            <FileOutlined style={{ marginRight: "5px", color: "#77888B" }} />
            {invoiceNo}
          </div>
        );
      },
    },
    {
      title: columnTitleElement("INVOICE DATE"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      filtered: (fromDate && endDate),
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDataRangePicker
          clearFilters={clearFilters}
          confirm={confirm}
          handleDateFilter={handleDateFilter}
          fromDate={fromDate}
          endingDate={endDate}
        />
      ),
    },
    {
      title: "CLINICIAN",
      dataIndex: "clinicianDetails",
      key: "clinicianDetails",
      width: 170,
      render: (details) => clinicianDetails(details),
    },
    {
      title: columnTitleElement("PATIENT ID"),
      dataIndex: "patientId",
      key: "patientId",
    },
    {
      title: columnTitleElement(
        companyType === 5 || companyType === 1
          ? "LAB PRODUCT / SERVICE NAME"
          : "PRODUCT/SERVICE"
      ),
      dataIndex: "productServiceName",
      key: "productServiceName",
      render: (productName, record) => {
        return (
          <Tooltip title={record.productServiceNames}>
          {productName}
        </Tooltip>
        );
      },
    },
    {
      title: columnTitleElement("PAYMENT STATUS"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: (paymentStatus, record) => {
        return (
          <>
            <Tag
              style={{
                borderRadius: "10px",
                paddingTop: "3px",
                paddingBottom: "3px",
                width: "130px",
                position: "relative",
              }}
              key={paymentStatus}
              onClick={() => showPaymentModal(record)}
            >
              {paymentStatus}
              <DownOutlined
                style={{
                  float: "right",
                  marginTop: "0.25rem",
                  position: "relative",
                  pointerEvents: "none",
                }}
              />
            </Tag>
          </>
        );
      },
    },
    {
      title: columnTitleElement("ORDER INVOICE"),
      dataIndex: "orderInvoice",
      key: "orderInvoice",
      render: (invoice, record) => {
        return (
          <>
              <Row style={{ marginTop: "1rem" }}>
                <Col>
                  {record?.shippingFiles?.length ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            setOrderInfo(record);
                            setShowInvoiceModal(true);
                          }}
                          className="invoice-viewBtn"
                          icon={
                            <EyeOutlined
                              style={{ fontSize: "17px", cursor: "pointer" }}
                            />
                          }
                        ></Button>
                      </Tooltip>
                      <Tooltip title="Download">
                     <Button
                       onClick={() => onDownloadAllClick(record?.shippingFiles)}
                       className="invoice-viewBtn"
                       icon={
                         <DownloadOutlined
                           style={{ fontSize: "17px", cursor: "pointer" }}
                         />
                       }
                     ></Button>
                   </Tooltip>
                    </div>
                  ) : (
                    "--"
                  )}
                </Col>
              </Row>
      
          </>
        );
      },
    },
  ];
  if (
    companyType === 5 ||
    companyType === 1
  ) {
    columns.splice(5, 0, {
      title: columnTitleElement("QUANTITY"),
      dataIndex: "quantity",
      key: "quantity",
      align:"end",
      width:120
    });
    columns.splice(7, 0, {
      title: columnTitleElement("LAB TOTAL PRICE"),
      dataIndex: "labProductTotalPrice",
      key: "labProductTotalPrice",
      // align:"end"
    });
  }
  const _getLabInvoicePdf = (patientOrderId) => {
    setLoadingPdf(true);

    axios
      .get(
        `${process.env.REACT_APP_CLINIC_BASE_URL}/clinic/labInvoice/pdf?patientOrderId=${patientOrderId}`,
        {
          headers: {
            tenantId: process.env.REACT_APP_SMILE_TENENT_ID,
          },
        }
      )
      .then(async (res) => {
        setLoadingPdf(false);
        window.open(res.data.body.url);
      })
      .catch(async (err) => {
        setLoadingPdf(false);
        alert(err);
        console.log("data---", err);
      });
  };
  const downPdf = (record) => {
    return (
      <Row>
        <Col
          style={{
            //marginTop: "0.05rem",
            //marginBottom: "1%",
            cursor: "pointer",
            marginLeft: "3rem",
          }}
          onClick={() => _getLabInvoicePdf(record.invoiceNo)}
        >
          <DownloadOutlined style={{ fontSize: "17px" }} />
        </Col>
      </Row>
    );
  };
  const confirmUpdateStatus = ({ paymentStatus }) => {
    const [{ payment_status_id: paymentStatusId }] = paymentStatusList.filter(
      (paymentStatusDetails) =>
        paymentStatusDetails.payment_status_name === paymentStatus
    );
    const orderId = selectedOrder.invoiceNo;
    confirmUpdatePaymentStatus(orderId, paymentStatusId);
  };
  return (
    <div>
      <Space direction="vertical">
        <div></div>
      </Space>

      {isModalVisible && (
        <UpdatePaymentStatusModal
          title="Update Payment status"
          paymentStatusList={paymentStatusList}
          selectedOrder={selectedOrder}
          showModal={isModalVisible}
          onConfirmation={confirmUpdateStatus}
          setShow={setIsModalVisible}
        ></UpdatePaymentStatusModal>
      )}
      <Row justify="space-between" style={{ marginLeft: "1rem" }}></Row>
      <Table
        className="invoice-table"
        style={{
          width: "100%",
          overflow: "hidden",
        }}
        columns={columns}
        dataSource={tableData}
        pagination={paginationObj}
        onChange={onPagination}
        loading={loadingPdf}
        scroll={{ x: "max-content" }}
        // onRow={(record, recordIndex) => ({

        //   onClick: (event) => {
        //     if(event.target.tagName === "svg"){
        //       _getLabInvoicePdf(record.invoiceNo)
        //     }

        //     //setLoadingPdf(true);
        //     //event.isPropagationStopped();
        //     if (event.target.innerText === "Awaiting Payment" || event.target.innerText === "Paid" || event.target.innerText === "Cancelled") {

        //     } else {
        //       event.preventDefault()
        //       console.log("onevent", event);
        //     }
        //   },
        // })}
      />
      {showInvoiceModal && (
        <LabInvoiceModal
          showInvoiceModal={showInvoiceModal}
          setShowInvoiceModal={setShowInvoiceModal}
          orderInfo={orderInfo}
          setOrderInfo={setOrderInfo}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(InvoiceListComponent);
