import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import styles from "./button.module.scss";
import { noop } from "utils/commonMethod";

const AppButton = ({
  type,
  size,
  shape = "round",
  loading = false,
  disabled = false,
  children,
  icon,
  iconPosition,
  className,
  onClick,
  block,
  htmlType,
  style,
  ...props
}) => {
  return (
    <Button
      type={type}
      size={size}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      shape={shape}
      iconPosition={iconPosition}
      className={`${styles["appBtn"]} ${className ?className: ''}`}
      {...props}
      block={block}
      icon={icon}
      htmlType={htmlType}
      style={style}
    >
      {children}
    </Button>
  );
};

export default AppButton;

AppButton.propTypes = {
  shape: PropTypes.oneOf(["default", "circle", "round"]),
  size: PropTypes.oneOf(["large", "middle", "small"]),
  type: PropTypes.oneOf(["primary", "dashed", "link", "text", "default"]),
  iconPosition: PropTypes.objectOf(["start", "end"]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
};

AppButton.defaultProps = {
  size: "large",
  shape: "round",
  type: "default",
  onClick: noop,
  htmlType: "button",
  iconPosition: "start",
  disabled: false,
  className: ""
};
