import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Divider,
  Button,
  Radio,
  InputNumber,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import DragIcon from "components/shared/Icon/DragIcon";
const { Option } = Select;

const DraggableList = ({
  fields,
  add,
  remove,
  handleOnDragEnd,
  handleCheckboxChange,
  productsHide,
  mode,
  productName,
  setProductName,
  productListSearch,
  productFormData,
  checkPriceLabel,
  checkFreeProduct,
  numberFreeValidator,
  numberValidator,
  defaultAppCurrency,
  productOrServiceCurrencyOptions,
  setProductFormData,
  inputRef,
  addItem,
  onLabProductDeleteClick,
  handleFreeChange,
}) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="productsServices">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <Draggable
                key={key}
                draggableId={String(key)}
                index={index}
                isDragDisabled={mode === "view"}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                  >
                    <Row
                      style={{
                        marginTop: "20px",
                        paddingBottom: "0.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      <DragIcon style={{ marginRight: "10px" }} />
                      Product {index + 1}
                      <span
                        style={{
                          color: "red",
                          cursor: "pointer",
                          display: mode === "view" ? "none" : "",
                        }}
                        onClick={() => remove(name)}
                      >
                        <DeleteOutlined style={{ margin: "0 0.5rem" }} />
                        Remove
                      </span>
                      <span style={{ marginLeft: "1rem" }}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxChange(index, e.target.checked)
                          }
                          checked={productsHide[index]}
                        >
                          {productsHide[index] ? "Unhide" : "Hide"}
                        </Checkbox>
                      </span>
                    </Row>

                    {!productsHide[index] && (
                      <>
                        <Row
                          gutter={[16, 16]}
                          align="middle"
                          className="form-row"
                        >
                          <Col lg={12} md={24} sm={24}>
                            <Form.Item
                              {...restField}
                              fieldKey={[fieldKey, "productName"]}
                              name={[name, "productName"]}
                              label="Product/Service Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Product name is required",
                                },
                              ]}
                              style={{
                                textAlign: "left",
                              }}
                            >
                              <Select
                                disabled={mode === "view"}
                                placeholder="Select Product"
                                className="lab-product-selectbox"
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {menu}
                                      <Divider
                                        style={{
                                          margin: "8px 0",
                                        }}
                                      />
                                      <div
                                        style={{
                                          padding: "0 8px 4px",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "7px",
                                        }}
                                      >
                                        <Input
                                          style={{
                                            width: "100%",
                                          }}
                                          placeholder="Please Enter Product/Service Name"
                                          ref={inputRef}
                                          value={productName}
                                          // onChange={onNameChange}
                                          onChange={(e) => {
                                            return (
                                              e.target.value.length <= 50 &&
                                              setProductName(e.target.value)
                                            );
                                          }}
                                          // onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <Button
                                          type="text"
                                          icon={<PlusOutlined />}
                                          onClick={addItem}
                                          style={{
                                            color: primaryColor,
                                          }}
                                        >
                                          Add item
                                        </Button>
                                      </div>
                                    </>
                                  );
                                }}
                                options={productListSearch?.map((item, i) => {
                                  return {
                                    ...item,
                                    key: i,
                                    value: item.productName,
                                    label: item.productName,
                                  };
                                })}
                                optionRender={(option) => {
                                  const findProductArches =
                                    productFormData.find(
                                      (obj) =>
                                        obj.productName ==
                                        option.data.productName
                                    );

                                  return (
                                    <>
                                      <span className="treatment-arches-dp">
                                        <span className="dp-label">
                                          {option.data.productName}
                                        </span>
                                        <span>
                                          <span className="db-arches">
                                            {" "}
                                            {findProductArches
                                              ? findProductArches.arches
                                              : ""}{" "}
                                          </span>
                                          <DeleteOutlined
                                            className="delete-icon"
                                            onClick={(e) =>
                                              onLabProductDeleteClick(
                                                e,
                                                option.data,
                                                option.data.key
                                              )
                                            }
                                          />
                                        </span>
                                      </span>
                                    </>
                                  );
                                }}
                              ></Select>
                            </Form.Item>
                          </Col>

                          <Col lg={12} md={24} sm={24}>
                            <Form.Item
                              {...restField}
                              fieldKey={[fieldKey, "quantityType"]}
                              name={[name, "quantityType"]}
                              label=""
                              style={{ textAlign: "left" }}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please select product quantity type",
                                },
                              ]}
                            >
                              <Radio.Group
                                disabled={mode === "view"}
                                style={{ marginTop: "22px" }}
                              >
                                <Radio value="Package">Package</Radio>
                                <Radio value="Per aligner">Per Unit</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]} className="form-row">
                          <Col lg={12} md={24} sm={24}>
                            <Form.Item
                              {...restField}
                              fieldKey={[fieldKey, "arches"]}
                              name={[name, "arches"]}
                              label="Arches Treated"
                              style={{
                                textAlign: "left",
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select an option",
                                },
                              ]}
                            >
                              <Select size="large" disabled={mode === "view"}>
                                <Option value="Upper and Lower">
                                  Upper and Lower
                                </Option>
                                <Option value="Upper">Upper</Option>
                                <Option value="Lower">Lower</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col lg={12} md={24} sm={24} style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "-6px",
                                left:
                                  checkPriceLabel(index) === "Price per Unit"
                                    ? "110px"
                                    : "95px",
                              }}
                            >
                              <Form.Item
                                {...restField}
                                fieldKey={[fieldKey, "free"]}
                                name={[name, "free"]}
                                label=""
                                valuePropName="checked"
                              >
                                <Checkbox
                                  disabled={mode === "view"}
                                  onChange={(e) =>
                                    handleFreeChange(e.target.checked, index)
                                  }
                                >
                                  Free
                                </Checkbox>
                              </Form.Item>
                            </div>
                            <Form.Item
                              {...restField}
                              fieldKey={[fieldKey, "price"]}
                              name={[name, "price"]}
                              label={checkPriceLabel(index)}
                              rules={[
                                {
                                  required: checkFreeProduct(index)
                                    ? false
                                    : true,
                                  message: "Price is required!",
                                },
                                {
                                  validator: checkFreeProduct(index)
                                    ? numberFreeValidator
                                    : numberValidator,
                                },
                              ]}
                            >
                              <InputNumber
                                size="large"
                                className="product-lab-price-input"
                                disabled={
                                  mode === "view" || checkFreeProduct(index)
                                }
                                placeholder="Price"
                                max={50000}
                                min={0}
                                step={0.01}
                                style={{ width: "100%" }}
                                addonAfter={
                                  <Form.Item
                                    {...restField}
                                    fieldKey={[fieldKey, "currencyId"]}
                                    name={[name, "currencyId"]}
                                    noStyle
                                  >
                                    <Select
                                      size="large"
                                      disabled={
                                        mode === "view" ||
                                        checkFreeProduct(index)
                                      }
                                      style={{ width: 80 }}
                                    >
                                      {productOrServiceCurrencyOptions.map(
                                        (currency, i) => (
                                          <Option
                                            key={i}
                                            value={currency.currency_id}
                                          >
                                            {currency.currency_name}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Divider />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;