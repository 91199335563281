import { Popconfirm, Tooltip } from "antd";
import { UploadedFileIcon } from "components/shared/Icon/ScanFileIcon";
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styles from "./uploadFile.module.scss";
import { primaryColor } from "colors-config";

const UploadedFile = ({ file, onDelete, onDownload, onView }) => {
  return (
    <div className={styles["scan-file-wrapper"]}>
      <Tooltip title={file.name}>
        <div className={styles["scan-file-display"]}>
          <UploadedFileIcon
            style={{ color: primaryColor }}
            className={styles["scan-file-icon"]}
          />

          <div className={styles.fileName}>{file?.name}</div>

          <div className={styles.fileMask}>
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this scan?"
              okText="Yes"
              cancelText="No"
              onConfirm={onDelete}
            >
              <DeleteOutlined className={styles.anticon} />
            </Popconfirm>
            {onDownload && (
              <DownloadOutlined
                onClick={onDownload}
                className={styles.anticon}
              />
            )}
            {onView && (
              <EyeOutlined onClick={onView} className={styles.anticon} />
            )}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default UploadedFile;
