import React, { useState, useEffect } from "react";
import ProductServicesContainer from "./ProductServicesContainer/ProductServicesContainer";
import { message, Button, Typography, Row, Spin, Col, Form } from "antd";
import {
  getUniquePrescriptionProducts,
  productServicesFormInitialValues,
  productsServiceMapper,
} from "utils/Lab";
import { loadLab } from "services/redux/actions/labActions";
import FormFooter from "../../shared/FormFooter/FormFooter";
import { connect } from "react-redux";
import { saveProducts, getCurrencies, getProducts } from "api/lab";
import { labApiTasks } from "models/lab";
import styles from "./products.module.scss";
import { labFormResources } from "api/lab";
import { defaultAppCurrency } from "colors-config";
import AppButton from "components/shared/AppButton/AppButton";

const Products = ({ lab, loadLab }) => {
  const [loading, setLoading] = useState(false);
  const [isButtonSpinning, setIsButtonSpinning] = useState(false);
  const [labDetails, setLabDetails] = useState({});
  const [productsAndServices, setProductAndServices] = useState([]);
  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] =
    useState([]);

  const [fileListForm, setFileListForm] = useState([]);
  const [mode, setMode] = useState("view");
  const [productList, setProductList] = useState([]);
  const [prescriptionProductList, setPrescriptionProductList] = useState([]);

  const [productAndServicesForm] = Form.useForm();
  const [productFormData, setProductFormData] = useState([]);

  useEffect(() => {
    const fetchLabProfile = async () => {
      try {
        setLoading(true);

        const currencyRes = await getCurrencies();
        setProductOrServiceCurrencyOptions(currencyRes?.data?.data);

        productServicesValueSetter(null, currencyRes?.data?.data, null);
        const res = await labApiTasks.getAll("lab/getLabDetailsByLabId", {
          labId: lab.labId,
        });

        const labDetailsFromDB = res.body.labServices[0];
        const labDetailsObject = {
          labName: labDetailsFromDB.lab_name,
          labAdminName: labDetailsFromDB.lab_admin_name,
          labServiceID: labDetailsFromDB.lab_service_id,
          businessRegistrationNo: labDetailsFromDB.lab_registration_number,
          email: labDetailsFromDB.lab_email,
          county: labDetailsFromDB.lab_county,
          country: labDetailsFromDB.lab_country,
          city: labDetailsFromDB.lab_city,
          address: labDetailsFromDB.lab_address,
          postalCode: labDetailsFromDB.lab_postal_code,
          phone: labDetailsFromDB.lab_phone_number,
          prefix: labDetailsFromDB.lab_country_code_name,
        };
        setLabDetails(labDetailsObject);

        await getLabProducts(
          currencyRes?.data?.data,
          labDetailsFromDB.refinementSeries
        );

        const resourceRes = await labFormResources(lab.labId);
        if (resourceRes?.data) {
          setFileListForm(resourceRes.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (lab.labId) {
      fetchLabProfile();
    }
  }, [lab.labId]);

  const productServicesValueSetter = (ps, def, pres, refinementSeries = 1) => {
    let formData;
    if (ps?.length > 0) {
      formData = productsServiceMapper(ps);
    } else {
      formData = productServicesFormInitialValues(def[0]?.currency_id);
    }

    productAndServicesForm.setFieldsValue({
      ...formData,
      prescriptionProducts: pres,
      refinementSeries,
    });

    setProductFormData(formData.productsServices);
  };

  const onProductsFormSubmit = async values => {
    const finalPrescriptionProducts = values.prescriptionProducts;
    const result = getUniquePrescriptionProducts(finalPrescriptionProducts);
    if (!result) {
      return message.error("Duplicate prescription products and services");
    }
    setIsButtonSpinning(true);
    setLoading(true);
    const finalValues = values.productsServices.map((product, index) => {
      const matchedOption = productOrServiceCurrencyOptions.find(
        p => p.currency_id == product.currencyId
      );
      return {
        ...product,
        product_order: index + 1,
        currency: matchedOption
          ? matchedOption.currency_name
          : productOrServiceCurrencyOptions[0]?.currency_name,
      };
    });
    if (!finalValues.every(i => i.productName && (i.price || i.free))) {
      message.error("All fields are required");
      setIsButtonSpinning(false);
      return false;
    }

    if (
      productDuplicateValidate(
        finalValues.map(obj => {
          return {
            productName: obj.productName,
            arches: obj.arches,
            quantityType: obj.quantityType,
          };
        })
      )
    ) {
      message.error("Remove duplicate products");
      setIsButtonSpinning(false);
      setLoading(false);
      return false;
    }

    try {
      const productData = await saveProducts(
        {
          productsServices: finalValues,
          productNames: productList,
          prescriptionProducts: finalPrescriptionProducts,
          refinementSeries: values.refinementSeries,
        },
        lab.labId
      );
      // console.log('productData',productData)
      setProductAndServices(productData.data.labProducts);
      setPrescriptionProductList(productData?.data?.prescriptionPrducts);
      productServicesValueSetter(
        productData.data.labProducts,
        productOrServiceCurrencyOptions[0]?.currency_id,
        productData?.data?.prescriptionPrducts,
        values.refinementSeries
      );
      message.success("Lab products updated");
    } catch (error) {
      console.log(error);
      message.error("One or more Products are in used");
      await getLabProducts();
    } finally {
      setLoading(false);
      setIsButtonSpinning(false);
      setMode("view");
    }
  };

  const getLabProducts = async (tempProductServices, refinementSeries) => {
    const productsRes = await getProducts(lab.labId);
    setProductAndServices(productsRes?.data?.labProducts);
    setPrescriptionProductList(productsRes?.data?.prescriptionPrducts);
    productServicesValueSetter(
      productsRes?.data?.labProducts,
      tempProductServices
        ? tempProductServices
        : productOrServiceCurrencyOptions[0]?.currency_id,
      productsRes?.data?.prescriptionPrducts,
      refinementSeries
    );
  };

  const productDuplicateValidate = list => {
    const map = new Map();
    const duplicates = [];

    list.forEach(obj => {
      const key = JSON.stringify(obj);
      if (map.has(key)) {
        duplicates.push(obj);
      } else {
        map.set(key, true);
      }
    });
    return duplicates.length ? true : false;
  };

  const onCancel = async () => {
    setMode("view");
    await getLabProducts();
  };

  const onOk = () => {
    productAndServicesForm.submit();
  };

  return (
    <Spin spinning={loading}>
      <div className={styles["products-view-container"]}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className={styles["heading"]}>Lab Products & Services</h2>
          </Col>
          <Col>
            {mode === "view" && (
              <AppButton
                type="primary"
                shape="round"
                style={{ padding: "0 40px", height: "35px" }}
                onClick={() => setMode("edit")}
              >
                Edit
              </AppButton>
            )}
          </Col>
        </Row>

        <ProductServicesContainer
          mode={mode}
          lab={lab}
          productAndServicesForm={productAndServicesForm}
          setMode={setMode}
          onProductsFormSubmit={onProductsFormSubmit}
          productsAndServices={productsAndServices}
          productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
          defaultCurrency={defaultAppCurrency}
          productList={productList}
          setProductList={setProductList}
          setProductFormData={setProductFormData}
          productFormData={productFormData}
          prescriptionProductList={prescriptionProductList}
          setPrescriptionProductList={setPrescriptionProductList}
        />
      </div>
      {mode !== "view" && (
        <FormFooter
          onCancelClick={onCancel}
          onOkClick={onOk}
          loading={isButtonSpinning}
        />
      )}
    </Spin>
  );
};

const mapStateToProps = state => ({
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps, { loadLab })(Products);
