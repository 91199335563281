import {useState} from "react"
import { Row, Col } from "antd";
import PhasingOrders from "./Graphs/PhasingOrders";
import TodayPhasing from "./Graphs/TodayPhasing";
const FutureAnalytics = ({ lab }) => {
  const [currentPhasingMax, setCurrentPhasingMax] = useState(0) 
  const [futurePhasingMax, setFuturePhasingMax] = useState(0) 
  return (
    <Row gutter={[20, 20]}>
      <Col span={24} >
        <TodayPhasing labId={lab.labId} />
      </Col>
      <Col  lg={12} md={24} sm={24}>
        <PhasingOrders
          cardTitle="This Week's Phasing Order"
          labId={lab.labId}
          setPhasingMax={setCurrentPhasingMax}
          maxPhasing={currentPhasingMax > futurePhasingMax ? currentPhasingMax : futurePhasingMax}
        />
      </Col>
      <Col lg={12} md={24} sm={24}>
        <PhasingOrders
          cardTitle="Next Week's Phasing Order"
          labId={lab.labId}
          isFuture
          maxPhasing={currentPhasingMax > futurePhasingMax ? currentPhasingMax : futurePhasingMax}
          setPhasingMax={setFuturePhasingMax}
        />
      </Col>
    </Row>
  );
};

export default FutureAnalytics;
