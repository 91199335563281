import React, { useState, useEffect } from "react";
import { message, Button, Typography, Row, Spin, Col, Form } from "antd";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import "../../assets/styles/routes/onboarding.scss";
import errorModal from "../../components/shared/modals/errorModal";
import { saveProducts, getCurrencies, addLab } from "api/lab";
import Settings from "components/private/Settings/Settings";
import { updateLabData, loadLab } from "services/redux/actions/labActions";
import {
  getLabSelector,
  getIsFirstLogin,
} from "services/redux/selectors/commonSelectors";
import {
  getUniquePrescriptionProducts,
  productServicesFormInitialValues,
  // productsServiceMapper,
} from "utils/Lab";

const { Title } = Typography;
const companyType = process.env.REACT_APP_COMPANY_TYPE;

function Onboarding() {
  const isFirstLogin = useSelector(getIsFirstLogin);
  const lab = useSelector(getLabSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] =
    useState([]);

  const [profileFill, setProfileFill] = useState(false);
  const [isButtonSpinning, setIsButtonSpinning] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [productList, setProductList] = useState([]);
  const history = useHistory();
  const [profileForm] = Form.useForm();
  const [productAndServicesForm] = Form.useForm();
  const [prescriptionProductList, setPrescriptionProductList] = useState([]);

  const [labDetails, setLabDetails] = useState({
    labName: "",
    labAdminName: "",
    labServiceID: "",
    labRegistrationNumber: "",
    labID: "",
    labCountry: "",
    labCity: "",
    labCounty: "",
    labAddress: "",
    labZipcode: "",
    labPhoneNumber: "",
    labCountryCode: "",
  });
  const [productFormData, setProductFormData] = useState([]);

  // console.log("productFormData", productFormData);
  const productsDisabled =
    companyType === "5" ? false : true;
  useEffect(() => {
    (async () => {
      try {
        const res = await getCurrencies();
        setProductOrServiceCurrencyOptions(res?.data?.data);

        const initValues = productServicesFormInitialValues();

        setProductFormData(initValues.productsServices);

        productAndServicesForm.setFieldsValue(initValues);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // useEffect(() => {
  //   console.log("here isFirstLogin ");
  //   if (!isFirstLogin&&typeof isFirstLogin==='boolean') {
  //     history.push("/lab/profile");
  //   }
  // }, [isFirstLogin]);

  useEffect(() => {
    if(companyType==5){
      if (lab?.labName && activeTab === "1") {
        setActiveTab("2");
      }
    }
    
  }, [lab, activeTab]);

  const onProductsFormSubmit = async (values) => {
    const finalPrescriptionProducts = values.prescriptionProducts;
    const result = getUniquePrescriptionProducts(finalPrescriptionProducts)
    if (!result) {
      return message.error("Duplicate prescription products and services")
    } 
    const finalValues = values.productsServices.map((product,index) => {
      const matchedOption = productOrServiceCurrencyOptions.find(
        (p) => p?.currency_id === product?.currencyId
      );
      return {
        ...product,
        product_order: index + 1,
        currency: matchedOption
          ? matchedOption.currency_name
          : productOrServiceCurrencyOptions[0]?.currency_name,
        currencyId: matchedOption.currency_id,
      };
    });

    if (!finalValues.every((i) => i.productName &&  (i.price || i.free))) {
      message.error("All fields are required");
      return false;
    }
    if (
      productDuplicateValidate(
        finalValues.map((obj) => {
          return {
            productName: obj.productName,
            arches: obj.arches,
            quantityType: obj.quantityType,
          };
        })
      )
    ) {
      message.error("Remove duplicate products");
      return false;
    }

    try {
      setLoading(true);
      setIsButtonSpinning(true);
      const res = await saveProducts(
        {
          productsServices: finalValues,
          productNames: productList,
          onBoarded: true,
          prescriptionProducts: finalPrescriptionProducts
        },
        lab.labId
      );
      message.success("Products has been added.");
      dispatch(loadLab());
      history.push("/lab/profile");
      // console.log("saveProducts", res);
    } catch (error) {
      console.log(error);
      message.error("operation failed!");
    } finally {
      setLoading(false);
      setIsButtonSpinning(false);
    }
  };

  const productDuplicateValidate = (list) => {
    const map = new Map();
    const duplicates = [];

    list.forEach((obj) => {
      const key = JSON.stringify(obj);
      if (map.has(key)) {
        duplicates.push(obj);
      } else {
        map.set(key, true);
      }
    });
    return duplicates.length ? true : false;
  };

  const onProfileFormSubmit = async (labDetails) => {
    if (!profileFill) {
      setIsButtonSpinning(true);
      const addLabBody = {
        labName: labDetails.labName,
        labAdminName: labDetails.labAdminName,
        labServiceID: labDetails.labServiceID,
        labRegistrationNumber: labDetails.businessRegistrationNo,
        labID: lab.labId,
        labCountry: labDetails.country,
        labCounty: labDetails.county ? labDetails.county : "",
        labCity: labDetails.city,
        //  labAddress:labDetails.address,
        labAddress: labDetails.address,
        labZipcode: labDetails.postalCode,
        labPhoneNumber: labDetails.phone,
        labCountryCode: labDetails.prefix,
      };

      try {
        await addLab(addLabBody);
        // console.log(res);

        dispatch(
          updateLabData({
            labData: {
              labPhoneNumber: addLabBody?.labPhoneNumber || "",
              labCountryCode: addLabBody.labCountryCode,
              labName: addLabBody.labName,
              labAdminName: addLabBody.labAdminName,
            },
          })
        );

        //
        message.success("Lab details has been added.");
        loadLab();
        if (!productsDisabled) {
          setActiveTab("2");
          setProfileFill(true);
        }
        else{
          history.push("/lab/settings");
        }
      } catch (error) {
        console.log(error);
        errorModal("operation failed");
      } finally {
        setLoading(false);
        setIsButtonSpinning(false);
      }
    } else {
      setActiveTab("2");
    }
  };

  const handleSubmit = () => {
    if (activeTab === "1") {
      profileForm.submit();
    } else {
      productAndServicesForm.submit();
    }
  };

  const onChange = (key) => {
    if (profileFill) {
      setActiveTab(key);
    } else {
      message.info("Please complete the lab profile");
    }
  };

  const handleCancel = () => {
    if (profileFill) {
      setActiveTab("1");
    }
  };

  return (
    <div className="onboarding-container">
      <Spin spinning={loading}>
        <Row justify="start">
          <Title
            level={3}
            style={{
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Profile & Settings
          </Title>{" "}
        </Row>
        <Settings
          activeKey={activeTab}
          onChange={onChange}
          onProfileFormSubmit={onProfileFormSubmit}
          labDetails={labDetails}
          profileForm={profileForm}
          productAndServicesForm={productAndServicesForm}
          onProductsFormSubmit={onProductsFormSubmit}
          productsAndServices={[]}
          productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
          defaultCurrency={productOrServiceCurrencyOptions[0]?.currency_id}
          lab={lab}
          productList={productList}
          setProductList={setProductList}
          setProductFormData={setProductFormData}
          productFormData={productFormData}
          setPrescriptionProductList={setPrescriptionProductList}
          prescriptionProductList={prescriptionProductList}
        />

        <Row justify="end">
          <Col>
            <div style={{ marginTop: "1rem" }}>
              <Button
                shape="round"
                style={{
                  height: "2.5rem",
                  width: "10rem",
                  marginRight: "1.5rem",
                }}
                onClick={handleCancel}
              >
                {activeTab === "1" ? "Cancel" : "Back"}
              </Button>
              {productsDisabled ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  onClick={handleSubmit}
                  style={{
                    float: "right",
                    height: "2.5rem",
                    width: "10rem",
                  }}
                  loading={isButtonSpinning}
                >
                  Save
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  onClick={handleSubmit}
                  style={{
                    float: "right",
                    height: "2.5rem",
                    width: "10rem",
                  }}
                  loading={isButtonSpinning}
                >
                  {activeTab === "1" ? "Next" : "Finish"}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Onboarding;
