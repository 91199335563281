import { useState, useEffect } from "react";
// import { UploadOutlined } from "@ant-design/icons";
import { Col, Row, Upload, message, Modal, Typography } from "antd";
import { ReactComponent as DeleteIcon } from "assets/images/delete_icon.svg";
// import { ReactComponent as UploadIcon } from "../../../assets/images/upload.svg";
// import { dentistClinicApiTasks } from "models/dentistClinic";
import axios from "axios";
import { deleteScanFile } from "api/patient";

import { StlViewer } from "react-stl-file-viewer";
import { DownloadIcon } from "components/shared/Icon/index";

const { Dragger } = Upload;
// const { Link } = Anchor;
const { Text } = Typography;

const companyType = Number(process.env.REACT_APP_COMPANY_TYPE);

const UploadScanComponent = ({
  scanUrls,
  patientId,
  activeCaseId,
  getImg,
  treatmentOriginId,
  setClinicalFilesArray,
  clinicalFilesArray,
  setScanUrls,
}) => {


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalUrl, setModalUrl] = useState("");
  const [isUpperFileUploaded, setIsUpperFileUploaded] = useState(false);
  const [isLowerFileUploaded, setIsLowerFileUploaded] = useState(false);
  const [isBiteFileUploaded, setIsBiteFileUploaded] = useState(false);
  const [isBiteFileUploaded2, setIsBiteFileUploaded2] = useState(false);
  const [upperArchFile, setUpperArchFile] = useState([]);
  const [lowerArchFile, setLowerArchFile] = useState([]);
  const [biteScanFile, setBiteScanFile] = useState([]);
  const [biteScanFile2, setBiteScanFile2] = useState([]);
  const [volume, setvolume] = useState(0);

  useEffect(() => {
    setUpperArchFile(
      scanUrls?.filter((item) => item.patient_image_view === "UpperArch")
    );
    setLowerArchFile(
      scanUrls?.filter((item) => item.patient_image_view === "LowerArch")
    );
    setBiteScanFile(
      scanUrls?.filter((item) => item.patient_image_view === "BiteScan")
    );
    setBiteScanFile2(
      scanUrls?.filter((item) => item.patient_image_view === "BiteScan2")
    );

    // if (modalUrl !== "") {
    //   setIsModalVisible(true);
    // }
  }, [
    isUpperFileUploaded,
    isLowerFileUploaded,
    isBiteFileUploaded,
    isBiteFileUploaded2,
    // modalUrl,
    scanUrls,
  ]);

  useEffect(() => {
    setIsUpperFileUploaded(false);
    setIsLowerFileUploaded(false);
    setIsBiteFileUploaded(false);
    setIsBiteFileUploaded2(false);
  }, [activeCaseId]);

  const showModal = (title, scanData) => {
    // getImageUrl(filename);
    console.log("scanData",scanData);
    if (scanData?.url) {
      setModalTitle(title);
      setModalUrl(scanData.url);
    }
  };

  useEffect(() => {
    if (modalTitle) {
      setIsModalVisible(true);
    }
  }, [modalTitle]);

  const downloadScanFile = async (title, scanData) => {
    if (scanData?.url) {
      handleDownload(title, scanData.url);
    }
  };

  const handleDownload = (fileName, url) => {
    // Create a blob from the file content

    // Create an anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Set the file name for download
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    document.body.removeChild(a);
  };

  const deleteScan = (data) => {
    const deleteData = {
      patient_id: data?.patient_id,
      file_name: data?.resource_url,
    };

    deleteScanFile(deleteData)
      .then((res) => {
        setScanUrls((prev) =>
          prev.filter((el) => el.patient_image_view !== data.patient_image_view)
        );
        message.success("Resource Deleted Successfully");
      })
      .catch((err) => console.log("err", err));
  };

  const getImageUrl = async (filename) => {
    // const requestBody = {
    //   file_name: filename,
    // };
    // try {
    //   const response = await dentistClinicApiTasks.post(
    //     "clinic/patient/resource/getOnboardingImagesURL",
    //     requestBody
    //   );
    //   const res = response.body.url;
    //   const uri = res.url;
    //   const url = { url: uri };
    //   setModalUrl(url);
    //   return url;
    // } catch (error) {
    //   console.log(error);
    //   return null;
    // }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setModalTitle("");
    setModalUrl("");
  };

  const properties = {
    // accept: ".stl",
    multiple: false,
    customRequest: () => {},
  };


  const onChangeHandler = (info, type) => {
    const { status } = info.file;

    if (status === "removed") {
      return;
    }
    //  console.log("onChangeHandler", info, "type", type)
    // console.log("info.file", info.file)
    const fileObj = {
      filename: type,
      file: info.file,
    };

    // console.log(URL.createObjectURL(info.file))
    const allFiles = clinicalFilesArray.map((el) => {
      return {
        file: {
          ...el.file,
          originFileObj: new File(
            [el.file.originFileObj],
            el.file.originFileObj.name
          ),
        },
        filename: el.filename,
      };
    });
    allFiles.push(fileObj);
    setClinicalFilesArray(allFiles);
    info.file.status = "done";
    // setSelectedFle(info.file);
    // setScanType(event.target.id);
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }

  };

  const onRemove = (delFile) => {
    // console.log("fileeeeeeeeeeeeee", delFile)
    message.success(`${delFile.name} removed successfully`);
    const allFiles = [...clinicalFilesArray];
    // console.log("allFiles", allFiles)
    const filtered = allFiles.filter((file) => file.file.uid !== delFile.uid);
    // console.log("filtered", filtered)
    setClinicalFilesArray(filtered);
  };

  return (
    <div>
      {/* <Row>
        {scansArray.map((scan) => {
          return (
            <Col span={6} className="scan-container">
              <div>
                <div className="scan-subheader required">{scan.name}</div>
                {upperArchFile?.length > 0 ? (
                  <div>
                    <div
                      className="NewClassAnchor"
                      style={{ textAlign: "start" }}
                    >
                      <div
                        className="scanName"
                        onClick={() =>
                          showModal("UpperArch", upperArchFile[0].resource_url)
                        }
                      >
                        {scan.title}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <DownloadIcon
                          onClick={() =>
                            downloadScanFile(
                              "UpperArch",
                              upperArchFile[0].resource_url
                            )
                          }
                        />
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            deleteScan(upperArchFile[0]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : treatmentOriginId && treatmentOriginId === 3 ? (
                  <div>
                    <Dragger
                      name="UpperArch"
                      className="scan-uploader"
                      onChange={(info) => onChangeHandler(info, scan.type)}
                      {...properties}
                      onRemove={onRemove}
                    >
                      <p className="ant-upload-drag-icon scan-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text scan-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger>
                  </div>
                ) : (
                  <Text
                    style={{
                      float: "left",
                      fontSize: "16px",
                      textAlign: "start",
                    }}
                  >
                    Only Lab can upload the scan files
                  </Text>
                )}
              </div>
            </Col>
          );
        })}
      </Row> */}

      <Row>
        <Col span={6} className="scan-container">
          <div>
            <div className="scan-subheader required">Upper Arch  </div>
            {upperArchFile?.length > 0 ? (
              <div>
                <div className="NewClassAnchor" style={{ textAlign: "start" }}> 
                  <div
                    className="scanName"
                    onClick={() => showModal("UpperArch", upperArchFile[0])}
                  >
                    UpperArch.stl
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <DownloadIcon
                      onClick={() =>
                        downloadScanFile("UpperArch", upperArchFile[0])
                      }
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteScan(upperArchFile[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : treatmentOriginId && treatmentOriginId === 3 ? (
              <div>
                <Dragger
                  name="UpperArch"
                  className="scan-uploader"
                  onChange={(info) => onChangeHandler(info, "UpperArch")}
                  {...properties}
                  onRemove={onRemove}
                  data-testid="upload-upper-arch-file"
                >
                  <p className="ant-upload-drag-icon scan-upload-drag-icon">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="upload"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                    </svg>
                  </p>
                  <p className="ant-upload-text scan-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
            ) : (
              <Text
                style={{ float: "left", fontSize: "16px", textAlign: "start" }}
              >
                Only Lab can upload the scan files
              </Text>
            )}
          </div>
        </Col>
        <Col span={6} className="scan-container">
          <div>
            <div className="scan-subheader required">Lower Arch</div>
            {lowerArchFile?.length > 0 ? (
              <div>
                <div className="NewClassAnchor" style={{ textAlign: "start" }}>
                  <div
                    className="scanName"
                    onClick={() => showModal("LowerArch", lowerArchFile[0])}
                  >
                    LowerArch.stl
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <DownloadIcon
                      onClick={() =>
                        downloadScanFile("LowerArch", lowerArchFile[0])
                      }
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteScan(lowerArchFile[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : treatmentOriginId && treatmentOriginId === 3 ? (
              <div>
                <Dragger
                  name="LowerArch"
                  className="scan-uploader"
                  onChange={(info) => onChangeHandler(info, "LowerArch")}
                  {...properties}
                  onRemove={onRemove}
                  data-testid="upload-lower-arch-file"
                >
                  <p className="ant-upload-drag-icon scan-upload-drag-icon">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="upload"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                    </svg>
                  </p>
                  <p className="ant-upload-text scan-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
            ) : (
              <Text
                style={{ float: "left", fontSize: "16px", textAlign: "start" }}
              >
                Only Lab can upload the scan files
              </Text>
            )}
          </div>
        </Col>
        <Col span={6} className="scan-container">
          <div>
            <div className="scan-subheader">Bite Scan</div>
            {biteScanFile?.length > 0 ? (
              <div>
                <div className="NewClassAnchor" style={{ textAlign: "start" }}>
                  <div
                    className="scanName"
                    onClick={() => showModal("BiteScan", biteScanFile[0])}
                  >
                    BiteScan.stl
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <DownloadIcon
                      onClick={() =>
                        downloadScanFile("BiteScan", biteScanFile[0])
                      }
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteScan(biteScanFile[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : treatmentOriginId && treatmentOriginId === 3 ? (
              <div>
                <Dragger
                  name="BiteScan"
                  className="scan-uploader"
                  onChange={(info) => onChangeHandler(info, "BiteScan")}
                  {...properties}
                  onRemove={onRemove}
                  data-testid="upload-bite-scan-file"
                >
                  <p className="ant-upload-drag-icon scan-upload-drag-icon">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="upload"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                    </svg>
                  </p>
                  <p className="ant-upload-text scan-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
            ) : (
              <Text
                style={{ float: "left", fontSize: "16px", textAlign: "start" }}
              >
                Only Lab can upload the scan files
              </Text>
            )}
          </div>
        </Col>
        <Col span={6} className="scan-container">
          <div>
            <div className="scan-subheader">Bite Scan 2</div>
            {biteScanFile2?.length > 0 ? (
              <div>
                <div className="NewClassAnchor" style={{ textAlign: "start" }}>
                  <div
                    className="scanName"
                    onClick={() => showModal("BiteScan2", biteScanFile2[0])}
                  >
                    BiteScan2.stl
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <DownloadIcon
                      onClick={() =>
                        downloadScanFile("BiteScan2", biteScanFile2[0])
                      }
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteScan(biteScanFile2[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : treatmentOriginId && treatmentOriginId === 3 ? (
              <div>
                <Dragger
                  name="BiteScan2"
                  className="scan-uploader"
                  onChange={(info) => onChangeHandler(info, "BiteScan2")}
                  {...properties}
                  onRemove={onRemove}
                  data-testid="upload-bite-scan2-file"
                >
                  <p className="ant-upload-drag-icon scan-upload-drag-icon">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="upload"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                    </svg>
                  </p>
                  <p className="ant-upload-text scan-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </div>
            ) : (
              <Text
                style={{ float: "left", fontSize: "16px", textAlign: "start" }}
              >
                Only Lab can upload the scan files
              </Text>
            )}
          </div>
        </Col>
      </Row>
      {isModalVisible && (
        <Modal
          title={modalTitle}
          open={isModalVisible}
          footer={null}
          width={800}
          // onOk={handleCancel}
          onCancel={handleCancel}
        >
          <StlViewer
            width={750}
            height={600}
            url={modalUrl.url}
            objectColor="gray"
            volume={setvolume}
          />
        </Modal>
      )}
    </div>
  );
};

export default UploadScanComponent;