import { Button, Space, Typography, message } from "antd";
import PropTypes from "prop-types";

import BulbIcon from "components/shared/Icon/Bulb";
import css from "./styles.module.scss";
import { primaryColor } from "colors-config";
import { useState } from "react";
import PreviewIFrame from "./PreviewIFrame";

const { Link } = Typography;

const Message = () => {
  return (
    <div className={css["helper-container"]}>
      <div
        className={css["helper-message"]}
      >
        <BulbIcon className={css["icon"]} />{" "}
        <span> Add files above to generate link </span>
      </div>
    </div>
  );
};

const LinkPreview = ({ generatedLink }) => {
  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(generatedLink)
      .then(() => {
        message.info("Copied to clipboard!");
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };
 
  const LinkComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [iframeUrl, setIFrameUrl] = useState("");
    const onPreviewClick = () => {
      setIFrameUrl(generatedLink)
      setIsModalOpen(true);
      // window.open(generatedLink, '_blank');
    };
    return (
      <div className={css["preview-sections-wrapper"]}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "70%",
          }}
        >
          <div style={{ marginBottom: "10px" }}>3D link</div>
          <Button onClick={onPreviewClick} style={{color:primaryColor, borderColor:primaryColor}} shape="round">
              Preview
            </Button>
          {/* <Link
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              flex: 1, // Ensures it takes all available width
              color: primaryColor,
            }}
            href={generatedLink}
            target="_blank"
          >
            {generatedLink}
          </Link> */}
        </div>
        <div className={css["preview-btns"]}>
          <Space>
            {/* <Button onClick={onPreviewClick} style={{color:primaryColor, borderColor:primaryColor}} shape="round">
              Preview
            </Button> */}
            {/* <Button onClick={copyToClipboard} shape="round">
              Copy URL
            </Button> */}
          </Space>
        </div>
        <PreviewIFrame
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          iframeUrl={iframeUrl}
        />
      </div>
    );
  };

  return (
    <div className={css["link-preview-container"]}>
      {generatedLink ? <LinkComponent /> : <Message />}
    </div>
  );
};

LinkPreview.propTypes = {
  generatedLink: PropTypes.string,
};
LinkPreview.defaultValues = {
  generatedLink: "",
};

export default LinkPreview;
