import {useState} from "react"
import { Row, Col } from "antd";
import classNames from "classnames";
import styles from "./treatments.module.scss";
import EmptyTreatment from "./EmptyTreatment/EmptyTreatment";
import TreatmentModal from "./TreatmentModal/TreatmentModal";


const Treatments = () => {
  const [showTreatmentModal,setShowTreatmentModal] = useState(false);


  const handleShow = () =>{
    setShowTreatmentModal(true)
  }
   return (
    <div className={classNames(styles["treatments-container"])}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Treatments</h2>
        </Col>
      </Row>
      <EmptyTreatment handleShow={handleShow}/>
     <TreatmentModal showTreatmentModal={showTreatmentModal} setShowTreatmentModal={setShowTreatmentModal}/>


    </div>
  );
};

export default Treatments;
