import {
  ADD_COMMON_DATA
} from "../actions/types";

const initialState = {
  countries: [],
  genderList: [],
  meetingDurationList: []
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMMON_DATA:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default commonReducer;
