import React, { useState, useEffect } from "react";
import { LOWER_JAW, UPPER_JAW } from "components/private/Attachments/data";
import Tooth from "components/private/IPRInfo/Tooth/Tooth";

const Attachments = ({
  chartValue,
  upperAttachments,
  lowerAttachments,
  hideTeethParam,
}) => {
  const [upperStructure, setUpperStructure] = useState(UPPER_JAW);
  const [lowerStructure, setLowerStructure] = useState(LOWER_JAW);

  function getTeethValuesWithTrue(array) {
    const teethValues = [];
    array.forEach(item => {
      if (item.alignerValues) {
        item.alignerValues.forEach(attachment => {
          if (attachment.value === true && attachment.teeth !== null) {
            teethValues.push(attachment.teeth);
          }
        });
      }
    });
    return teethValues;
  }

  function getTeethValuesWithPressureTrue(array) {
    const teethValues = [];
    array.forEach((item) => {
      if (item.alignerValues) {
        item.alignerValues.forEach((attachment) => {
          if (attachment.pressureLine === "yes" && attachment.teeth !== null) {
            teethValues.push(attachment.teeth);
          }
        });
      }
    });
    return teethValues;
  }

  useEffect(() => {
    let previousTooths = [];
    let pressureTooths = [];
    const mergedArray = upperAttachments.length ? [...upperAttachments] : [];
    previousTooths = getTeethValuesWithTrue(mergedArray);
    pressureTooths = getTeethValuesWithPressureTrue(mergedArray);
    const structure = [...UPPER_JAW];
    const updatedStructure = structure.map(Item => {
      const matchingTooth = previousTooths.find(tooth => tooth == Item.id);
      return {
        ...Item,
        fdiName: Item.id,
        selected: matchingTooth ? true : false,
        pressurePoint: pressureTooths.includes(Item.id)
      };
    });
    setUpperStructure(updatedStructure);
  }, [upperAttachments]);

  useEffect(() => {
    let previousTooths = [];
    let pressureTooths = [];
    const mergedArray = lowerAttachments.length ? [...lowerAttachments] : [];
    previousTooths = getTeethValuesWithTrue(mergedArray);
    pressureTooths = getTeethValuesWithPressureTrue(mergedArray);
    const structure = [...LOWER_JAW];
    const updatedStructure = structure.map(Item => {
      const matchingTooth = previousTooths.find(tooth => tooth == Item.id);
      return {
        ...Item,
        fdiName: Item.id,
        selected: matchingTooth ? true : false,
        pressurePoint: pressureTooths.includes(Item.id)
      };
    });
    setLowerStructure(updatedStructure);
  }, [lowerAttachments]);

  //   console.log("upperStructure", upperStructure);

  const bottomPosition =
    hideTeethParam !== "lower" && hideTeethParam.length ? "-25px" : "unset";
  return (
    <div>
      <div className="ipr-detail-container">
        <div className="teethChart-container">
          <div className="teethRow-container">
            {hideTeethParam !== "upper" && <div className="teethRow-bgOne" />}
            <div className="teethRow-one ipr-row">
              {hideTeethParam !== "upper" && (
                <>
                  {upperStructure.map(tooth => (
                    <div key={tooth.id}>
                      <Tooth
                        type="upper"
                        tooth={tooth}
                        chartValue={chartValue}
                      />
                    </div>
                  ))}
                </>
              )}
              <div className="tooth-sides" style={{ bottom: bottomPosition }}>
                <h2>Right</h2>
                <h2>Left</h2>
              </div>
              {hideTeethParam !== "lower" && (
                <>
                  {lowerStructure.map(tooth => (
                    <div key={tooth.id}>
                      <Tooth
                        type="lower"
                        tooth={tooth}
                        chartValue={chartValue}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
            {hideTeethParam !== "lower" && (
              <div className="teethRow-bgTwo"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attachments;
