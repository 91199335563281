import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Row, Col, Button, Checkbox, Spin, message } from "antd";
import { Link } from "react-router-dom";

import { Typography } from "antd";

// import logo from "../../assets/images/smile-genius-logo.png";
import image from "../../assets/images/Registration-Webpage-2.png";
import PasswordHandleModal from "../../components/shared/modals/passwordHandleModal";

// import errorModal from "../../components/shared/modals/errorModal";

// import { labApiTasks } from "../../models/lab";
import { connect } from "react-redux";
import { login } from "../../services/redux/actions/authActions";
// import { PUBLIC_API_BASE_URL_DEV } from "../../services/api/utilities/public";

import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import "../../assets/styles/routes/login.scss";
import {
  LogInPageLogo,
  companyName,
  companyType,
  smilePrivacyUrl,
  smileTermsUrl,
  primaryColor,
} from "colors-config";
import { AnchorTag } from "components/shared/AnchorTag/AnchorTag";
import { RedirectLink } from "components/shared/RedirectLink/RedirectLink";
import AppButton from "components/shared/AppButton/AppButton";


// const Tc_url =process.env.REACT_APP_TC_URL

const { Title } = Typography;

// const formItemLayout = {
//   labelCol: {
//     xs: {
//       span: 24,
//       offset: 1,
//     },
//     sm: {
//       span: 8,
//       offset: 2,
//     },
//     lg: {
//       span: 8,
//       offset: 3,
//     },
//     xl: { span: 8, offset: 3 },
//     xxl: { span: 8, offset: 3 },
//   },
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 1,
//     },
//     sm: {
//       span: 20,
//       offset: 2,
//     },
//     lg: {
//       span: 16,
//       offset: 3,
//     },
//     xl: { span: 15, offset: 3 },
//     xxl: { span: 14, offset: 3 },
//   },
// };
// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 20,
//       offset: 2,
//     },
//     lg: {
//       span: 16,
//       offset: 3,
//     },
//     xl: { span: 15, offset: 3 },
//     xxl: { span: 14, offset: 3 },
//   },
// };

function Login({ isAuthenticated, login }) {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const location = useLocation();
  // const [isButtonSpinning,setIs]
  const containerRef = useRef();

  const [form] = Form.useForm();

  useEffect(() => {
    document.body.style = "background:white !important;";
    _getPolicyLinks();

    return () => {
      //   document.body.style = "background: #f3f6fa  !important;";
      //   containerRef.current.style = "background-color:#f7f9f9";
    };
  }, []);
  const history = useHistory();
  function removeAWSKey(url) {
    return url.slice(0, url.indexOf("?"));
  }
  const _getPolicyLinks = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_CLINIC_BASE_URL}/public/getGenericTC`
      );
      let termsUrl = smileTermsUrl;
      let privacyUrl = smilePrivacyUrl;
      if (
        res?.data?.body?.privacy_policy?.url &&
        res?.data?.body?.terms_of_service?.url
      ) {
        // if (companyType!==-1) {
          termsUrl = res?.data?.body?.terms_of_service?.url;
          privacyUrl = res?.data?.body?.privacy_policy?.url;
        // }
      }
      setPrivacyPolicyUrl(privacyUrl);
      setTermsOfServiceUrl(termsUrl);
    } catch (error) {
      console.log(error);
    }
  };

  async function signIn(username, password) {
    login(username, password, history, setIsSpinning, location);
  }

  useEffect(() => {
    form.resetFields();
    const remember = localStorage.getItem("remember");
    if (remember && remember === "true") {
      const email = localStorage.getItem("email");
      if (email) {
        form.setFieldsValue({
          email,
          remember: true,
        });
      }
    }
  }, []);

  const onFinish = (values) => {
    setIsRegistered(true);
    setIsSpinning(true);
    const { email: username, password, remember } = values;

    if (remember) {
      localStorage.setItem("remember", "true");
      localStorage.setItem("email", username);
    }

    signIn(username, password);
  };
  const confirmForgotPassword = async (email) => {
    try {
      const res = await Auth.forgotPassword(email);
      console.log(res, "res");
      history.push({
        pathname: "/forgot-password",
        state: { message: email },
      });
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
        offset: 1,
      },
      sm: {
        span: 8,
        offset: 2,
      },
      lg: {
        span: 8,
        offset: 3,
      },
      xl: { span: 8, offset: 3 },
      xxl: { span: 8, offset: 3 },
    },
    wrapperCol: {
      xs: {
        span: 24,
        offset: 1,
      },
      sm: {
        span: 20,
        offset: 2,
      },
      lg: {
        span: 16,
        offset: 3,
      },
      xl: { span: 15, offset: 3 },
      xxl: { span: 14, offset: 3 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 2,
      },
      lg: {
        span: 16,
        offset: 3,
      },
      xl: { span: 15, offset: 3 },
      xxl: { span: 14, offset: 3 },
    },
  };

  return (
    <div
      ref={containerRef}
      className="login-container"
      style={{ fontFamily: "Mulish" }}
    >
      {showForgotPasswordModal && (
        <PasswordHandleModal
          title="Forgot Password"
          showModal={showForgotPasswordModal}
          onConfirmation={confirmForgotPassword}
          setShow={setShowForgotPasswordModal}
        ></PasswordHandleModal>
      )}
      <Row>
        <Col lg={12}>
          <div className="login-image-container">
            <img className="image" src={LogInPageLogo} alt="placeholder" />
          </div>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className="registration-form-container">
            <div className="register-form">
              <div className="login-title-container">
                <Title
                  level={2}
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Login to <span style={{ color: primaryColor}}>Lab Portal</span>
                </Title>

                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    fontFamily: "Mulish",
                    margin: "14px 0px",
                  }}
                >
                  Let’s take the first step to start your journey with{" "}
                  {companyName}
                </p>
              </div>
              <Form
                className="registration-form"
                {...formItemLayout}
                form={form}
                layout={"vertical"}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
                style={{ marginTop: "20px" }}
              >
                <Form.Item
                  name="email"
                  style={{ fontFamily: "Mulish" }}
                  rules={[
                    {
                      type: "email",
                      message: "Invalid email address",
                    },
                    {
                      required: true,
                      message: "Please enter your E-mail!",
                    },
                  ]}
                  label="Email ID"
                >
                  <Input data-testid="email-input" size="large" placeholder="Email ID" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  style={{ fontFamily: "Mulish", marginBottom: "10px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password data-testid="password-input" size="large" placeholder="Password" />
                </Form.Item>

                <Form.Item style={{ marginBottom: "0" }}>
                  <div
                    style={{
                      textAlign: "left",
                      float: "left",
                      fontFamily: "Mulish",
                    }}
                  >
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox data-testid="remember-me">Remember Me</Checkbox>
                    </Form.Item>
                  </div>
                  <AnchorTag
                    style={{ fontFamily: "Mulish" }}
                    className="login-form-forgot"
                    onUrlClicked={() => setShowForgotPasswordModal(true)}
                    text="Forgot Password?"
                  />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <div className="btn-container">
                    {/* <Spin spinning={isSpinning}> */}
                    <AppButton
                      loading={isSpinning}
                      type="primary"
                      className="registration-button"
                      htmlType="submit"
                      data-testid="login-button"
                    >
                      Log in
                    </AppButton>
                    {/* </Spin> */}
                  </div>
                </Form.Item>
              </Form>

              <div className="login-footer">
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#1D1D1D",
                      fontSize: "17px",
                      display: "inline",
                      fontFamily: "Mulish",
                      width: "100%",
                    }}
                  >
                    Don't have an account?
                  </p>
                  <p
                    style={{
                      fontSize: "17px",
                      display: "inline",
                      marginLeft: "0.5rem",
                      fontWeight: "bolder",

                      width: "100%",
                    }}
                  >
                    <RedirectLink text={"Sign up"} url={"/registration"} />
                  </p>
                </div>
                <p className="terms-line">
                  By clicking on ‘Login’ you agree to our{" "}
                  <AnchorTag
                    url={termsOfServiceUrl}
                    target="_blank"
                    rel="noreferrer"
                    text="Terms of Service"
                  />
                  &nbsp;and&nbsp;
                  <AnchorTag
                    url={privacyPolicyUrl}
                    target="_blank"
                    rel="noreferrer"
                    text="Privacy Policy"
                  />
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { login })(Login);
