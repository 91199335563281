import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { Auth } from "aws-amplify";
import StaffTable from "./StaffTable/StaffTable";
import StaffHeader from "./StaffHeader";
import AddStaffModal from "./AddStaffModal/AddStaffModal";
import ResendInviteModal from "./ResendInviteModal/ResendInviteModal";
import { TABLE_ACTION_TYPES } from "./constants";
import { getLabId } from "services/redux/selectors/commonSelectors";
import useGetStaff from "./useGetStaff";
import { handleApiService } from "./services";
import { deleteLabStaff } from "api/staff";
import { subUserSelector } from "services/redux/selectors/lab.selectors";
import { staffMembers } from "services/redux/actions/labActions";

const ManageStaff = () => {
  const labId = useSelector(getLabId);
  const subUser = useSelector(subUserSelector);
  const [loading, staffData, rawData, error, fetchData] = useGetStaff(labId);
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [showResendInviteModal, setShowResendInviteModal] = useState(false);
  const selectedRowRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(staffMembers(rawData));
  }, [rawData, dispatch]);

  const addStaffHandler = () => {
    setShowAddStaffModal(true);
  };
  const deleteStaffService = () => {
    handleApiService(
      deleteLabStaff,
      { labId, staffId: selectedRowRef.current.id },
      setLoader,
      (data) => {
        message.success("Staff Member deleted");
        fetchData();
      }
    );
  };

  const handleResetUserPassword = async () => {
    try {
      setLoader(true);
      await Auth.forgotPassword(selectedRowRef.current.email);
      message.success("Password reset successfully");
    } catch (error) {
      message.error("operation failed, please try again");
    } finally {
      setLoader(false);
    }

  };
  const staffActionHandler = ({ key }, currentRow) => {
    selectedRowRef.current = currentRow;
    if (key === TABLE_ACTION_TYPES.RESET_PASSWORD) {
      handleResetUserPassword();
    }

    if (key === TABLE_ACTION_TYPES.RESEND_INVITE) {
      setShowResendInviteModal(true);
    }

    if (key === TABLE_ACTION_TYPES.DELETE_INVITE) {
      deleteStaffService();
    }
  };
  return (
    <div>
      {showResendInviteModal && (
        <ResendInviteModal
          fetchData={fetchData}
          labId={labId}
          selectedRowRef={selectedRowRef}
          showResendInviteModal={showResendInviteModal}
          setShowResendInviteModal={setShowResendInviteModal}
        />
      )}
      {showAddStaffModal && (
        <AddStaffModal
          fetchData={fetchData}
          labId={labId}
          showAddStaffModal={showAddStaffModal}
          setShowAddStaffModal={setShowAddStaffModal}
        />
      )}
      <StaffHeader addStaffHandler={addStaffHandler} />
      <StaffTable
        loading={loading || loader}
        staffActionHandler={staffActionHandler}
        staffData={staffData}
        subUser={subUser}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default ManageStaff;
