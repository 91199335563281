import { Row, Col } from "antd";
import { ReactComponent as CalendarIcon } from "assets/images/calendar_icon.svg";
import { ReactComponent as SmartphoneIcon } from "assets/images/smartphone_icon.svg";
import { ReactComponent as AddressIcon } from "assets/images/address_icon.svg";
import { ReactComponent as EmailIcon } from "assets/images/envelope.svg";

import { UserOutlined } from "@ant-design/icons";

const ClinicInfo = ({ clinicInfo, primaryColor, clinicName, clinicAddress, time }) => {

  return (
    <div className="patient-info">
      <Row gutter={[32, 16]}>
        {/* Hours */}
        <Col lg={4} md={6} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">Hours</div>
            <div className="content">
              <CalendarIcon stroke={primaryColor} />
              <span style={{ marginLeft: "8px" }}>{time || "-"}</span>
            </div>
          </div>
        </Col>

        {/* Mobile Number */}
        <Col lg={4} md={6} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">Mobile No</div>
            <div className="content">
              <SmartphoneIcon stroke={primaryColor} />
              <span style={{ marginLeft: "8px" }}>
                {clinicInfo?.clinic_contact || "-"}
              </span>
            </div>
          </div>
        </Col>

        {/* Email */}
        <Col lg={5} md={8} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">Email</div>
            <div className="content">
              <EmailIcon
                style={{ height: "20px", width: "20px" }}
                fill={primaryColor}
              />
              <span style={{ marginLeft: "8px" }}>
                {clinicInfo?.clinic_email || "-"}
              </span>
            </div>
          </div>
        </Col>

        {/* Address */}
        <Col lg={6} md={6} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">Address</div>
            <div className="content">
              <AddressIcon fill={primaryColor} />
              <span style={{ marginLeft: "8px" }}>{clinicAddress}</span>
            </div>
          </div>
        </Col>

        {/* Clinic Name */}
        <Col lg={5} md={8} sm={12} xs={24}>
          <div className="info-card">
            <div className="caption">Clinic Name</div>
            <div className="content">
              <UserOutlined style={{ color: primaryColor }} />
           

              <span style={{ marginLeft: "8px" }}>{clinicName}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ClinicInfo;
