import { Tooltip, Dropdown, Menu, Button } from "antd";
import PropTypes from "prop-types";

import { teethCollection } from "./TeethSvgs";
import { companyType } from "colors-config";

const ToothSvg = ({ id }) => {
  const ToothComponent = teethCollection[+id];
  return ToothComponent ? <ToothComponent /> : <div>No SVG for ID {id}</div>;
};

const Tooth = ({ tooth, chartValue }) => {
  const toothNameSwitcher = (t, value) => {
    if (value === "fdi") {
      return t.fdiName;
    }
    if (value === "universal") {
      return t.universalName;
    }

    return t.palmerName;
  };

  return (
    <>
      <Tooltip
        placement="topLeft"
        title={<div>{`Tooth No: ${toothNameSwitcher(tooth, chartValue)}`}</div>}
      >
        <div className={`${
          tooth.selected ? "teeth-structure active-teeth" : "teeth-structure"
        } ${
          companyType === 5
            ? "teeth-structure-smilealign"
            : ""
        }
        ${
          companyType === 6
            ? "teeth-structure-ias"
            : ""
        }
        ${
          companyType === 7
            ? "teeth-structure-aqua"
            : ""
        }
        ${tooth.pressurePoint ? "stroke-teeth" : ""}
        ${
          companyType === 8
            ? "teeth-structure-brushbar"
            : ""
        }
        `}>
          {<ToothSvg id={tooth.showName} />}
          <span>{toothNameSwitcher(tooth, chartValue)}</span>
        </div>
      </Tooltip>
    </>
  );
};

export default Tooth;
