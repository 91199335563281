import { companyType } from "colors-config";
const PrescriptionDuration = ({ prescriptionPDFObj }) => {
  return (
    <div className="prescriptionPDF-card">
      <h2>Duration & Prescription details</h2>
      <div className="">
        <div>
          <div>
            <label className="treatment-label">Duration per Aligner</label>
            <p className="treatment-info">{prescriptionPDFObj.duration}</p>
          </div>
        </div>
        <div>
          <div>
            <label className="treatment-label">
              {companyType === 8 ? "Treatment Notes" : "Prescription Details"}
            </label>
            <p className="treatment-info">
              {prescriptionPDFObj.prescriptionDetails}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionDuration;
