import React, { useCallback } from "react";
import { Button, Divider, Select, Input, Row, Col, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { showTeethName } from "./data";
import { ReactComponent as AddTooth } from "assets/images/teeths/addTooth.svg";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
// import { ReactComponent as DeleteIcon } from "../../../assets/images/teeths/deleteIcon.svg";
import "./teethChart.scss";
import { companyType, primaryColor } from "colors-config";
const { Option } = Select;
// const { TextArea } = Input;

const TeethInfo = ({
  toothStatuses,
  setToothStatuses,
  setTeethStructure,
  teethStructure,
  chartValue,
  teethChartRef,
}) => {
  const handleChange = (value, field, index) => {
    if (field === "toothNo") {
      const filteredStatus = toothStatuses?.filter(
        (tooth) => tooth.toothNo == value
      );
      // console.log("filteredStatus", filteredStatus)
      if (filteredStatus?.length > 0) {
        return message.info("Tooth is already selected add additional data");
      }
    }

    const newData = [...toothStatuses];
    newData[index][field] = value;
    setToothStatuses(newData);
    teethChartRef.current = newData;
  };

  const handleStatusDelete = (toothNum) => {
    const newData = [...toothStatuses];
    const filtered = newData.filter((status) => status.toothNo !== toothNum);
    setToothStatuses(filtered);
    teethChartRef.current = filtered;
    const structure = [...teethStructure];
    const updatedTeths = structure.map((tooth) => {
      if (tooth.id === toothNum) {
        return {
          ...tooth,
          selected: false,
        };
      }
      return tooth;
    });
    setTeethStructure(updatedTeths);
  };

  const handleAddTreatment = (index, toothNum) => {
    const newData = [...toothStatuses];
    const newTreatment = {
      procedure: "tooth_status",
      detail: "",
      toothNo: toothNum,
      isAdditional: true,
      isExtra: false,
    };
    newData[index].addOns.push(newTreatment);
    setToothStatuses(newData);
    teethChartRef.current = newData;
  };

  const handleDeleteAddOn = (rowIndex, addOnIndex) => {
    const newData = [...toothStatuses];
    newData[rowIndex].addOns.splice(addOnIndex, 1);
    setToothStatuses(newData);
    teethChartRef.current = newData;
  };

  const handleAddOnChange = (value, field, rowIndex, addOnIndex) => {
    //  console.log(value, field, rowIndex, addOnIndex);
    const newData = [...toothStatuses];
    // console.log("newData",newData)
    newData[rowIndex].addOns[addOnIndex][field] = value;
    setToothStatuses(newData);
    teethChartRef.current = newData;
  };
  //  console.log("toothStatuses", toothStatuses)
  return (
    <div className="teethStatus-container">
      <h1>Status</h1>
      <Divider />
      {!toothStatuses?.length && (
        <div className="add-tooth-container">
        <AddTooth
          style={{fill: primaryColor}}/>
          <p>Use the above chart to add teeth status</p>
        </div>
      )}
      <div className="status-editForm">
        {toothStatuses ?  toothStatuses?.map((item, index) => {
          return (
            <div key={index}>
              <Row
                gutter={[16, 16]}
                align="middle"
                style={{ marginTop: "10px" }}
              >
                <Col lg={4} sm={24}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        display: item.isExtra ? "none" : "block",
                        background: primaryColor,
                      }}
                      className="toothName"
                    >
                      {chartValue === "fdi"
                        ? item.toothNo
                        : showTeethName(item.toothNo, chartValue)}
                    </p>
                    <Select
                      size="large"
                      style={{ display: !item.isExtra ? "none" : "block" }}
                      placeholder="Select Tooth"
                      value={item.toothNo}
                      // className={!item.isExtra ? "toothNum-input" : ""}
                      className={`${!item.isExtra ? "toothNum-input" : ""} ${companyType === 5 ? "toothNum-input-select-smilelign" : companyType === 6 ? "toothNum-input-select-ias" : companyType === 7 ? "toothNum-input-select-aqua": companyType === 8 ? "toothNum-input-select-brushbar":"toothNum-input-select"} custom-placeholder`}
                      disabled={!item.isExtra}
                      onChange={(value) =>
                        handleChange(value, "toothNo", index)
                      }
                    >
                      {teethStructure?.map((tooth, index) => {
                        return (
                          <Option value={tooth.name} key={index}>
                            {chartValue === "fdi"
                              ? tooth.showName
                              : chartValue === "universal"
                              ? tooth.universalName
                              : tooth.palmerName}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
                <Col lg={7}>
                  <Select
                    size="large"
                    value={item.procedure}
                    onChange={(value) =>
                      handleChange(value, "procedure", index)
                    }
                  >
                    <Option value="tooth_status">Tooth Status</Option>
                    <Option value="missing">Missing</Option>
                    <Option value="extraction_planned">
                      Extraction Planned
                    </Option>
                    <Option value="crown">Crown</Option>
                    <Option value="veneer">Veneer</Option>
                    <Option value="Inlay_Onlay">Inlay / Onlay</Option>
                    <Option value="bridge">Bridge</Option>
                    <Option value="implant">Implant</Option>
                    <Option value="deciduous">Deciduous</Option>
                    <Option value="impacted">Impacted</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Col>
                <Col lg={11}>
                  <Input
                    placeholder="Detail"
                    size="large"
                    value={item.detail}
                    onChange={(e) =>
                      handleChange(e.target.value, "detail", index)
                    }
                  />
                </Col>
                <Col lg={2}>
                  <Button
                    className="delete-btn"
                    onClick={() => handleStatusDelete(item.toothNo)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Col>
              </Row>

              {item?.addOns?.map((addOn, addOnIndex) => (
                <div key={addOnIndex}>
                  <Row
                    gutter={[16, 16]}
                    align="middle"
                    style={{ marginTop: "10px" }}
                  >
                    <Col lg={4}></Col>
                    <Col lg={7}>
                      <Select
                        size="large"
                        value={addOn.procedure}
                        onChange={(value) =>
                          handleAddOnChange(
                            value,
                            "procedure",
                            index,
                            addOnIndex
                          )
                        }
                      >
                        <Option value="tooth_status">Tooth Status</Option>
                        <Option value="missing">Missing</Option>
                        <Option value="extraction_planned">
                          Extraction Planned
                        </Option>
                        <Option value="crown">Crown</Option>
                        <Option value="veneer">Veneer</Option>
                        <Option value="Inlay_Onlay">Inlay / Onlay</Option>
                        <Option value="bridge">Bridge</Option>
                        <Option value="implant">Implant</Option>
                        <Option value="deciduous">Deciduous</Option>
                        <Option value="impacted">Impacted</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    </Col>
                    <Col lg={11}>
                      <Input
                        size="large"
                        value={addOn.detail}
                        placeholder="Detail"
                        onChange={(e) =>
                          handleAddOnChange(
                            e.target.value,
                            "detail",
                            index,
                            addOnIndex
                          )
                        }
                      />
                    </Col>
                    <Col lg={2}>
                      <Button
                        className="delete-btn"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeleteAddOn(index, addOnIndex)}
                      ></Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row gutter={[16, 16]}>
                <Col offset={17}>
                  <Button
                    onClick={() => handleAddTreatment(index, item.toothNo)}
                    style={{ marginTop: "0px" }}
                    className="addOn-btn"
                    icon={<AddIcon style={{ fill: primaryColor }} />}
                  >
                    Additional Status
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }):""}
      </div>
    </div>
  );
};

export default TeethInfo;
