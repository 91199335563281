import { useState,  useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { message, Row, Col, Button } from "antd";
import AccountVerified from "assets/images/AccountVerified.png";
import NotVerified from "assets/images/NotVerified.png";
// import Verified from "assets/images/Verified.png";
// import VerifiedIAS from "assets/images/Verified_ias.png";
// import VerifiedSmilelign from "assets/images/Verified_smilelign.png";
import Registered from "assets/images/Registered.png";
import RegisteredSmile from "assets/images/RegisteredSmile.png";
// import RegisteredIAS from "assets/images/RegisteredIAS.png";
import VerifiedAccount from "assets/images/VerifiedAccount.png";

import { Auth } from "aws-amplify";

import "../../assets/styles/routes/registration.scss";
import { LogInPageLogo, companyType } from "colors-config";
import styles from "./user.module.scss";


export default function ConfirmUser() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [resendView, setResendView] = useState(false);

  // Accessing individual query parameters
  const typeParam = queryParams.get("type");
  const emailParam = queryParams.get("email");

//   console.log("typeParam", typeParam);
//   console.log("emailParam", emailParam);

  const containerRef = useRef();

  // const checkQueryString = () => {
  //   if (!keyParam) {
  //     return null;
  //   }
  //   const obj = stringDecode(keyParam);
  //   return obj;
  // };

  // useEffect(() => {
  //   onInitialLoad();
  // }, []);

  // const onInitialLoad = async () => {
  //   try {
  //     setIsSpinning(true);
  //     await _getPolicyLinks();
  //     const paramsData = checkQueryString(keyParam);
  //     if (keyParam && paramsData && paramsData?.type === ONBOARDING_PROGRESS.ONBOARDING_REQUESTED) {
  //       await handleLabClinicOnBoarding(paramsData?.email);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  //   finally {
  //     setIsSpinning(false);
  //   }

  // }

  if (!typeParam) {
    history.push("/404");
  }

  if (
    typeParam !== "expired" &&
    typeParam !== "success" &&
    typeParam !== "confirmed" &&
    typeParam !== "registered"
  ) {
    history.push("/404");
  }

  if (typeParam === "expired" && !emailParam) {
    history.push("/404");
  }

  const onResendEmail = async () => {
    try {
      await Auth.resendSignUp(emailParam);
      setResendView(true);
    } catch (error) {
      console.log("error", error);
      message.error(
        error.message || "Attempt limit exceeded, please try after some time.",
        4000
      );
    }
  };


  const loginPageHandler = () =>{
    history.push("/login");
  }

  return (
    <div
      ref={containerRef}
      className="registration-container"
      style={{ fontFamily: "Mulish" }}
    >
      <Row>
        <Col lg={12}>
          <div className="registration-image-container">
            <img className="image" src={LogInPageLogo} alt="placeholder" />
          </div>
        </Col>

        <Col lg={12} md={24} sm={24} xs={24}>
          <div className={styles["userColTwo"]}>
            <Row justify="center" align="middle" style={{ width: "100%" }}>
              {typeParam === "success" && !resendView ? (
                <Col span={12}>
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={AccountVerified}
                    alt="VerifiedAccount"
                    className={styles["info-image"]}
                  />
                  <h1 className={styles["heading"]}>Account Verified!</h1>
                  <p className={styles["description"]}>
                    You can now login and start using our platform to manage
                    your clinic orders and more.
                  </p>
                  <Button
                    type="primary"
                    shape="round"
                    className={styles["resendBtn"]}
                    onClick={loginPageHandler}
                  >
                    Go to login page
                  </Button>
                </Col>
              ) : null}
              {typeParam === "registered" && !resendView ? (
                <Col span={12}>
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={VerifiedAccount}
                    alt="logo"
                    className={styles["register-image"]}
                  />
                  <h1 className={styles["heading"]}>
                  Verification link sent to your email
                  </h1>
                  <p className={styles["description"]}>
                  Please click the verification link sent and you are ready to login.
                  </p>
                </Col>
              ) : null}
              {typeParam === "confirmed" && !resendView ? (
                <Col span={12}>
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={AccountVerified}
                    alt="logo"
                    className={styles["info-image"]}
                  />
                  <p className={styles["heading"]}>Account Already Verified!</p>
                  <p className={styles["description"]}>
                    You can now log in and start using our platform to manage
                    your clinic orders and more.
                  </p>
                  <Button
                    type="primary"
                    shape="round"
                    className={styles["resendBtn"]}
                    onClick={loginPageHandler}
                  >
                    Go to login page
                  </Button>
                </Col>
              ) : null}
              {typeParam === "expired" && !resendView ? (
                <Col span={12}>
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={NotVerified}
                    alt="logo"
                    className={styles["info-image"]}
                  />
                  <h1 className={styles["heading"]}>Account not Verified</h1>
                  <p className={styles["description"]}>
                    Its looks like your account is not verified yet.
                  </p>
                  <p className={styles["description"]}>
                    Please check your email or resend verification email.
                  </p>
                  <Button
                    type="primary"
                    onClick={onResendEmail}
                    shape="round"
                    className={styles["resendBtn"]}
                  >
                    Resend Verification Email
                  </Button>
                </Col>
              ) : null}

              {typeParam === "expired" && resendView ? (
                <Col span={12}>
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={companyType === 5 ? RegisteredSmile : Registered}
                    alt="logo"
                    className={styles["register-image"]}
                  />
                  <h1 className={styles["heading"]}>
                    {" "}
                    Verification Email sent to your email
                  </h1>
                  <p className={styles["description"]}>
                    Please check your email for verification link.
                  </p>
                </Col>
              ) : null}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
