import { Table } from "antd";
import { makeStaffColumns } from "./Columns";
import PropTypes from "prop-types";

const StaffTable = ({ loading, staffData, staffActionHandler, subUser, scroll }) => {

  return (
    <Table
      loading={loading}
      columns={makeStaffColumns(staffActionHandler, subUser)}
      dataSource={staffData}
      scroll={scroll}
    />
  );
};

StaffTable.defaultProps = {
  staffData: [],
};
StaffTable.propTypes = {
  staffData: PropTypes.array,
  staffActionHandler: PropTypes.func.isRequired,
};

export default StaffTable;
