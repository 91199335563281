import React, { useState, useEffect } from "react";
import { Steps, Button, Card, message, Form } from "antd";
// import { labApiTasks } from "../../../models/lab";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "../../../assets/images/edit_icon.svg";
import { ReactComponent as Tracking } from "assets/images/Tracking.svg";
// import { EditOutlined } from "@ant-design/icons";

import DeliveryUpdateModal from "../../shared/modals/deliveryUpdateModal";
// import ConfirmationModal from "../../shared/modals/confirmationModal";

import "../../../assets/styles/components/patientOrdersStepsComponent.scss";
import {
  getProducts,
  updateLabOrderStatus,
  updateLabOrderTrackingStatus,
  uploadAppMediaApi,
  updateLabOrderDispatchStatus,
  updatePhaseOrderStatuses,
} from "api/lab";
// import { AnchorTag } from "components/shared/AnchorTag/AnchorTag";
import { makeClinicAddress } from "utils/Lab";
import OrderDetail from "./OrderDetail";
import AppButton from "components/shared/AppButton/AppButton";
import { updateClinicIntercomJourneyStatus } from "api/clinic";
import PrintOrderModal from "../LabOrder/PrintOrderModal/PrintOrderModal";
import PrintStage from "../LabOrder/PrintStage/PrintStage";
import UpdateDispatchDateModal from "../LabOrder/UpdateDispatchDateModal/UpdateDispatchDateModal";
import OrderScansModal from "components/private/OrderScansModal/OrderScansModal";

const companyType = process.env.REACT_APP_COMPANY_TYPE;
const { Step } = Steps;

const PatientAlignerOrderStepsComponent = ({
  steps,
  orderDetails,
  getOrders,
  patientId,
  treatmentSetup,
  patientAddressRef,
  clinicInfo,
  treatmentPlans,
  getPatientInformation,
}) => {
  // console.log("orderDetails",orderDetails)
  const [printForm] = Form.useForm();
  const lab = useSelector(state => state.lab);
  const [currentStep, setCurrentStep] = useState(1);
  // console.log("currentStep",currentStep)
  const [showDeliveryUpdateModal, setShowDeliveryUpdateModal] = useState(false);
  const [showPrintingConfirmationModal, setShowPrintingConfirmationModal] =
    useState(false);

  const [isPrintingPaused, setIsPrintingPaused] = useState(false);

  const [labProducts, setLabProducts] = useState([]);
  const [labProductDetails, setLabProductDetails] = useState();
  const [updateData, setUpdateData] = useState();
  const [resourceFileList, setResourceFileList] = useState([]);
  const [removeInvoiceFiles, setRemoveInvoiceFiles] = useState([]);
  const [printLoader, setPrintLoader] = useState(false);
  const [showEditDispatchModal, setShowEditDispatchModal] = useState(false);
  const [showScanFileModal, setShowScanFileModal] = useState(false);
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    if (orderDetails?.labProductId && lab?.labId) {
      getAllLabProducts(lab.labId, orderDetails.labProductId);
    }
  }, [orderDetails, lab]);

  const getAllLabProducts = async (labId, labProductId) => {
    try {
      const labProductsRes = await getProducts(labId);
      if (labProductsRes?.data?.labProducts?.length) {
        const tempLabProducts = labProductsRes.data.labProducts;
        setLabProducts(tempLabProducts);
        const findProduct = tempLabProducts.find(obj => obj.id == labProductId);
        if (findProduct) {
          setLabProductDetails(
            <span>
              {findProduct.productName} <br />
              {findProduct.quantityType == "Per aligner"
                ? "Unit"
                : "Total"}{" "}
              price : {findProduct.currency} {findProduct.price}
              {findProduct.quantityType == "Per aligner" ? (
                <>
                  <br /> Total Price : {findProduct.currency}{" "}
                  {findProduct.price * orderDetails.quantity}
                </>
              ) : null}
            </span>
          );
        }
      }
    } catch (error) {
      message.error("Some Error occured!");
      console.error(error);
    }
  };

  const uploadSingleFile = async (invoiceFile, order_id) => {
    // console.log("order_id", order_id)
    try {
      let formData = new FormData();

      formData.append("file", invoiceFile);
      formData.append("labId", lab.labId);
      formData.append("patientId", patientId);
      formData.append("clinicId", clinicInfo?.clinic_id);
      formData.append("model", "order_shipping");
      formData.append("uploadedBy", "lab");
      formData.append("orderId", order_id);

      const { data } = await uploadAppMediaApi(formData);
      if (data) {
        return data.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invoiceFilesUploader = async (files, order_id) => {
    const promiseArray = [];

    files.forEach((file, index) => {
      promiseArray.push(uploadSingleFile(file, order_id));
    });

    const finalResponse = await Promise.all(
      promiseArray.map(p => p.catch(e => e))
    );
  };



  const confirmDeliveryUpdate = async (val, number, arrivalDate) => {
    const hide = message.loading("Updating Delivery Status", 0);
    setLoading(true)
    const model = {
      order_id: orderDetails.patient_order_id,
      new_order_status_id: 3,
      tracker_url: val,
      trackingNumber: number,
      arrivalDate: arrivalDate
        ? dayjs(arrivalDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      invoiceAttachment: resourceFileList?.length ? true : false,
      phaseNo: orderDetails.phaseValue,
    };
    if (resourceFileList) {
      await invoiceFilesUploader(
        resourceFileList,
        orderDetails.patient_order_id
      );
    }

    try {
      const res = await updateLabOrderStatus(model);

      let update = {
        order_delivery_status_id: 2,
        order_tracking_link: val,
        tracking_no: number,
        arrival_date: arrivalDate
          ? dayjs(arrivalDate).format("YYYY-MM-DD")
          : null,
      };

      const isPhasing = Boolean(orderDetails?.phasingOption === "yes");
      if (isPhasing) {
        await updatePhaseOrderStatuses({
          updateData: update,
          phaseNo: orderDetails.phaseValue,
          lab_treatment_id: orderDetails.lab_treatment_id,
        });
      }

      message.success(res.body.msg);
      setTimeout(hide, 0);

      if (clinicInfo?.firstTimeOnboard) {
        updateClinicIntercomJourneyStatus(clinicInfo?.clinic_id, {
          firstTimeOnboardStep: "START_TREATMENT",
        });
      }
      getPatientInformation();
      getOrders();
      treatmentPlans();
    } catch (error) {
      setTimeout(hide, 0);

      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      }
    } finally {
      setRemoveInvoiceFiles([]);
      setResourceFileList([]);
      setLoading(false)
    }
  };

  const updateTrackingDetails = async (val, number, arrivalDate) => {
    // console.log("2222222")
    const hide = message.loading("Updating Delivery Status", 0);
    const model = {
      order_id: orderDetails.patient_order_id,
      trackingUrl: val,
      trackingNumber: number,
      arrivalDate: arrivalDate
        ? dayjs(arrivalDate).format("YYYY-MM-DD HH:mm:ss")
        : "",
      toDeleteShippingFiles: removeInvoiceFiles,
      invoiceAttachment: resourceFileList.length ? true : false,
    };
    const filteredList = resourceFileList.filter(file => !file.url);

    if (filteredList.length) {
      // console.log("order", orderDetails?.shippingFiles)
      // console.log("resourceFileList", resourceFileList)
      //  console.log("filteredList to upload", filteredList)
      await invoiceFilesUploader(filteredList, orderDetails.patient_order_id);
    }
    try {
      const res = await updateLabOrderTrackingStatus(model);

      const isPhasing = Boolean(orderDetails?.phasingOption === "yes");
      if (isPhasing) {
        let update = {
          // order_delivery_status_id: 2,
          order_tracking_link: val,
          tracking_no: number,
          arrival_date: arrivalDate
            ? dayjs(arrivalDate).format("YYYY-MM-DD")
            : null,
        };

        await updatePhaseOrderStatuses({
          updateData: update,
          phaseNo: orderDetails.phaseValue,
          lab_treatment_id: orderDetails.lab_treatment_id,
        });
      }
      message.success(res.data.body.msg);
      setTimeout(hide, 0);

      getOrders();
    } catch (error) {
      setTimeout(hide, 0);

      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setRemoveInvoiceFiles([]);
      setResourceFileList([]);
    }
  };

  useEffect(() => {
    if (orderDetails.order_delivery_status_id === 7) {
      setCurrentStep(2);
    }
    if (orderDetails.order_delivery_status_id === 2) {
      setCurrentStep(3);
    }
    if (orderDetails.order_delivery_status_id === 8) {
      setCurrentStep(4);
    }

    if (orderDetails.order_delivery_status_id !== 9) {
      setIsPrintingPaused(false);
    }

    if (orderDetails.order_delivery_status_id === 9) {
      setIsPrintingPaused(true);
      setCurrentStep(1);
    }

    if (orderDetails.order_delivery_status_id === 6) {
      setCurrentStep(1);
    }

    // if(orderDetails.order_delivery_status_id ===3){
    //   setCurrentStep(2);
    // }
  }, [orderDetails]);

  const confirmPrinting = async (values, printingPause = false) => {
    const hide = message.loading("Updating Status", 0);
    const model = {
      order_id: orderDetails.patient_order_id,
      new_order_status_id:
        orderDetails.order_delivery_status_id === 6
          ? 2
          : isPrintingPaused
          ? 2
          : 9,
      order_type_id: orderDetails?.order_type_id,
      estDate: values?.estDate
        ? dayjs(values?.estDate).format("YYYY-MM-DD")
        : dayjs(orderDetails?.est_date).format("YYYY-MM-DD"),
      phaseNo: orderDetails.phaseValue,
    };
    setPrintLoader(true);
    try {
      const res = await updateLabOrderStatus(model);

      let updateData = {
        order_delivery_status_id: orderDetails.order_delivery_status_id,
      };
      let order_delivery_status_id = orderDetails.order_delivery_status_id;

      if (printingPause && order_delivery_status_id === 7) {
        order_delivery_status_id = 9;

        updateData = {
          order_delivery_status_id: 9,
          aligner_printing_paused_date: dayjs().format("YYYY-MM-DD"),
        };
      }

      if (order_delivery_status_id === 6) {
        updateData = {
          order_delivery_status_id: 7,
          est_date: model.estDate,
        };
      }

      if (order_delivery_status_id === 9) {
        updateData = {
          order_delivery_status_id: 7,
          aligner_printing_start_date: dayjs().format("YYYY-MM-DD"),
        };
      }

      const isPhasing = Boolean(orderDetails?.phasingOption==="yes");
      if (isPhasing) {
        await updatePhaseOrderStatuses({
          updateData: updateData,
          phaseNo: orderDetails.phaseValue,
          lab_treatment_id: orderDetails.lab_treatment_id,
        });
      }

      message.success(res.body.msg);
      setTimeout(hide, 0);

      getOrders();

      // console.log(res, "get orders");
    } catch (error) {
      setTimeout(hide, 0);

      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setShowPrintingConfirmationModal(false);
      setPrintLoader(false);
    }
  };

  const handleEditDispatchDate = async values => {
    const model = {
      order_id: orderDetails.patient_order_id,
      estDate: values.estDate
        ? dayjs(values.estDate).format("YYYY-MM-DD")
        : null,
      labId: lab.labId,
      patientId: orderDetails.patient_id,
      clinicId: clinicInfo?.clinic_id,
    };
    const hide = message.loading("Updating Dispatch Date", 0);
    setPrintLoader(true);
    try {
      const res = await updateLabOrderDispatchStatus(model);
      if (res) {
        await getOrders();
        message.success("Order Dispatch Date Updated");
        setTimeout(hide, 0);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setShowEditDispatchModal(false);
      setPrintLoader(false);
    }
  };

  const updateShippingStatusComponent = () => {
    if (orderDetails.order_delivery_status_id === 7) {
      return (
        <Button
          style={{ display: currentStep !== 2 ? "none" : "" }}
          onClick={() => setShowDeliveryUpdateModal(true)}
          type="primary"
          shape="round"
        >
          Update Status
        </Button>
      );
    }
    if (orderDetails.order_delivery_status_id === 9) {
      return (
        <Button
          shape="round"
          style={{ display: currentStep !== 2 ? "none" : "" }}
          disabled={true}
          type="primary"
        >
          Update Status
        </Button>
      );
    }

    if (
      orderDetails &&
      (orderDetails?.order_tracking_link || orderDetails?.aligner_shipped_date)
    ) {
      return (
        <div>
          <p className="shipping-label" style={{ position: "relative" }}>
            Shipped on
          </p>

          <p style={{ fontWeight: "bolder", textAlign: "center" }}>
            {orderDetails?.aligner_shipped_date
              ? moment(orderDetails.aligner_shipped_date).format("MMM Do YYYY")
              : moment(orderDetails.progress_started_date).format(
                  "MMM Do YYYY"
                )}
          </p>
          {/* <p>Tracking Link</p> */}
          {/* shipped-url */}
          {orderDetails?.order_tracking_link?.length ? (
            <div className="tracking-url">
              <a
                href={orderDetails.order_tracking_link}
                target="_blank"
                rel="noreferrer"
              >
                <span className="tracking-link-text">
                  {orderDetails.order_tracking_link}
                </span>
                <Tracking />
              </a>
            </div>
          ) : (
            ""
          )}

          {orderDetails.tracking_no ? (
            <div>
              <p className="shipping-label" style={{ position: "relative" }}>
                Tracking Number
              </p>
              <p style={{ fontWeight: "bolder", textAlign: "center" }}>
                {orderDetails.tracking_no}
              </p>
            </div>
          ) : null}
          {orderDetails.arrival_date ? (
            <div>
              <p className="shipping-label" style={{ position: "relative" }}>
                Est. Arrival date:
              </p>
              <p style={{ fontWeight: "bolder", textAlign: "center" }}>
                {dayjs(orderDetails.arrival_date).format("MMM Do YYYY")}
              </p>
            </div>
          ) : null}
          <AppButton
            className="edit-btn"
            icon={
              <EditIcon
                style={{
                  width: "12px",
                  marginTop: "2px",
                  fill: "#fff",
                }}
              />
            }
            shape="round"
            type="primary"
            onClick={onUpdateTrackingLinkClick}
          >
            Edit Shipping Info
          </AppButton>
        </div>
      );
    } else {
      if (currentStep === 2) {
        return (
          <div>
            <p className="shipping-label" style={{ position: "relative" }}>
              Shipped on
            </p>

            <p style={{ fontWeight: "bolder", textAlign: "center" }}>
              {orderDetails?.progress_started_date &&
                moment(orderDetails.progress_started_date).format(
                  "MMM Do YYYY"
                )}
            </p>
            <AppButton
              className="edit-btn"
              icon={
                <EditIcon
                  style={{
                    width: "12px",
                    marginTop: "2px",
                    fill: "#fff",
                  }}
                />
              }
              shape="round"
              type="primary"
              onClick={onUpdateTrackingLinkClick}
            >
              Edit Shipping Info
            </AppButton>
          </div>
        );
      }
    }
  };

  const onUpdateTrackingLinkClick = () => {
    setUpdateData({
      trackingUrl: orderDetails.order_tracking_link,
      trackingNo: orderDetails.tracking_no,
      arrivalDate: orderDetails.arrival_date,
    });
    setShowDeliveryUpdateModal(true);
  };

  const deliveredStatusComponent = () => {
    if (orderDetails.aligner_received_date) {
      return (
        <div>
          <p>Received on</p>

          <p style={{ fontWeight: "bolder" }}>
            {moment(orderDetails.aligner_received_date).format("MMM Do YYYY")}
          </p>
        </div>
      );
    }
  };

  const getTotalAligners = aligner => {
    let total = 0;

    if (aligner?.num_of_lower_aligners) {
      total = aligner?.num_of_lower_aligners + aligner?.num_of_upper_aligners;
    }

    if (companyType == 4) {
      total =
        aligner?.num_of_lower_aligners > aligner?.num_of_upper_aligners
          ? aligner?.num_of_lower_aligners
          : aligner?.num_of_upper_aligners;
    }
    return total;
  };

  const renderOrderShippingAddress = () => {
    if (orderDetails?.shipping_address == "clinic") {
      const findClinicAddress = clinicInfo?.multipleAdresses?.find(
        obj => obj.id == orderDetails?.clinicAddressId
      );
      const getClinicFinalAddress = findClinicAddress
        ? makeClinicAddress(findClinicAddress)
        : null;

      return (
        <span style={{ fontWeight: "400" }}>
          <span style={{ fontWeight: "600" }}>{findClinicAddress?.label}</span>
          {getClinicFinalAddress ? " : (" + getClinicFinalAddress + ")" : ""}
        </span>
      );
    } else {
      return (
        <span style={{ fontWeight: "400" }}>
          {patientAddressRef?.current?.trim()
            ? " : " + patientAddressRef.current?.trim()
            : ""}
        </span>
      );
    }
  };

  return (
    <Card className="order-container">
      {showDeliveryUpdateModal && (
        <DeliveryUpdateModal
          title="Shipping Status"
          content={"Aligner has been shipped"}
          showModal={showDeliveryUpdateModal}
          onConfirmation={confirmDeliveryUpdate}
          setShow={setShowDeliveryUpdateModal}
          updateData={updateData}
          setUpdateData={setUpdateData}
          updateTrackingDetails={updateTrackingDetails}
          resourceFileList={resourceFileList}
          setResourceFileList={setResourceFileList}
          orderDetails={orderDetails}
          removeInvoiceFiles={removeInvoiceFiles}
          setRemoveInvoiceFiles={setRemoveInvoiceFiles}
          okLoading={loading}
        ></DeliveryUpdateModal>
      )}

      {/* {showPrintingConfirmationModal && (
        <ConfirmationModal
          okText="Confirm"
          cancelText="Cancel"
          title=""
          content={handlePrintingStatusMessage()}
          showModal={showPrintingConfirmationModal}
          onConfirmation={confirmPrinting}
          setShow={setShowPrintingConfirmationModal}
        ></ConfirmationModal>
      )} */}
      {showPrintingConfirmationModal && (
        <PrintOrderModal
          showPrintingConfirmationModal={showPrintingConfirmationModal}
          setShowPrintingConfirmationModal={setShowPrintingConfirmationModal}
          onConfirmation={confirmPrinting}
          printForm={printForm}
          orderDetails={orderDetails}
          loading={printLoader}
        />
      )}
      {showEditDispatchModal && (
        <UpdateDispatchDateModal
          showEditDispatchModal={showEditDispatchModal}
          setShowEditDispatchModal={setShowEditDispatchModal}
          onConfirmation={handleEditDispatchDate}
          printForm={printForm}
          orderDetails={orderDetails}
          loading={printLoader}
        />
      )}

      <OrderDetail
        orderDetails={orderDetails}
        renderOrderShippingAddress={renderOrderShippingAddress}
      />

      <div className="steps">
        <Steps
          labelPlacement="vertical"
          current={currentStep}
          status={orderDetails.order_remake_reason_id ? "error" : ""}
          style={{
            width: "100%",
            padding: "0 1rem",
          }}
        >
          <Step
            style={{ margin: "2rem 0 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[0].title}
            title={steps[0].title}
            subTitle={"Order created date"}
            description={
              <>
                <p className="created-date">
                  {moment(orderDetails.product_order_date).format(
                    "MMM Do YYYY"
                  )}
                </p>
                {orderDetails.orderScanFiles?.length > 0 && (
                  <>
                    <label style={{ marginLeft: "35px", fontWeight: "600" }}>
                      Scan Files
                    </label>
                    <Button
                      type="primary"
                      shape="round"
                      style={{ width: "120px", margin: "4px 15px" }}
                      onClick={() => setShowScanFileModal(true)}
                    >
                      View Files
                    </Button>
                  </>
                )}
                {showScanFileModal && (
                  <OrderScansModal
                    uploadedFiles={orderDetails.orderScanFiles}
                    showHistoryModal={showScanFileModal}
                    setShowHistoryModal={setShowScanFileModal}
                  />
                )}
              </>
            }
          />
          <Step
            style={{ margin: "2rem 0 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[1].title}
            title={steps[1].title}
            subTitle={
              <PrintStage
                orderDetails={orderDetails}
                setShowPrintingConfirmationModal={
                  setShowPrintingConfirmationModal
                }
                setShowEditDispatchModal={setShowEditDispatchModal}
              />
            }
            // description={item.description}
          />
          <Step
            style={{ margin: "2rem 0 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[2].title}
            title={steps[2].title}
            // subTitle={updateShippingStatusComponent()}
            description={updateShippingStatusComponent()}
          />
          <Step
            style={{ margin: "2rem 0 0 0", padding: "0 0.5rem 0 0" }}
            key={steps[3].title}
            title={steps[3].title}
            subTitle={deliveredStatusComponent()}
            // subTitle={item.subTitle}
            // description={item.description}
          />
        </Steps>
      </div>
    </Card>
  );
};

export default PatientAlignerOrderStepsComponent;
