import React from "react";
import { Row, Col } from "antd";
import { companyType } from "colors-config";
import CustomScanUpload from "../ScanUpload/CustomScanUpload";

const ScansView = ({
  uploadedFiles,
  treatmentType,
  handleFileUpload,
  handleRemove,
  setFileLoader,
  clinicId,
  patientId,
  setUploadedFiles,
  setScansInfo,
  uploadConfirm,
}) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        {Object.entries(uploadedFiles).map(([scanKey, fileData]) => (
          <Col key={scanKey} lg={4} md={12} sm={24}>
            <CustomScanUpload
              scanType={scanKey} // e.g., "UpperArch", "LowerArch"
              scanLabel={scanKey}
              required={
                // Note: upper and lower arch scan files are required for brush bar
                companyType === 8 &&
                (scanKey === "UpperArch" || scanKey === "LowerArch")
              }
              treatmentName={treatmentType}
              uploadedFile={fileData} // fileData is the data for that specific scan type or null
              onFileUpload={handleFileUpload}
              onFileRemove={handleRemove}
              prescriptionUpload={true}
              setFileLoader={setFileLoader}
              clinicId={clinicId}
              patientId={patientId}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              setScansInfo={setScansInfo}
              uploadConfirm={uploadConfirm}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ScansView;
