import AppTable from "components/shared/AppTable/AppTable";
import { ReactComponent as Invites } from "assets/images/Invites.svg";
import styles from "../../labPartners.module.scss";
import { calculateErrorCount, getSuccessButtonText } from "../../utils";

const InvitesTable = ({
  columns,
  tableData,
  loading,
  onRow,
  pagination,
  fileList,
}) => {
  return (
    <div className={styles.invitesContainer}>
      <div className={styles.invitesHeader}>
        <div className={styles.invites}>
          <Invites />
          <b>{fileList?.name}</b>
        </div>
        <div className={styles.fileTags}>
          <div className={`${styles.fileTag} ${styles.fileTagError}`}>
            {calculateErrorCount(tableData)} Errors
          </div>
          <div className={`${styles.fileTag} ${styles.fileTagSuccess}`}>
            {getSuccessButtonText(tableData)} Successful
          </div>
        </div>
      </div>
      <AppTable
        columns={columns}
        tableData={tableData}
        loading={loading}
        onRow={onRow}
        pagination={pagination}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default InvitesTable;
