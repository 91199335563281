import React from "react";
import { Row, Card, Col } from "antd";
import {removeUnderscore} from "../utils"

const Periodontium = ({ bioType, recession }) => {
  return (
    <div style={{ marginTop: "30px" }}>
      <Card className="prescription-card" hoverable>
        <h1 className="form-heading">Periodontium</h1>
        <Row gutter={[8, 8]}>
          <Col lg={6} md={12} sm={24}>
            <h2 className="caption">Biotype</h2>
            <p className="content">{removeUnderscore(bioType)}</p>
          </Col>
          <Col lg={12} md={12} sm={24}>
            <h2 className="caption">Recession (Miller's Class)</h2>
            <p className="content">{recession === "" ? "--" : recession}</p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Periodontium;
