import api from "./api";

export const getClinicLabs = (params) => {
  return api({
    method: "GET",
    url: "/clinic/patientlist/patient/orders/placeOrder/getPartneredLabs",
    params,
  }).then((res) => res.data);
};

export const getAssociatedClinics = ({ status, labId, limit, page, search }) => {
  return api({
    method: "GET",
    url: "/lab/lab/clinicList",
    params: {
      status,
      labId,
      limit,
      page,
      search,
    },
  });
};

export const getTreatmentTypes = (labId) => {
  return api({
    method: "GET",
    url: `/clinic/prescription/smilelign/treatmentTypes/${labId}`,
  });
};

export const getWhiteLabelling = (labId) => {
  return api({
    method: "GET",
    url: `lab/clinic/get/${labId}`,
  });
};

export const saveWhiteLabelling = (data) => {
  return api({
    method: "POST",
    url: "/lab/clinic/settings/save",
    data: data,
  }).then((obj) => obj.data);
};

export const saveWhiteLabelImage = (data, labId) => {
  return api({
    method: "POST",
    url: `/lab/clinic/resource/add/${labId}`,
    data: data,
  }).then((obj) => obj.data);
};

export const uploadWhiteLabelFileAPI = (data, labId) => {
  return api({
    method: "POST",
    url: `/lab/clinic/resource/add/${labId}`,
    data,
  }).then((obj) => obj.data);
};

export const confirmWhiteLabelFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/upload/confirm",
    data,
  });
};

export const getClinicWhiteLabelList = (labId) => {
  return api({
    method: "GET",
    url: `lab/clinic/getAllClinicList/${labId}`,
  });
};

export const saveClinicWhiteLabelList = (data) => {
  return api({
    method: "POST",
    url: `lab/clinic/updateClinicWhitelabeling`,
    data,
  }).then((obj) => obj.data);
};

export const getClinicProducts = (clinicId) => {
  return api({
    method: "GET",
    url: "/clinic/getServicesAndResources",
    params: {
      clinicId,
    },
  }).then((obj) => obj.data);
};

export const getClinicInfoService = (clinic_id) => {
  return api({
    method: "GET",
    url: "lab/clinic/details",
    params: {
      clinic_id,
    },
  });
};


export const createClinicLabOrderAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patientlist/patient/orders/placeOrder`,
    data: data,
  }).then((res) => res.data);
};

export const getPatientResourceUploadStageApi = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patient/getPatientResourceUploadStage`,
    params,
  }).then((res) => res.data);
};
export const updateClinicIntercomJourneyStatus = (clinicId, data) => {
  return api({
    method: "POST",
    url: `/clinic/intercom/updateClinicIntercomJourney/${clinicId}`,
    data
  })
};
