import dayjs from "dayjs";
import { Tooltip } from "antd";

export const checkLastActiveStatus = () => {
  const lastActiveTime = localStorage.getItem("lastActiveTime");

  if (lastActiveTime) {
    const lastActiveTimeDate = dayjs(lastActiveTime);
    const lastActiveDiff = dayjs().diff(lastActiveTimeDate, "minute");
    if (lastActiveDiff > 60) {
      return true;
    }
  }
  return false;
};

export const renderTreatmentTypes = (companyType, createdAt) => {
  let returnValue = true;
  let oldPrescription = false;
  if (companyType === 1) {
    if (createdAt) {
      const comparisonDate = '2024-11-06';
      const parsedDateToCheck = dayjs(createdAt);
      const parsedComparisonDate = dayjs(comparisonDate);
      const isBeforeOrSame =
        parsedDateToCheck.isBefore(parsedComparisonDate, "day") ||
        parsedDateToCheck.isSame(parsedComparisonDate, "day");
      if (isBeforeOrSame) {
        returnValue = false;
        oldPrescription = true;
      } else {
        returnValue = true;
      }
    } else {
      returnValue = true;
    }
  }
  if (companyType === 5) {
    returnValue = true;
  }

  if (companyType === 6 || companyType === 7 || companyType === 8) {
    if (createdAt) {
      const comparisonDate = "2024-07-02";
      const parsedDateToCheck = dayjs(createdAt);
      const parsedComparisonDate = dayjs(comparisonDate);
      const isBeforeOrSame =
        parsedDateToCheck.isBefore(parsedComparisonDate, "day") ||
        parsedDateToCheck.isSame(parsedComparisonDate, "day");
      if (isBeforeOrSame) {
        returnValue = false;
        oldPrescription = true;
      } else {
        returnValue = true;
      }
    } else {
      returnValue = true;
    }
  }

  return { show: returnValue, old: oldPrescription };
};

export const columnTitleElement = (value) => {
  return (
    <Tooltip title={value}>
      <p className="appTable-column-title">{value}</p>
    </Tooltip>
  );
};

export const noop = () => {};


export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const disableFutureDates = (current) =>{
  return current && current >= dayjs().endOf("day");
}

export const disablePastDates = (current) => {
  return current && current < dayjs().startOf("day");
};

export const capitalizeWord = (str) => {
  if (str) {
    return str[0].toUpperCase() + str.slice(1);
  }
  return str;
}

export const validURL=(str)=> {
   // const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    //   '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  const regex = /^https?:\/\/[^\s/$.?#].[^\s]*$/;
return regex.test(str);
}
export const hexToHsl = (hex) => {
  // Remove the '#' from the hex string
  hex = hex.replace("#", "");

  // Convert the hex string to RGB values
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the min and max values of RGB
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate lightness
  let h, s, l = (max + min) / 2;

  if (max === min) {
    // Achromatic (no hue)
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = 0;
    }

    h /= 6;
  }

  return { h: h * 360, s: s * 100, l: l * 100 };
};

export const hslToHex = (h, s, l) => {
  // Convert HSL to RGB
  h /= 360;
  s /= 100;
  l /= 100;

  let r, g, b;

  if (s === 0) {
    // Achromatic (no hue)
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  // Convert RGB to hex
  const toHex = (x) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
