
import { Row, Col, Tooltip } from "antd";
import { ReactComponent as CalendarIcon } from "assets/images/calendar_icon.svg";
import { ReactComponent as SmartphoneIcon } from "assets/images/smartphone_icon.svg";
import { ReactComponent as AddressIcon } from "assets/images/address_icon.svg";
import { ReactComponent as EmailIcon } from "assets/images/envelope.svg";
import dayjs from "dayjs";

import { UserOutlined } from "@ant-design/icons";
import { getDisplayGender } from "utils/Patient";


const PatientInfo = ({ patientInfo, primaryColor, patientAddress }) => {

  return (
    <div className="patient-info">
      <Row gutter={[32, 16]}>
        {/* Date of Birth */}
        <Col lg={4} md={6} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">DOB</div>
            <div className="content">
              <CalendarIcon stroke={primaryColor} />
              <span style={{ marginLeft: "8px" }}>
                {patientInfo?.patient_date_of_birth
                  ? dayjs(patientInfo.patient_date_of_birth).format(
                      "DD-MMM-YYYY"
                    )
                  : "-"}
              </span>
            </div>
          </div>
        </Col>

        {/* Gender */}
        <Col lg={4} md={6} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">Gender</div>
            <div className="content">
              <UserOutlined style={{ color: primaryColor }} />
              <span style={{ marginLeft: "8px" }}>
              <Tooltip
                          title={getDisplayGender(patientInfo)}
                          placement="topLeft"
                        >
                          {getDisplayGender(patientInfo)}
                        </Tooltip>
              </span>
            </div>
          </div>
        </Col>

        {/* Mobile Number */}
        <Col lg={4} md={8} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">Mobile No</div>
            <div className="content">
              <SmartphoneIcon stroke={primaryColor} />
              <span style={{ marginLeft: "8px" }}>
                {`${patientInfo?.patient_country_code_name || ""}-${
                  patientInfo?.patient_phone_number || "-"
                }`}
              </span>
            </div>
          </div>
        </Col>

        {/* Email */}
        <Col lg={5} md={6} sm={12} xs={24} className="border-right">
          <div className="info-card">
            <div className="caption">Email</div>
            <div className="content">
              <EmailIcon
                style={{ height: "20px", width: "20px" }}
                fill={primaryColor}
              />
              <span style={{ marginLeft: "8px" }}>
                {patientInfo?.patient_email || "-"}
              </span>
            </div>
          </div>
        </Col>

        {/* Address */}
        <Col lg={6} md={6} sm={12} xs={24}>
          <div className="info-card">
            <div className="caption">Address</div>
            <div className="content">
              <AddressIcon fill={primaryColor} />
              <span style={{ marginLeft: "8px" }}>{patientAddress || "--"}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PatientInfo;
