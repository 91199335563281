import classNames from "classnames";
import styles from "../treatments.module.scss";
import EmptyTableDesign from "components/shared/EmptyTable/EmptyTable";
import AppButton from "components/shared/AppButton/AppButton";
import {  PlusOutlined } from "@ant-design/icons";

const EmptyTreatment = ({handleShow}) => {
  return (
    <div className={classNames(styles["empty-container"])}>
      <EmptyTableDesign />
      <AppButton
        icon={<PlusOutlined />}
        type="primary"
        onClick={handleShow}
        data-testid="add-patient-modal-button"
      >
        Manage
      </AppButton>
    </div>
  );
};

export default EmptyTreatment;
