import { companyType } from "colors-config";

const SmilePrescriptionTreatmentTypes = ({ prescriptionPDFObj,treatmentOptions }) => {
  const renderTreatmentName = (smilelignTreatmentTypeId) => {
    const findTreatment = treatmentOptions.find(obj => obj.id == smilelignTreatmentTypeId);
    if (findTreatment) return findTreatment.treatmentName;
    return '';
  }

  const renderTreatmentTypes = (smilelignTreatmentTypeId,smilelignArches) => {
    return (<div
      className="prescriptionPDF-SmileTreat"
      style={{ paddingLeft: "20px" }}
    >
      <div>
        <div className="border-right">
          <label className="treatment-label">Treatment Type</label>
          <p className="treatment-info">
            {" "}
            {(smilelignTreatmentTypeId && treatmentOptions.length)
              ? renderTreatmentName(smilelignTreatmentTypeId)
              : "--"}
          </p>
        </div>
      </div>
      <div>
        <div className="border-right">
          <label className="treatment-label">Retainer Arches</label>
          <p className="treatment-info">
            {" "}
            {smilelignArches
              ? smilelignArches
              : "--"}
          </p>
        </div>
      </div>
    </div>)
  }

  return (
    <div className="prescriptionPDF-card">
      <h2> Service Type</h2>
      {companyType !== 1 ?
        renderTreatmentTypes(prescriptionPDFObj?.smilelignTreatmentTypeId, prescriptionPDFObj?.smilelignArches)
        : prescriptionPDFObj?.sgTreatmentTypes?.map((obj, index) => {
          return <span key={index}>{renderTreatmentTypes(obj.smilelignTreatmentTypeId, obj.smilelignArches)}</span>
        })
      }

      {companyType === 5 ?
        <div
          className="prescriptionPDF-SmileTreat"
          style={{ paddingLeft: "20px" }}
        >
          <div>
            <div className="border-right">
              <label className="treatment-label">Phasing</label>
              <p className="treatment-info">
                {" "}
                {prescriptionPDFObj.phasing ? prescriptionPDFObj.phasing : "--"}
              </p>
            </div>
          </div>
          <div>
            <div className="border-right">
              <label className="treatment-label">Phasing Details</label>
              <p className="treatment-info">
                {prescriptionPDFObj.prescriptionPhasing
                  ? prescriptionPDFObj.prescriptionPhasing
                  : "--"}
              </p>
            </div>
          </div>
        </div> : null
      }
    </div>
  );
};

export default SmilePrescriptionTreatmentTypes;
