import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Menu, Avatar, Dropdown, Button, Row, Col, Typography, Badge } from "antd";
import { BellOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import LogoutConfirmationModal from "../shared/modals/logoutConfirmationModal";

import { connect } from "react-redux";
import { logout } from "../../services/redux/actions/authActions";
import SearchBar from "components/shared/SearchBar/SearchBar";
import "../../assets/styles/components/headerComponent.scss";
import { Link, useHistory } from "react-router-dom";
import { labApiTasks } from "../../models/lab";
import AddPatientModal from "./AddPatientModal/AddPatientModal";
import { headerLogo, logoWidth, logoMarginLeft } from "colors-config";
import AppButton from "components/shared/AppButton/AppButton";
import { subUserSelector } from "services/redux/selectors/lab.selectors";
const { Text } = Typography;

function HeaderComponent({ logout, lab }) {
  const history = useHistory();
  const userInfo = useSelector(subUserSelector);

  const [showLogoutConfirmationModel, setShowLogoutConfirmationModel] = useState(false);
  const [showAddPatientsModal, setShowAddPatientsModal] = useState(false);
  const [scanUrls, setScanUrls] = useState([]);

  const handleMenuClick = (event) => {
    console.log("test", event);
    if (+event.key === 3) {
      setShowLogoutConfirmationModel(true);
    }
    if (+event.key === 2) {
      history.push("/changePassword");
    }
  };

  const signOut = async () => {
    const deviceToken = localStorage.getItem("device_token");
    try {
      labApiTasks
        .remove("deviceToken/delete", {
          user_id: lab?.labId,
          device_token: deviceToken,
        })
        .then(() => {
          console.log("Token reomeved or updated!");
          window.Intercom("shutdown");
          localStorage.removeItem("device_token");
        });
    } catch (error) {
      console.log("error while removing token", error);
    }
    logout();
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      // selectedKeys={[current]}
      style={{
        // marginTop: "0.5rem",
        marginTop: "1.2rem",
        width: "10rem",
        paddingTop: "0.5rem",
      }}
      mode="horizontal"
      // selectable={false}
    >
      <Menu.Item key={2}>Change Password</Menu.Item>
      <Menu.Item key={3}>Logout</Menu.Item>
    </Menu>
  );

  const onSelectCallback = (data) => {
    if(data.type==='patient'){
      history.push(`/lab/patient/${data.id}`);
    }
    else{
      history.push(`/lab/partners?email=${data.email}`);
    }
    window.location.reload();
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "4rem",
        // position: "relative",
        boxShadow: "3px 6px 8px rgba(0, 0, 0, 0.03)",
        // position: "fixed" /* Fixed Sidebar (stay in place on scroll) */,
        // zIndex: "1" /* Stay on top */,
        // width: "100%",
        position: "fixed",
        width: "100%",
        left: 0,
        top: 0,
        right: 0,
        paddingBottom: "2rem",
        zIndex: 1000,
      }}
    >
      {showAddPatientsModal && (
        <AddPatientModal
          showAddPatientsModal={showAddPatientsModal}
          labName={lab.labName}
          labId={lab.labId}
          setShowAddPatientsModal={setShowAddPatientsModal}
          setScanUrls={setScanUrls}
        />
      )}

      {showLogoutConfirmationModel && (
        <LogoutConfirmationModal
          title="Logout"
          showModal={showLogoutConfirmationModel}
          onConfirmation={signOut}
          setShow={setShowLogoutConfirmationModel}
        ></LogoutConfirmationModal>
      )}
      <Row justify="space-between">
        <Col md={6}>
          <div style={{ textAlign: "left" }}>
            <Link to={"/"}>
              <img
                style={{
                  width: logoWidth,
                  marginLeft: logoMarginLeft,
                }}
                className={`header-logo`}
                src={headerLogo}
                alt="logo"
              />
            </Link>
          </div>
        </Col>

        <Col md={18}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {lab?.labId && !lab?.labName && !lab?.isFirstLogin ? null : (
              <div style={{ width: "280px", marginRight: "10px" }}>
                <SearchBar labId={lab.labId} onSelectCallback={onSelectCallback} />
              </div>
            )}

            {lab?.labId && !lab?.labName && !lab?.isFirstLogin ? null : (
              <AppButton
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => setShowAddPatientsModal(true)}
                data-testid="add-patient-modal-button"
              >
                New Patient
              </AppButton>
            )}

            <Badge
              count={lab.notificationCount}
              // style={{ marginRight: "15px",marginLeft:"10px" }}
              style={{ right: 15 }}
            >
              <BellOutlined
                style={{
                  marginRight: "1rem",
                  fontSize: "25px",
                  color: "#77849E",
                  marginLeft: "15px",
                }}
                onClick={() => {
                  history.push("/lab/notifications");
                }}
              />
            </Badge>

            <div
              style={{
                borderLeft: "2px solid #EFEFF9",
                display: "inline-block",
                padding: "14px",
              }}
            >
              <Dropdown overlay={menu} trigger={["hover"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                  href="http://localhost"
                >
                  <Avatar size={39} icon={<UserOutlined />} style={{ marginRight: "0.5rem" }} />
                  <Text style={{ fontWeight: "lighter", textTransform: "capitalize" }}>
                    {userInfo.firstName ? userInfo.firstName : ""}
                  </Text>
                </a>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lab: state.lab,
  // error: state.error,
});

export default connect(mapStateToProps, { logout })(HeaderComponent);
