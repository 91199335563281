import { useState } from "react";
import AppModal from "components/shared/AppModal/AppModal";
import { Select } from "antd";
import TreatmentCard from "../TreatmentModal/TreatmentCard";

const TreatmentModal = ({ showTreatmentModal, setShowTreatmentModal }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const resendModalCloseHandler = () => setShowTreatmentModal(false);


  const onOk = () => {
    console.log("okkkk");
  };

  const handleChange = value => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      {showTreatmentModal && (
        <AppModal
          onOk={onOk}
          okText="Send Email"
          width={960}
          open={showTreatmentModal}
          onCancel={resendModalCloseHandler}
          //   confirmLoading={confirmLoading}
          title="Choose Treatment"
          footer={null}
        >
        <p>Filter by</p>
          <Select
            placeholder="Choose Treatment"
            size="large"
            style={{
              width: 300,
            }}
            onChange={handleChange}
            options={[
              {
                value: "1",
                label: "Orthodontic Treatments",
              },
              {
                value: "2",
                label: "Lucy",
              },
              {
                value: "3",
                label: "yiminghe",
              },
            ]}
          />
          <TreatmentCard/>

        </AppModal>
      )}
    </div>
  );
};

export default TreatmentModal;
