import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as Circle } from "assets/images/circle.svg";
import { useState } from "react";
import { primaryColor } from "colors-config";
import {
  getAlignerBorder,
  getAlignerClassName,
  getAlignerBackground,
  getAlignerNumberBackground,
} from "utils/ipr";

const AttachmentAligners = ({
  alignersArray,
  setUpperAttachments,
  setLowerAttachments,
  alignerType,
  alignersLabel,
  setAttachmentIndex,
  attachmentIndex,
  teethStructure,
  setTeethStructure,
  updateAlignersErrorsArray,
}) => {
  const [deleteData, setDeleteData] = useState();

  const handleOk = (e, uniqueId, alignerType) => {
    handleAlignerDelete(e, uniqueId, alignerType);
  };
  const handleCancel = () => {};

  function getTeethValuesWithTrue(array) {
    const teethValues = [];
    array.forEach((item) => {
      if (item.alignerValues) {
        item.alignerValues.forEach((attachment) => {
          if (attachment.value === true && attachment.teeth !== null) {
            teethValues.push(attachment.teeth);
          }
        });
      }
    });
    return teethValues;
  }

  function getTeethValuesWithPressureTrue(array) {
    const teethValues = [];
    array.forEach((item) => {
      if (item.alignerValues) {
        item.alignerValues.forEach((attachment) => {
          if (attachment.pressureLine === "yes" && attachment.teeth !== null) {
            teethValues.push(attachment.teeth);
          }
        });
      }
    });
    return teethValues;
  }

  const handleAlignerDelete = (e, id, type) => {
    console.log('handleAlignerDeletehandleAlignerDelete')
    let aligners;
    if (type === "upper") {
      aligners = [...alignersArray];
    } else {
      aligners = [...alignersArray];
    }

    let filteredAligners = aligners.filter((item) => item.uniqueId !== id);
    let ToothAligners = aligners.filter((item) => item.uniqueId === id);

    const previousTooths = getTeethValuesWithTrue(ToothAligners);
    const pressureTooths = getTeethValuesWithPressureTrue(ToothAligners);

    if (type === "upper") {
      filteredAligners = filteredAligners.reverse().map((obj, index) => {
        obj.index = index + 1;
        return obj;
      });
      setUpperAttachments(filteredAligners.reverse());
      updateAlignersErrorsArray("upperAlignersNo");
    } else {
      setLowerAttachments(filteredAligners);
      updateAlignersErrorsArray("lowerAlignersNo");
    }

    if (previousTooths.length) {
      const structure = [...teethStructure];

      const updatedStructure = structure.map((Item) => {
        const matchingTooth = previousTooths.find((tooth) => tooth == Item.id);
        return {
          ...Item,
          selected: matchingTooth ? false : Item.selected,
          pressurePoint: matchingTooth ? false : Item.pressurePoint,
        };
      });
      setTeethStructure(updatedStructure);
    }
    e.stopPropagation();
  };

  const handleAlignerActive = (id, index, type) => {
    let aligners;
    if (type === "upper") {
      aligners = [...alignersArray];
    } else {
      aligners = [...alignersArray];
    }
    const updatedAligner = aligners.map((aligner) => {
      if (aligner.uniqueId === id) {
        return {
          ...aligner,
          selected: true,
        };
      }
      return { ...aligner, selected: false };
    });
    if (type === "upper") {
      setUpperAttachments(updatedAligner);
      setLowerAttachments((prev) =>
        prev.map((aligner) => {
          return { ...aligner, selected: false };
        })
      );
    } else {
      setLowerAttachments(updatedAligner);
      setUpperAttachments((prev) =>
        prev.map((aligner) => {
          return { ...aligner, selected: false };
        })
      );
    }
    setAttachmentIndex({
      type,
      activeAligner: id,
      value: index,
    });
  };

  const checkSessionNo = (aligner_count) => {
    const findSessionNo = alignersLabel.find((obj) => obj.aligner_count == aligner_count);
    if (findSessionNo) {
      return findSessionNo.session;
    }
  };

  return (
    <div className="aligners-container">
      {alignersArray.map((upperAligner, upperIndex) => {
        return (
          <div
            key={upperIndex}
            className={upperAligner.selected ? "selected-aligner aligner-rows" : "aligner-rows"}
            onClick={() => handleAlignerActive(upperAligner.uniqueId, upperIndex, alignerType)}
          >
            <div className="aligner-info attachment-info">
              <div>
                <h2>Session {checkSessionNo(upperAligner.aligner_count)}</h2>
                <p>Before  Aligner</p>
              </div>
              <div
                style={{
                  background: `${getAlignerNumberBackground(upperAligner.aligner_count)}`,
                }}
                className={`aligner-num`}
              >
                {upperAligner.aligner_count}
              </div>
            </div>
            <div className={`aligner-row attachment-row`}>
              {upperAligner.alignerValues.map((aligner, alignerIndex) => {
                return (
                  <div
                    style={{
                      border: `1px solid ${getAlignerBorder(aligner, upperAligner)} `,
                      background: `${getAlignerBackground(aligner, upperAligner)} `,
                    }}
                    className={`
                  ${getAlignerClassName(
                    aligner,
                    upperAligner,
                    alignerType,
                    upperIndex,
                    alignersArray
                  )}
              `}
                    key={alignerIndex}
                  >
                    <Circle />
                  </div>
                );
              })}
            </div>

            <Popconfirm
              title="Delete"
              description="Are you sure to delete this session?"
              okText="Yes"
              cancelText="No"
              onConfirm={(e) => handleOk(e, upperAligner.uniqueId, alignerType)}
              onCancel={handleCancel}
              //  open={open}
              okButtonProps={{
                style: {
                  width: "7rem",
                  background: primaryColor,
                  borderColor: primaryColor,
                  borderRadius: "30px",
                  color: "#fff",
                  height: "32px",
                  padding: "4px 15px",
                  marginTop: "10px",
                },
              }}
              cancelButtonProps={{
                style: {
                  width: "7rem",
                  borderRadius: "30px",
                  height: "32px",
                  padding: "4px 15px",
                  marginTop: "10px",
                },
              }}
            >
              <Button
                className="del-btn"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  setDeleteData({
                    e,
                    uniqueId: upperAligner.uniqueId,
                    alignerType,
                  });
                }}
              />
            </Popconfirm>

            {/* <Button
              onClick={(e) => {
                handleAlignerDelete();
              }}
              className="del-btn"
              icon={<DeleteOutlined />}
            /> */}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentAligners;
