import { useState } from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import UploadedFile from "./UploadedFile";
import Uploader from "./Uploader";
import css from "../styles.module.scss";
import { v4 as uuidv4 } from "uuid";
import { convertObjToGLB, getUploadSignedUrl, uploadService } from "../services";



const FileBox = ({
  className,
  title,
  numberOfAligners,
  uploadedImages,
  setUploadedImages,
  boxName,
  draftId,
  patientId,
  fileType = "upper",
  setGeneratedLink,
  handleCustomRequest,
  uploaders,
  setCheckFilename
}) => {


  const handleUploadChange = index => info => {
    const fileName = info.file.name.toLowerCase();
    const numberMatch = fileName.match(/\d+/); // Matches one or more digits
    const fileNumber = numberMatch ? parseInt(numberMatch[0], 10) : null;

    const expectedNumber = index;

    if(fileType === 'upper' && (fileNumber !== expectedNumber || !fileName.includes('upper'))){
      setCheckFilename(prev => ({ ...prev, [index] : false}))
    }
    if(fileType === 'upper' && (fileNumber === expectedNumber && fileName.includes('upper'))){
      setCheckFilename(prev => ({ ...prev, [index] : true}))
    }
    if(fileType === 'lower' && (fileNumber !== expectedNumber || !fileName.includes('lower'))){
      setCheckFilename(prev => ({ ...prev, [index] : false}))
    }
    if(fileType === 'lower' && (fileNumber === expectedNumber && fileName.includes('lower'))){
      setCheckFilename(prev => ({ ...prev, [index] : true}))
    }
    if (info.file.type !== "model/obj" && !info.file.name.includes(".obj")) {
      return message.error("Unsupported file format uploaded, please check and retry");
    }

  };

  // const handleCustomRequest = async (
  //   { file, onProgress, onSuccess, onError },
  //   index
  // ) => {
  //   if (file.type !== "model/obj" && !file.name.includes(".obj")) {
  //     return;
  //   }
  //   const nameSplitted = file.name.split(".");

  //   const extension = nameSplitted[nameSplitted.length - 1];

  //   try {
  //     setUploaders(prev => ({
  //       ...prev,
  //       [index]: true,
  //     }));
  //     const fileUniqueId = uuidv4();
  //     const fileName = `${fileUniqueId}_${fileType}-${index}.${extension}`;
  //     const preSignedRequestBody = {
  //       patientId: patientId,
  //       draftUniqueId: draftId,
  //       fileName: fileName,
  //       fileType: file?.type || "model/obj",
  //     };
  //     const preSignedResponse = await getUploadSignedUrl(preSignedRequestBody);

  //     await uploadService(
  //       preSignedResponse.data.data.signedUrl,
  //       file,
  //       onProgress
  //     );

  //     convertObjToGLB({
  //       patientId: preSignedRequestBody.patientId,
  //       draftUniqueId: preSignedRequestBody.draftUniqueId,
  //       fileName: preSignedRequestBody.fileName,
  //     }).then(()=>{
  //       setUploadedImages(prev => {
  //         const updated = [...prev];
  //         updated[index][0].converted = true;
  //         return updated;
  //       });
        
  //     })

  //     message.success(`${fileType === "upper" ? "Upper" : "Lower"} Aligner ${index} uploaded successfully`);
  //     console.log("file",file);
  //     file.newType='model/gltf-binary'
  //     file.newName = `${fileUniqueId}_${fileType}-${index}.glb`
  //     file.isFilenameVaild = checkFilename[index];
  //     file.fileHash = fileUniqueId;
  //     file.converted = false;
  //     setUploadedImages(prev => {
  //       const updated = [...prev];
  //       updated[index] = [file];
  //       return updated;
  //     });
  //     onSuccess("Upload successful");
  //   } catch (error) {
  //     console.log("error", error);
      
  //     message.error(`${file.name} does not uploaded successfully`);
  //     onError("upload failed");
  //   } finally {
  //     setUploaders(prev => ({
  //       ...prev,
  //       [index]: false,
  //     }));
  //   }
  // };

  const onDelete = index => {
    setUploadedImages(prev => {
      const updated = [...prev];
      updated[index] = [];
      return updated;
    });
    setGeneratedLink("")
  };

  return (
    <div className={className}>
      <div>{title}</div>
      {Array.from({ length: (numberOfAligners + 1) }).map((_, index) => {
        return (
          <div key={index} className={css["uploader-wrapper"]}>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Uploader
                uploading={uploaders[index]}
                accept=".obj"
                customRequest={fileData => handleCustomRequest(fileData, index, fileType)}
                maxCount={2}
                onChange={handleUploadChange(index)}
                fileList={uploadedImages[index] || []}
                itemRender={() => null}
                CustomRender={UploadedFile}
                boxName={boxName}
                index={index}
                onDelete={() => onDelete(index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const FileUploader = ({
  setLowerImages,
  setUpperImages,
  upperImages,
  lowerImages,
  upperAligner,
  lowerAligner,
  draftId,
  patientId,
  setGeneratedLink,
  handleCustomRequest,
  setCheckLowerFilename,
  setCheckUpperFilename,
  upperUploaders,
  lowerUploaders
}) => {
  const commonProps = {
    draftId,
    patientId,
  };
  return (
    <div className={css["box-container"]}>
      <FileBox
        {...commonProps}
        setUploadedImages={setUpperImages}
        uploadedImages={upperImages}
        className={css["left-box"]}
        numberOfAligners={upperAligner}
        title="Upper Aligners"
        boxName="Upper"
        fileType="upper"
        setGeneratedLink={setGeneratedLink}
        handleCustomRequest={handleCustomRequest}
        setCheckFilename={setCheckUpperFilename}
        uploaders={upperUploaders}
      />
      <FileBox
        {...commonProps}
        setUploadedImages={setLowerImages}
        uploadedImages={lowerImages}
        numberOfAligners={lowerAligner}
        className={css["right-box"]}
        title="Lower Aligners"
        boxName="Lower"
        fileType="lower"
        setGeneratedLink={setGeneratedLink}
        handleCustomRequest={handleCustomRequest}
        setCheckFilename={setCheckLowerFilename}
        uploaders={lowerUploaders}
      />
    </div>
  );
};

FileUploader.propTypes = {
  setLowerImages: PropTypes.func.isRequired,
  setUpperImages: PropTypes.func.isRequired,
  upperImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  lowerImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  upperAligner: PropTypes.number.isRequired,
  lowerAligner: PropTypes.number.isRequired,
  setGeneratedLink: PropTypes.func.isRequired,
};

export default FileUploader;
