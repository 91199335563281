import PropTypes from "prop-types";
import { Menu, Dropdown } from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons";
import { TABLE_ACTION_TYPES, STAFF_STATUS } from "../constants";
import { LAB_USER_ROLES } from "constants";


const Actions = ({ row, staffActionHandler }) => {
  const renderDropdownMenu = (currentRow) => {
    if (currentRow.status === STAFF_STATUS.ACTIVE) {
      return (
        <Menu.Item key={TABLE_ACTION_TYPES.RESET_PASSWORD} icon={<SendOutlined />}>
          Reset Password Email
        </Menu.Item>
      );
    }

    return (
      <>
        <Menu.Item key={TABLE_ACTION_TYPES.RESEND_INVITE} icon={<EditOutlined />}>
          Resend Invite
        </Menu.Item>
        <Menu.Item key={TABLE_ACTION_TYPES.DELETE_INVITE} icon={<DeleteOutlined />}>
          Delete Invite
        </Menu.Item>
      </>
    );
  };

  if (row.role === LAB_USER_ROLES.SUPER_ADMIN) {
    return null;
  }

  return (
    <Dropdown
      overlay={
        <Menu onClick={(menuOptions) => staffActionHandler(menuOptions, row)}>
          {renderDropdownMenu(row)}
        </Menu>
      }
      trigger={["click"]}
    >
      <MoreOutlined style={{ cursor: "pointer" }} />
    </Dropdown>
  );
};

Actions.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    role: PropTypes.oneOf([LAB_USER_ROLES.SUPER_ADMIN, LAB_USER_ROLES.ADMIN, LAB_USER_ROLES.USER])
      .isRequired,
  }).isRequired,
  staffActionHandler: PropTypes.func.isRequired,
};

export default Actions;
