import React, { useEffect, useState } from "react";
import { Upload, message, Row, Col, Typography, Tooltip, Modal } from "antd";
import {
  LinkOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { primaryColor, smilePrivacyUrl, smileTermsUrl } from "colors-config";
import { connect } from "react-redux";
import { labApiTasks } from "models/lab";

import axios from "axios";
import { CONSENT_FORMS } from "constants";
// import "../../assets/styles/components/uploadFilesComponent.scss";
import { AnchorTag } from "components/shared/AnchorTag/AnchorTag";
import styles from "./uploadFiles.module.scss";
import { deleteFormResource, labResourceConfirm } from "api/lab";

const { Dragger } = Upload;
const { Title } = Typography;

const UploadFilesComponent = ({
  lab,
  mode,
  termsAndConditionsUrl,
  dataFormUrl,
  fileListTermsAndConditions,
  fileListForm,
}) => {
  // console.log("fileListForm", fileListForm);
  const [
    uploadFileListTermsAndConditions,
    setUploadFileListTermsAndConditions,
  ] = useState([]);
  const [patientConsentFile, setPatientConsentFile] = useState([]);
  const [dataAcquisitionFile, setDataAcquisitionFile] = useState([]);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState("");
  const [patientConsentUrl, setPatientConsentUrl] = useState("");
  const [uploadFileListForm, setUploadFileListForm] = useState(
    fileListForm || []
  );
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [iframeFile, setIframeFile] = useState(null);

  const fileMapper = resource => {
    const file = {
      ...resource,
      name: resource.file_name,
      type: resource.file_type,
      uid: resource.resource_id,
    };

    if (resource.lab_document_type === CONSENT_FORMS.TERMS_CONDITION) {
      setUploadFileListTermsAndConditions([file]);
    } else if (resource.lab_document_type === CONSENT_FORMS.PATIENT_CONSENT) {
      setPatientConsentFile([file]);
    } else if (resource.lab_document_type === CONSENT_FORMS.DATA_ACQUISITION) {
      setDataAcquisitionFile([file]);
    }
  };

  useEffect(() => {
    if (fileListForm?.length) {
      fileListForm.forEach(resource => {
        fileMapper(resource);
      });
    }
  }, [fileListForm]);

  useEffect(() => {
    setUploadFileListForm(fileListForm);
  }, [fileListTermsAndConditions, fileListForm]);

  useEffect(() => {
    if (lab.labId) {
      _getPolicyLinks();
    }
  }, [lab.labId]);

  const _getPolicyLinks = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_CLINIC_BASE_URL}/clinic/labPartner/getResource?labId=${lab.labId}`
      );

      let termsUrl = smileTermsUrl;
      let privacyUrl = smilePrivacyUrl;
      termsUrl = res?.data?.body?.terms_and_conditions?.url;
      privacyUrl = res?.data?.body?.data_acquisition_form?.url;
      setPrivacyPolicyUrl(privacyUrl);
      setTermsOfServiceUrl(termsUrl);
      setPatientConsentUrl(privacyUrl);
    } catch (error) {
      console.log(error);
    }
  };

  async function uploadFiles(options, type) {
    const { onSuccess, onError, file, onProgress } = options;
    const fileNameArray = file.name.split(".");
    fileNameArray[0] = fileNameArray[0] + "-" + new Date().valueOf();
    const fileName = fileNameArray.join(".");
    console.log("file", file);

    const config = {
      headers: {
        "content-type": file.type,
      },

      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);

        if (percent === 100) {
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const preSignRequestBody = {
        lab_id: lab.labId,
        file_name: fileName,
        resource_type: "file",
        file_type: file.type.split("/")[0] === "application" ? "pdf" : "image",
        lab_document_type: type,
      };
      const preSignedResponse = await labApiTasks.post(
        "lab/resource/add",
        preSignRequestBody
      );

      const accessUrl = preSignedResponse.body.success.accessUrl;
      const uploadUrl = preSignedResponse.body.success.uploadUrl;
      await axios.put(uploadUrl, file, config);

      const response = await labResourceConfirm({
        lab_id: lab.labId,
        file_url: accessUrl,
        file_name: fileName,
        file_type: file.type.split("/")[0] === "application" ? "pdf" : "image",
        lab_document_type: type,
      });

      if (response?.data?.body?.file) {
        fileMapper(response.data.body.file);
      }
      onSuccess("Ok");
    } catch (error) {
      console.log(error);

      onError({ error });
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  }

  const propsTermsAndConditions = {
    name: "file",
    multiple: false,

    onChange(info) {
      // console.log(info);
      setUploadFileListTermsAndConditions([...info.fileList]);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsForm = {
    name: "file",
    multiple: false,
    maxCount: 1,
    onChange(info) {
      setDataAcquisitionFile(info.fileList);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onTermsAndConditionsRemove = async (file, type) => {
    console.log(file, "terms remove");
    const hide = message.loading("Deleting", 0);
    let resourceDeleteBody;
    const fileId = file.uid;
    resourceDeleteBody = {
      lab_id: lab.labId,
      file_url: file.url,
      lab_document_type: type,
    };
    // }

    try {
      await deleteFormResource(resourceDeleteBody);
      setTimeout(hide, 0);
      message.success("File successfully removed");
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);
      message.error("Failed to delete. Please try again later");
    }
  };

  const onFormsRemove = async (file, type) => {
    console.log(file, "file ");
    let resourceDeleteBody;
    const fileId = file.uid;
    const hide = message.loading("Deleting", 0);

    if (type === CONSENT_FORMS.PATIENT_CONSENT) {
      setPatientConsentFile([]);
    }

    if (!file?.resource_id) {
      return;
    }
    resourceDeleteBody = {
      lab_id: lab.labId,
      file_url: file.resource_url,
      lab_document_type: type,
    };
    // }

    try {
      await deleteFormResource(resourceDeleteBody);
      // console.log(res);
      setTimeout(hide, 0);
      message.success("File successfully removed");
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);
      message.error("Failed to delete. Please try again later");
    }
  };

  const viewFile = async (file, type) => {
    console.log(file);
    const frameFile = { ...file, fileUrl: file.url };
    setIframeFile(frameFile);
    setShowIframeModal(true);
  };

  const handleOk = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  const handleCancel = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  return (
    <div
      className="upload-file-container"
      style={{
        backgroundColor: "white",
      }}
    >
      <div>
        <Row>
          <Title level={5}>
            Labs Terms & Conditions / Data Acquisition Form
          </Title>
        </Row>
        <Row>
          <AnchorTag
            url={termsOfServiceUrl}
            target="_blank"
            rel="noreferrer"
            text="View Generic Lab Terms and Conditions"
          />
          &nbsp;, &nbsp;
          <AnchorTag
            url={privacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
            text=" Lab Declaration of Consent Data Acquisition and Processing"
          />
          &nbsp; and &nbsp;
          <AnchorTag
            url={patientConsentUrl}
            target="_blank"
            rel="noreferrer"
            text="  Patient Consent Form"
          />
          {/* <a href={'https://www.smilegeniusdental.com/terms'} target="_blank">
          View Generic Lab Terms and Conditions
        </a>{" "}
        &nbsp; and &nbsp;{" "}
        <a href={'https://www.smilegeniusdental.com/privacy-policy'} target="_blank"s>
          Lab Declaration of Consent Data Acquisition and Processing
        </a> */}
        </Row>
        <div
          className={styles["upload-container"]}
          style={{ marginTop: "1rem" }}
        >
          <Row gutter={[8, 8]}>
            <Col lg={8} md={24} sm={24}>
              <div className={styles["upload_child"]}>
                <p style={{ textAlign: "left", minHeight: "50px" }}>
                  {" "}
                  Lab Terms & Conditions (optional){" "}
                  <Tooltip title="You can upload your own Terms & Conditions">
                    <InfoCircleOutlined
                      style={{ color: primaryColor, marginLeft: "0.1rem" }}
                    />
                  </Tooltip>
                </p>
                <div className={styles["upload-dragger__container"]}>
                  <Dragger
                    customRequest={options =>
                      uploadFiles(options, CONSENT_FORMS.TERMS_CONDITION)
                    }
                    onPreview={file => viewFile(file, "terms")}
                    onRemove={e =>
                      onTermsAndConditionsRemove(
                        e,
                        CONSENT_FORMS.TERMS_CONDITION
                      )
                    }
                    disabled={mode === "view"}
                    accept="application/pdf"
                    iconRender={file => {
                      if (file.type === "url") return <LinkOutlined />;
                    }}
                    style={{
                      width: "100%",
                    }}
                    fileList={uploadFileListTermsAndConditions}
                    {...propsTermsAndConditions}
                  >
                    <div>
                      <UploadOutlined />

                      <p>click or drag file to this to upload</p>
                    </div>
                  </Dragger>
                </div>
              </div>
            </Col>
            <Col lg={8} md={24} sm={24}>
              <div className={styles["upload_child"]}>
                <p style={{ textAlign: "left", minHeight: "50px" }}>
                  Lab Consent Data Acquisition and Processing Form (optional){" "}
                  <Tooltip title="You can upload your own Lab Consent Data Acquisition and Processing Form">
                    <InfoCircleOutlined style={{ color: primaryColor }} />
                  </Tooltip>
                </p>
                <div className={styles["upload-dragger__container"]}>
                  <Dragger
                    customRequest={options =>
                      uploadFiles(options, CONSENT_FORMS.DATA_ACQUISITION)
                    }
                    onRemove={e =>
                      onFormsRemove(e, CONSENT_FORMS.DATA_ACQUISITION)
                    }
                    accept="application/pdf"
                    onPreview={file => viewFile(file, "data")}
                    disabled={mode === "view"}
                    iconRender={file => {
                      if (file.type === "url") return <LinkOutlined />;
                    }}
                    style={{
                      width: "100%",
                    }}
                    fileList={dataAcquisitionFile}
                    {...propsForm}
                  >
                    <div>
                      <UploadOutlined />

                      <p>click or drag file to this to upload</p>
                    </div>
                  </Dragger>
                </div>
              </div>
            </Col>
            <Col lg={8} md={24} sm={24}>
              <div className={styles["upload_child"]}>
                <p style={{ textAlign: "left", minHeight: "50px" }}>
                  Default Patient Consent Form (optional){" "}
                  <Tooltip title="You can upload your own Lab Consent Data Acquisition and Processing Form">
                    <InfoCircleOutlined style={{ color: primaryColor }} />
                  </Tooltip>
                </p>
                <div className={styles["upload-dragger__container"]}>
                  <Dragger
                    customRequest={options =>
                      uploadFiles(options, CONSENT_FORMS.PATIENT_CONSENT)
                    }
                    onRemove={e =>
                      onFormsRemove(e, CONSENT_FORMS.PATIENT_CONSENT)
                    }
                    accept="application/pdf"
                    onPreview={file => viewFile(file, "data")}
                    disabled={mode === "view"}
                    iconRender={file => {
                      if (file.type === "url") return <LinkOutlined />;
                    }}
                    style={{
                      width: "100%",
                    }}
                    fileList={patientConsentFile}
                    name="file"
                    multiple={false}
                    maxCount={1}
                    onChange={info => {
                      setPatientConsentFile(info.fileList);

                      const { status } = info.file;
                      if (status !== "uploading") {
                      }
                      if (status === "done") {
                        message.success(
                          `${info.file.name} file uploaded successfully.`
                        );
                      } else if (status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                      }
                    }}
                  >
                    <div>
                      <UploadOutlined />

                      <p>click or drag file to this to upload</p>
                    </div>
                  </Dragger>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {showIframeModal && (
          <Modal
            title={iframeFile?.name}
            visible={showIframeModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            okButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
            cancelButtonProps={{
              style: { width: "7rem", borderRadius: "30px" },
            }}
            width={1020}
          >
            <div>
              <label>Download File</label>
              <a href={iframeFile?.fileUrl} target="_blank" rel="noreferrer">
                <p>{iframeFile?.name}</p>
              </a>
              <iframe
                title="PDF Viewer"
                frameBorder="0"
                style={{
                  width: "100%",
                  minHeight: "45rem",
                  border: "none",
                  overflow: "hidden",
                }}
                src={iframeFile?.fileUrl}
              ></iframe>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(UploadFilesComponent);
