import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Checkbox,
  Select,
  DatePicker,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  Tooltip
} from "antd";
import { DeleteOutlined, PlusCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import AppTag from "components/shared/AppTag/AppTag";
import { primaryColor } from "colors-config";
import "./phases.scss"

const { Option } = Select;

const Phases = ({ hasCheckbox, form, upperLength, lowerLength }) => {
  const [alignerList, setAlignerList] = useState([]);
  const [checkedPhases, setCheckedPhases] = useState([true]);

  // const [selectedStages, setSelectedStages] = useState([]);

  useEffect(() => {
    const phases = form?.getFieldValue("phases");
    if (phases) {
      setCheckedPhases(phases.map((phase, index) => index === 0 ? true : Boolean(phase.batch)));
    }
  }, [form]);

  useEffect(() => {
    let length = upperLength;
    let data = [];
    if (upperLength < lowerLength) {
      length = lowerLength;
    }

    for (let i = 1; i <= length; i++) {
      let stage_name = `Aligner ${i}`;
      let alignerU = "";
      let alignerL = "";

      if (i <= upperLength) {
        alignerU += "U";
      }
      if (i <= lowerLength) {
        alignerL += "L";
      }
      if (alignerU || alignerL) {
        stage_name += ` (${alignerU}${
          alignerU && alignerL ? "," : ""
        }${alignerL})`;
      }
      data.push({
        value: i,
        label: stage_name,
      });
    }
    setAlignerList(data);
  }, [upperLength, lowerLength]);

  useEffect(() => {
    const updateSelectedStages = () => {
      // const phases = form.getFieldValue("phases") || [];
      // const allSelected = phases.flatMap(phase => phase?.alignerIds || []);
      // setSelectedStages(allSelected);
    };

    const unsubscribe = form.getFieldsValue;
    form.setFieldsValue = (...args) => {
      unsubscribe(...args);
      updateSelectedStages();
    };

    return () => {
      form.setFieldsValue = unsubscribe;
    };
  }, [form]);

  const validateAlignerSequence = (_, value) => {
    const phases = form.getFieldValue("phases")
    let allAligners = phases.flatMap(phase => phase.alignerIds || [])
    allAligners.sort((a, b) => a - b)

    // if(allAligners[0] !== 1){
    //   return Promise.reject("Aligner stages must be sequential and consecutive")
    // }

    // Check if all aligners are sequential
    for (let i = 1; i < allAligners.length; i++) {
      if (allAligners[i] !== allAligners[i-1] + 1) {
        return Promise.reject("Aligner stages are already selected")
      }
    }

    return Promise.resolve()
  }

  const updateAllPhases = () => {
    const phases = form.getFieldValue("phases")
    phases.forEach((_, index) => {
      form.validateFields([["phases", index, "alignerIds"]])
    })
  }

  const getAvailableAligners = (currentPhaseIndex) => {
    const phases = form.getFieldValue("phases") || []
    const selectedAligners = phases.flatMap((phase, index) => 
      index < currentPhaseIndex ? phase.alignerIds || [] : []
    )
    return alignerList.filter(aligner => !selectedAligners.includes(aligner.value))
  }

  const getDisabledDate = index => {
    return current => {
      if (!current) return false;
      if (current < dayjs().startOf('day')) return true;
      const today = dayjs().startOf("day");
      const currentDate = dayjs(current).startOf("day");
      if (currentDate.isBefore(today, "day")) {
        return true; // Disable dates before today
      }
      if (index === 0) return false; // No additional restrictions for the first phase
      const prevPhaseDate = form.getFieldValue([
        "phases",
        index - 1,
        "deliveryDate",
      ]);
      return prevPhaseDate ? currentDate.isBefore(prevPhaseDate, "day") : false;
    };
  };

  const handleCheckboxChange = (index, checked) => {
    if (index === 0) return; // First checkbox can't be changed

    const newCheckedPhases = [...checkedPhases];
    newCheckedPhases[index] = checked;
    // If unchecking, uncheck all subsequent phases
    if (!checked) {
      for (let i = index + 1; i < newCheckedPhases.length; i++) {
        newCheckedPhases[i] = false;
      }
    }

    setCheckedPhases(newCheckedPhases);

    // Update form values
    const phases = form.getFieldValue("phases");
    phases.forEach((phase, i) => {
      phase.batch = newCheckedPhases[i];
    });
    form.setFieldsValue({ phases });
  };

  return (
    <Form.List name="phases">
      {(fields, { add, remove }) => (
        <div className="phasing-container" >
          <Row style={{ marginTop: '35px'}} gutter={[8,32]}>
            {hasCheckbox && <Col lg={1}></Col>}
            <Col lg={3} md={12} sm={24}>
              <label style={{ position: "absolute",top:"-30px", color: hasCheckbox ? '#9CA5BC' : '#000000' }}>{hasCheckbox ? 'Phase':'Phase'}</label>
              </Col>
            <Col lg={hasCheckbox ? 9 : 10} md={12} >
            <label style={{position: "absolute",top:"-30px", color: hasCheckbox ? '#9CA5BC' : '#000000' }}><span style={{ color: 'red'}}>*</span>Aligner Stages</label>
            </Col>
            <Col lg={4} md={12} sm={24}>
            <label style={{position: "absolute",top:"-30px", color: hasCheckbox ? '#9CA5BC' : '#000000' }}><span style={{ color: 'red'}}>*</span>Est. Date of Delivery</label>
            </Col>
            <Col lg={6} md={12} sm={24}>
            <label style={{position: "absolute",top:"-30px",color: hasCheckbox ? '#9CA5BC' : '#000000'  }}>Details</label>
            </Col>
            <Col lg={1}></Col>
          </Row>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => {
            const phase = form.getFieldValue("phases")[index];
            const ordered = Boolean(phase?.patient_order_id);
            const isDisabled = index > 0 && !form.getFieldValue(["phases", index - 1, "batch"]);

            return (
              <>
              <Row gutter={[4, 4]} key={key} style={{ alignItems: "baseline"}}>
                {hasCheckbox && (
                  <Col span={1} >
                    <>
                    <Form.Item
                      {...restField}
                      name={[name, "batch"]}
                      fieldKey={[fieldKey, "batch"]}
                      valuePropName="checked"
                      style={{ justifySelf: 'center'}}
                    >
                      <Checkbox 
                        disabled={ordered || index === 0 || isDisabled}
                        checked={checkedPhases[index]}
                        onChange={(e) => handleCheckboxChange(index, e.target.checked)} />
                    </Form.Item>
                    </>
               
                  </Col>
                )}
                {(ordered && !hasCheckbox) && 
                    <div style={{ marginLeft: '-15px', marginRight:'5px' }}>
                    <CheckCircleFilled style={{ color: primaryColor, fontSize: '14px'}} />
                    </div>
                }
                <Col lg={3} md={24} sm={24}  style={{position:"relative"}}>
                {/* <label style={{ position: "absolute",top:"-30px" }}>Phase#</label> */}
                  <AppTag color={ordered ? "#f0f0f0" : primaryColor} customStyle={{ width: '100%', textAlign: 'center', background: ordered ? "#f0f0f0" : primaryColor, color: ordered ? '#000' : '#fff'}} text={`Phase ${phase?.phaseNo || index + 1}`} />
                </Col>
                <Col lg={hasCheckbox ? 9 : 10} md={24} sm={24}>
                  <Form.Item
                    {...restField}
                    name={[name, "alignerIds"]}
                    fieldKey={[fieldKey, "alignerIds"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one aligner",
                      },
                      { validator: validateAlignerSequence }
                    ]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Select
                      onChange={(value) => {
                        const sortedValue = [...value].sort((a, b) => a - b)
                        const phases = form.getFieldValue("phases")
                        phases[index].alignerIds = sortedValue
                        form.setFieldsValue({ phases })
                      }}
                      className="phasing-multiple"
                      mode="multiple"
                      style={{ paddingBlock: '5px'}}
                      placeholder="Select Aligners"
                      disabled={ordered}
                      maxTagCount="responsive"
                      maxTagPlaceholder={(omittedValues) => (
                        <Tooltip
                          overlayStyle={{
                            pointerEvents: 'none',
                          }}
                          title={omittedValues.map(({ label }) => label).join(', ')}
                        >
                          <span>+{omittedValues.length}...</span>
                        </Tooltip>
                      )}
                    >
                      {alignerList.map((aligner) => (
                        <Option key={aligner.value} value={aligner.value}>
                          {aligner.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <Form.Item
                    {...restField}
                    name={[name, "deliveryDate"]}
                    fieldKey={[fieldKey, "deliveryDate"]}
                    rules={[
                      {
                        required: true,
                        message: "Please add date",
                      }]}
                  >
                    <DatePicker
                      disabled={ordered}
                      style={{ width: "100%" }}
                      disabledDate={getDisabledDate(index)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={6} md={11} sm={12}>
                  <Form.Item
                    {...restField}
                    name={[name, "details"]}
                    fieldKey={[fieldKey, "details"]}
                  >
                    <Input style={{ height: '40px'}} disabled={ordered} placeholder="Write Details" />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  {fields.length > 2 && !ordered && (
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete this Phase?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={e => {
                        remove(name);
                      }}
                      onCancel={() => {}}
                    >
                      <DeleteOutlined
                        style={{
                          color: "black",
                          fontSize: "16px",
                          float: "right",
                          paddingRight: '5px'
                        }}
                      />
                    </Popconfirm>
                  )}
                </Col>
              </Row>
              {(ordered && !hasCheckbox) && <div style={{borderBottom: '1px solid #f0ebeb', width:'96%', marginBottom: '10px', marginTop: '-18px'}} /> }
              </>
            );
          })}
          
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: '50px'
            }}
          >
            <Form.Item>
              <Button
                type="text"
                onClick={() =>{ 
                  const phases = form?.getFieldValue("phases");
                  if(phases.length > 0 ){
                    const phaseNo = phases[phases?.length - 1].phaseNo + 1;
                    add({ phaseNo })
                  } else {
                    add({ phaseNo: 1})
                  }
                }}
                // disabled={fields.length === 3}
                icon={<PlusCircleFilled style={{ color: primaryColor }} />}
              >
                Add Another
              </Button>
            </Form.Item>
          </div>
        </div>
      )}
    </Form.List>
  );
};

export default Phases;

Phases.defaultProps = {
  hasCheckbox: true,
  upperLength: 0,
  lowerLength: 0,
};

Phases.propTypes = {
  hasCheckbox: PropTypes.bool,
  form: PropTypes.object.isRequired,
  upperLength: PropTypes.number,
  lowerLength: PropTypes.number,
};
