import React from 'react';
import { Modal } from 'antd';
import css from "./styles.module.scss";

const PreviewIFrame = ({
    isModalOpen,
    setIsModalOpen,
    iframeUrl
}) => {
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                width="100vw"
                onCancel={handleCancel}
                footer={null}
                className={css["preview-iframe-modal"]}
            >
                <iframe
                    title="PDF Viewer"
                    frameBorder="0"
                    style={{
                        width: "100%",
                        minHeight: "45rem",
                        height: "100%",
                        border: "none",
                        overflow: "hidden",
                    }}
                    src={iframeUrl}
                ></iframe>
            </Modal>
        </>
    );
};
export default PreviewIFrame;