import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";

import {
  Modal,
  Form,
  Input,
  Select,
  Radio,
  Spin,
  message,
  Row,
  Col,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import styles from "assets/styles/modals/customSelect.module.scss";
import { CheckOutlined, CheckCircleOutlined } from "@ant-design/icons";
import CustomEditIcon from "components/shared/Icon/EditIcon";
import { createClinicLabOrderAPI } from "api/clinic";
import { getLabProductList } from "api/lab";
import { patientClinicUpdateStatus, savePhaseOrder } from "api/treatment";
import { createStripePaymentIntentAPI } from "api/common";
import { createConfirmConfig } from "components/shared/ShowConfirmModal/config";
// import PatientAddressModal from "./patientAddressModal/patientAddressModal";
import {
  APPLIANCES_ORDER_KEY,
  PORTAL_NAME,
  TREATMENT_ORDER_KEY,
  ORDER_MODAL_TYPES,
} from "../../../../constants";
import { primaryColor } from "colors-config";
import "./order.scss";
import {
  calculateProductsTotal,
  initialPhasesFormData,
  initialProductsFormData,
  intialPhasingFormData,
  labProductsFormMapper,
} from "utils/treatment";
import LabProductForm from "components/shared/LabProductForm/LabProductForm";
import { determineMimeType } from "utils/prescription";
import { addNewScanFilesAPI } from "api/scans";
import ScanUpload from "components/private/ClinicScans/components/ScanUpload/CustomScanUpload";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Phases from "routes/private/PatientDetails/Common/Phases/Phases";

dayjs.extend(utc);

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const LabOrderModal = forwardRef(
  (
    {
      title,
      // onConfirmation,
      showModal,
      setShow,
      // clinicProducts,
      // partneredLabs,
      patientId,
      clinic,
      // treatmentProgressDet,
      patientAddressRef,
      // orderRetainerClick,
      // patientOrders,
      wholeTreatmentPlan,
      prescriptionForm,
      fromPatientJourneyButton,
      setFromPatientJourneyButton,
      clinicMultipleAddresses,
      treatmentId,
      getOrders,
      modal,
      patientInfoState,
      form,
      setShowAddPatientAddress,
      confirmPatientAddressModal,
      selectedTPVersion,
      setIsEditAddress,
      setFromOrderTab,
      fromOrderTab,
      labForm,
      refinements,
      orderType,
      labId,
      isPhaseOrder,
      alignerOrders,
      treatmentPlans,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      getConfirmOrderConfig,
    }));

    // console.log("orderType", orderType);
    // const [selectedProductType, setSelectedProductType] = useState(null);
    // const [selectedProductName, setSelectedProductName] = useState(null);
    // const [selectedLab, setSelectedLab] = useState(null);
    // const [partneredLabsByProduct, setPartneredLabsByProduct] = useState([]);
    const [isLabInputSpinning, setIsLabInputSpinning] = useState(false);
    const [addressId, setAddressId] = useState();
    const [sendTo, setSendTo] = useState("clinic");
    // const [productTypeList, setProductTypeList] = useState([]);
    // const [productNameList, setProductNameList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [labProducts, setLabProducts] = useState([]);
    // const [labProductLoading, setLabProductLoading] = useState(false);
    const [treatmentVersionList, setTreatmentVersionList] = useState([]);
    const [selectedLabProductName, setSelectedLabProductName] = useState();
    const [orderDescriptionShow, setOrderDescriptionShow] = useState(false);
    //   const [defaultAddress, setDefaultAddress] = useState("");
    const [totalSum, setTotalSum] = useState("0.00");
    const [openDropdownKey, setOpenDropdownKey] = useState(null);
    const [fileLoader, setFileLoader] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState({
      upperarch: null,
      lowerarch: null,
      bitescan: null,
      bitescan2: null,
    });

    const [showPhasing, setShowPhasing] = useState(true);

    const [alignerNumbers, setAlignerNumbers] = useState({
      upper: 0,
      lower: 0,
    });

    const scanTypes = [
      {
        type: "UpperArch",
        label: "Upper Arch",
      },
      {
        type: "LowerArch",
        label: "Lower Arch",
      },
      {
        type: "BiteScan",
        label: "Bitescan",
      },
      {
        type: "BiteScan2",
        label: "Bitescan2",
      },
    ];
    const treatmentType = "appliance";
    const formatKey = scan => scan.replace(/\s+/g, "").toLowerCase();

    useEffect(() => {
      if (clinicMultipleAddresses?.length) {
        let clinicDefaultaddress = clinicMultipleAddresses?.find(
          address => address.default
        );
        if (!clinicDefaultaddress) {
          clinicDefaultaddress = clinicMultipleAddresses[0];
        }
        setAddressId(clinicDefaultaddress?.id || 0);

        // setTimeout(()=>{
        form.setFieldValue("clinicAddress", clinicDefaultaddress?.id || null);
        // },300)
      }
    }, [clinicMultipleAddresses, selectedLabProductName]);

    useEffect(() => {
      if (labId) {
        getAllLabProducts(labId);
      }
    }, [labId]);

    useEffect(() => {
      if (wholeTreatmentPlan.length) {
        const tpArray = refinements.length ? refinements : wholeTreatmentPlan;
        const generateVersionList = tpArray.map(i => ({
          ...i,
          label: i.revision_plan_version
            ? `Refinement Plan Version ${i.revision_plan_version}.0`
            : `Treatment Plan Version ${i.treatment_plan_version}.0`,
          value: i.treatment_plan_version,
        }));

        setTreatmentVersionList(generateVersionList);
      }
    }, [wholeTreatmentPlan]);

    const findTpVersionInfo = (treatmentVersionList, versionNumber = 0) => {
      let searchKey = "approved",
        searchKeyValue = 1;
      if (versionNumber) {
        searchKey = "value";
        searchKeyValue = versionNumber;
      }

      const approvedTreatment = treatmentVersionList.find(
        obj => obj[searchKey] === searchKeyValue
      );
      if (!approvedTreatment) {
        return treatmentVersionList[0];
      }
      return approvedTreatment;
    };

    // console.log("alignerOrders", alignerOrders, treatmentVersionList);

    const setFormValues = useCallback(
      (tpVersion, approved = false) => {
        let getDefaultVersion = null;
        if (!tpVersion && isPhaseOrder) {
          const order = alignerOrders.find(
            order => order.patient_order_id === isPhaseOrder
          );

          if (order) {
            getDefaultVersion = treatmentVersionList.find(
              tp => tp.lab_treatment_id === order.lab_treatment_id
            );
          }
        } else {
          getDefaultVersion = findTpVersionInfo(
            treatmentVersionList,
            approved ? 0 : tpVersion
          );
        }

        if (!getDefaultVersion) {
          return;
        }
        // console.log(getDefaultVersion);
        setAlignerNumbers({
          lower: getDefaultVersion.num_of_lower_aligners,
          upper: getDefaultVersion.num_of_upper_aligners,
        });
        const sum = calculateProductsTotal(getDefaultVersion?.labProductList);
        form.setFieldValue("version", getDefaultVersion?.lab_treatment_id);
        form.setFieldValue(
          "treatmentPlanVersion",
          getDefaultVersion.treatment_plan_version
        );
        form.setFieldValue(
          "patientName",
          patientInfoState?.patient_first_name +
            " " +
            patientInfoState?.patient_last_name
        );

        // console.log('getDefaultVersion',getDefaultVersion);
        const phasing = isPhaseOrder
          ? true
          : getDefaultVersion?.phasingOption === "yes"
          ? true
          : false;
        const filteredPhasing = getDefaultVersion?.phasingList?.filter(phase => !phase?.patient_order_id)
        const phasesList = filteredPhasing?.map((phase, index) => {
            
            return {
              ...phase,
              batch: index === 0 ? true : false,
              deliveryDate: phase?.deliveryDate
                ? dayjs.utc(phase.deliveryDate)
                : null,
            };
          }) || [];

        form.setFieldValue("phasing", phasing);
        if(getDefaultVersion?.phasingList?.length > 0 ){
          form.setFieldValue(
            // phasing: phasing,
            "phases",phasesList
          );
        } else {
          form.setFieldValue(
            // phasing: phasing,
            "phases", intialPhasingFormData());
        }
        

        // console.log('getDefaultVersion?.labProductList',getDefaultVersion?.labProductList?.length > 0);
        setShowPhasing(phasing);
        if (getDefaultVersion?.labProductList &&
          getDefaultVersion?.labProductList?.length > 0 &&
          orderType === TREATMENT_ORDER_KEY
        ) {
          form.setFieldValue(
            "products",
            labProductsFormMapper(getDefaultVersion?.labProductList)
          );
        } else {
          // console.log('testssts');
          form.setFieldValue(
            "products",
            initialProductsFormData(),
            // phases: initialPhasesFormData(),
          );
        }
        if (orderType === TREATMENT_ORDER_KEY) {
          setTotalSum(sum);
        }
      },
      [
        form,
        isPhaseOrder,
        orderType,
        patientInfoState?.patient_first_name,
        patientInfoState?.patient_last_name,
        treatmentVersionList,
        alignerOrders,
      ]
    );

    useEffect(() => {
      if (treatmentVersionList.length) {
        setFormValues(selectedTPVersion, fromOrderTab);
        setFromOrderTab(false);
      } else {
        const tempForm = form.getFieldsValue();
        if(!tempForm?.products?.length){
          form.setFieldValue(
            "products",
            initialProductsFormData(),
            // phases: initialPhasesFormData(),
          );
        }
      }
    }, [
      treatmentVersionList,
      form,
      fromOrderTab,
      selectedTPVersion,
      setFormValues,
      setFromOrderTab,
    ]);
    // console.log("prescriptionForm",prescriptionForm.getFieldValue("phasing"))

    const getAllLabProducts = async labId => {
      try {
        // setLabProductLoading(true);
        const labProductsRes = await getLabProductList(labId);
        // console.log('labProductsRes',labProductsRes);
        if (labProductsRes?.data?.labProducts?.length) {
          const labProductList = labProductsRes.data.labProducts;
          setLabProducts(labProductList);
          const selectedLabProductId = form.getFieldValue("labProductId");
          const findProduct = labProductList.find(
            obj => obj.id == selectedLabProductId
          );
          if (!findProduct) {
            form.setFieldValue("labProductId", null);
          }
        }
        // form.setFieldValue("phasing", prescriptionForm.getFieldValue("phasing"));
      } catch (error) {
        console.log("error", error);
        // message.error("Some Error occured!");
      } finally {
        // setLabProductLoading(false);
      }
    };

    // useEffect(() => {
    //   if (clinicProducts?.length) {
    //     const uniqueProductTypeList = getAllProductTypes(clinicProducts);
    //     setProductTypeList(uniqueProductTypeList);
    //     if (uniqueProductTypeList?.length) {
    //       let defaultSelect = "Treatment";
    //       let defaultProduct = uniqueProductTypeList.find((obj) => obj.product_type == defaultSelect);
    //       if (!defaultProduct) {
    //         defaultProduct = uniqueProductTypeList[0];
    //       }
    //       const product_type_id = defaultProduct.product_type_id.toString();
    //       setSelectedProductType(product_type_id);
    //       form.setFieldValue("productType", product_type_id);
    //       _getPartneredLabsByProduct(product_type_id);

    //       // form.resetFields();
    //     }
    //   }
    // }, [clinicProducts]);

    // useEffect(() => {
    //   if (selectedProductType) {
    //     const getAllProductNameList = clinicProducts.filter(
    //       (obj) => obj.product_type_id == selectedProductType
    //     );
    //     setProductNameList(getAllProductNameList);
    //     let defaultProductName = getAllProductNameList[0].product_name_id.toString();
    //     if (treatmentProgressDet?.treatment_name) {
    //       const getSelectedProductName = getAllProductNameList.find(
    //         (obj) => obj.product_name == treatmentProgressDet?.treatment_name
    //       );
    //       if (getSelectedProductName) {
    //         defaultProductName = getSelectedProductName?.product_name_id.toString();
    //       }
    //     }

    //     setSelectedProductName(defaultProductName);
    //     form.setFieldValue("productName", defaultProductName);
    //   }
    // }, [selectedProductType]);

    // useEffect(() => {
    //   if (partneredLabsByProduct.length) {
    //     const labId = partneredLabsByProduct[0].lab_id;
    //     setSelectedLab(labId);

    //     form.setFieldsValue({ lab: labId });
    //   }
    // }, [partneredLabsByProduct]);

    // const _getPartneredLabsByProduct = async (productId) => {
    //   setIsLabInputSpinning(true);
    //   try {
    //     const partneredLabsRes = await getClinicLabs({
    //       clinicId: clinic.clinic_id,
    //       productTypeId: productId,
    //     });
    //     setPartneredLabsByProduct(partneredLabsRes.body.partnerLabDetails);
    //     setIsLabInputSpinning(false);
    //   } catch (error) {
    //     console.log(error);
    //     setIsLabInputSpinning(false);
    //   }
    // };

    // const getAllProductTypes = (products) => {
    //   const uniqueElements = Object.values(
    //     products.reduce((acc, obj) => {
    //       acc[obj.product_type] = obj;
    //       return acc;
    //     }, {})
    //   );
    //   return uniqueElements;
    // };

    // const shouldFormRender = () => {
    //   return (
    //     productTypeList?.length > 0 &&
    //     selectedProductName &&
    //     selectedProductType &&
    //     partneredLabs.length > 0
    //   );
    // };

    const payAtClinicApi = async (
      treatmentPlanPriceData,
      productPrice,
      treatmentPlanId
    ) => {
      try {
        if (treatmentPlanPriceData?.body?.length) {
          const payObj = { ...treatmentPlanPriceData.body[0] };
          const insertData = {
            clinic_id: wholeTreatmentPlan[0]?.clinic_id,
            patient_id: patientId,
            product_id: payObj.product_id,
            product_type_id: payObj.product_type_id,
            product_name: payObj.product_name,
            product_name_id: payObj.product_name_id,
            product_price: productPrice?.toFixed(2),
            product_currency_id: payObj.product_currency_id,
            currency_name: payObj.currency_name,
            payment_method: "payatclinic",
            paymentType: "offline",
            notify: false,
            lab_treatment_id: treatmentPlanId,
            paidStatus: patientInfoState.payment_status
              ? patientInfoState.payment_status
              : "Lab",
            client: "clinic",
          };
          await createStripePaymentIntentAPI(insertData);

          // _getOrders();
          // treatmentPlans();
          // _getPatientInformation();
        } else {
          // message.error("Some Error Occured!");
        }
      } catch (error) {
        console.log("error", error);
        // message.error("Some Error Occured!");
      }
    };

    const handleOk = () => {
      setFromOrderTab(false);
      if (sendTo === "patient") {
        handleOkPatient();
      } else {
        modal.confirm(getConfirmOrderConfig());
      }
    };

    const handleOkPatient = () => {
      if (sendTo === "patient") {
        if (
          patientInfoState &&
          (!patientInfoState.patient_address ||
            !patientInfoState.patient_address ||
            !patientInfoState.patient_city ||
            !patientInfoState.patient_country ||
            !patientInfoState.patient_county ||
            !patientInfoState.patient_postal_code)
        ) {
          setShowAddPatientAddress(true);
        } else {
          confirmPatientAddressModal();
        }
      }
    };

    const getConfirmOrderConfig = () => {
      const resendPasswordConfig = createConfirmConfig({
        title: "Are you sure you want to continue?",
        content:
          "A notification will be sent to the clinic upon confirming this request that an order has been placed directly by your lab.",
        onOk: placeOrderForPatientViaLab,
        onCancel: () => {},
        okText: "Yes, continue",
        zIndex: 1001, // Ensure this is higher than the default zIndex of modals
      });

      return resendPasswordConfig;
    };

    const placeOrderForPatientViaLab = () => {
      if (sendTo === "patient") {
        labForm.submit();
      } else {
        form.submit();
      }
    };
    function checkLabProductId(products) {
      if (!products?.length) {
        return true;
      }
      return products.find(product => !product.labProductId);
    }

    const updatePhasingOrder = async (val, treatmentPlanId) => {
      const isBatchChecked = val?.phases?.some(ph => ph.batch);

      if (!isBatchChecked) {
        return message.error("Please select at least one phase for order");
      }

      try {
        setLoading(true);

        await savePhaseOrder({
          patient_order_id: isPhaseOrder,
          phases: val.phases.map(el => ({
            ...el,
            deliveryDate: dayjs(el.deliveryDate).format("YYYY-MM-DD"),
            orderNote: val?.orderNote,
            orderVia: val?.orderVia,
            createdFrom: PORTAL_NAME.LAB,
            shipping_address: val.sendTo,
            othersDescription: val?.othersDescription,
            clinicAddressId: val?.clinicAddress || 0,
          })),
          lab_treatment_id: treatmentPlanId,
          phaseOrder: true,
        });

        message.success("Phasing Order Placed");
        getOrders();
        treatmentPlans();
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    const onFinish = async val => {
      //  console.log("vallllll", val);

      if (val.phasing) {
        if (!val?.phases?.length) {
          return message.error("Phases are missing");
        }

        if (val?.phases?.length) {
          const testPhses = val.phases.some(phase => phase.batch);

          if (!testPhses) {
            return message.error(
              "Please select at least one phase to place the order"
            );
          }
        }
      }

      const hasNullLabProductId = checkLabProductId(val.products);
      if (val?.products?.length > 1) {
        if (hasNullLabProductId) {
          return message.error("Please select a product or delete empty one");
        }
      }
      if (val.phasing) {
        // let alignerCount = 0;
        // val.phases.forEach(el => {
        //   alignerCount = alignerCount + el.alignerIds.length;
        // });

        const lastAlignerId = val?.phases?.reduce((_, current) => {
          const lastValue = current.alignerIds?.[current.alignerIds.length - 1];
          return lastValue !== undefined ? lastValue : _;
        }, null);

        let totalLength = alignerNumbers.upper;

        if (alignerNumbers.upper < alignerNumbers.lower) {
          totalLength = alignerNumbers.lower;
        }

        if (totalLength > lastAlignerId) {
          return message.error("Please select all aligners in phases");
        }
      }

      try {
        setLoading(true);
        const versionNo = form.getFieldValue("version");
        const treatmentPlanDetails = treatmentVersionList.find(
          obj => obj.lab_treatment_id == versionNo
        );
        val.refinement = false;
        if (
          treatmentPlanDetails &&
          treatmentPlanDetails.revision_plan_version
        ) {
          val.refinement = true;
        }
        const treatmentPlanId = treatmentPlanDetails?.lab_treatment_id
          ? treatmentPlanDetails?.lab_treatment_id
          : treatmentId;
        if (isPhaseOrder && TREATMENT_ORDER_KEY === orderType) {
          return updatePhasingOrder(val, treatmentPlanId);
        }
        // const clinicProductId = { ...val }.productType;
        val.productType = val.refinement ? 6 : 1;
        // const findProduct = clinicProducts.find(
        //   (obj) => obj.product_type_id == (val.refinement ? 6 : 1)
        // );
        // if(!findProduct){
        //   message.error(`Clinic does not have ${val.refinement? "refinement" :"treatment"} product available.`);
        //   return
        // }
        // if (findProduct) {
        // val.productType = findProduct.product_type_id;
        // val.productName = findProduct.product_name_id;

        // val.productCurrency = findProduct.product_currency_id;
        // val.productId = findProduct.product_id;
        // val.productPrice = findProduct.free ? 0 : findProduct.price;

        val.createdFrom = PORTAL_NAME.LAB;

        // const treatmentPlanPriceData = await getTreatmentPlanPrice(patientId);

        // if (clinicProductId == 1 && !patientOrders?.length) {
        //   await payAtClinicApi(treatmentPlanPriceData, val.productPrice,treatmentPlanId);
        // }

        // if (
        //   treatmentPlanPriceData?.body?.length &&
        //   treatmentPlanPriceData.body[0].product_type_id === 6 &&
        //   !treatmentPlanPriceData.body[0].free
        // ) {
        //   const findRefinementOrder = patientOrders.find(
        //     (obj) => obj.product_type_id == 6 && obj.payment_status == 2
        //   );
        //   if (!findRefinementOrder) {
        //     await payAtClinicApi(treatmentPlanPriceData, val.productPrice,treatmentPlanId);
        //   }
        // }

        const orderModel = {
          clinic_id: clinic.clinic_id,
          patient_id: patientId,
          // product_id: val.productId,
          product_type_id: +val.productType,
          // product_name_id: +val.productName,
          // product_price: val.productPrice,
          // product_currency_id: val.productCurrency,
          lab_id: labId,
          phasing: val.phasing,
          phases: val.phasing
            ? val.phases.map((phase, index) => {
                return {
                  ...phase,
                  phaseNo: index + 1,
                  lab_treatment_id: treatmentPlanId,
                  deliveryDate:dayjs(phase.deliveryDate).format("YYYY-MM-DD")
                };
              })
            : [],
          shipping_address: val.sendTo,
          lab_treatment_id: treatmentPlanId,
          clinicAddressId: val?.clinicAddress || 0,
          labProductId: val?.labProductId,
          newOrder: fromPatientJourneyButton ? false : true,
          quantity: val?.labProductId ? val?.quantity : 0,
          refinement: val?.refinement ? val.refinement : false,
          orderNote: val?.orderNote,
          createdFrom: val.createdFrom,
          orderVia: val?.orderVia,
          othersDescription: val?.othersDescription,
          orderLabProductList: val?.products,
          others: orderType === APPLIANCES_ORDER_KEY,
        };
        // console.log(orderModel)
        // return
        if (orderType !== APPLIANCES_ORDER_KEY) {
          const approveTPPayload = {
            lab_treatment_id: treatmentPlanId,
            approval_status: "approved",
            // free
            isRefinement: orderModel.refinement,
            sendToPatient: false,
            sendEmailToLab:false
          };

          await patientClinicUpdateStatus(approveTPPayload);
        }

        const reponse = await createClinicLabOrderAPI(orderModel);
        //  console.log("reponsereponse", reponse)
        const filesList = Object.values(uploadedFiles)
          .filter(file => file !== null)
          .map(file => ({
            fileName: file.fileName,
            contentType: file.type || determineMimeType(file.fileName, file),
            imageView: file.imageView,
            type: "file",
          }));

        if (filesList.length && reponse?.body?.orderId) {
          const addNewData = {
            notify: false,
            patientId,
            model: treatmentType,
            createdFrom: PORTAL_NAME.LAB,
            addFrom: "order",
            caseId: 1,
            orderId: reponse.body.orderId,
            filesList,
          };

          await addNewScanFilesAPI(clinic.clinic_id, addNewData);
        }

        message.success("Order placed successfully");
        getOrders();
        treatmentPlans();
        // }
      } catch (err) {
        console.log("err", err);
        message.error("Kindly select correct product");
      } finally {
        setLoading(false);
        setShow(false);
      }
    };

    const onSendToChange = e => {
      setSendTo(e.target.value);
    };

    const onOrderViaChange = value => {
      // console.log("value", value);
      setOrderDescriptionShow(value === "others");
    };

    const onTPVersionChange = value => {
      setFormValues(value);
    };

    const handleEdit = () => {
      setShowAddPatientAddress(true);
      setIsEditAddress(true);
    };

    const handleProductNameChange = (value, name, fieldKey) => {
      if (!value) {
        form.setFields([
          {
            name: [`products`, name, "arches"],
            value: null,
          },
          {
            name: [`products`, name, "netPrice"],
            value: null,
          },
          {
            name: [`products`, name, "total"],
            value: null,
          },
          {
            name: [`products`, name, "quantity"],
            value: null,
          },
          {
            name: [`products`, name, "quantity"],
            value: null,
          },
        ]);
        calculateTotal();
        return;
      }
      const values = form.getFieldsValue().products || [];
      const isDuplicate = values.find(
        (item, index) => item?.labProductId === value && index !== name
      );
      if (isDuplicate) {
        message.warning("This product has already been added. Please select a different product.");
        form.setFields([
          {
            name: [`products`, name, "labProductId"],
            value: null,
          },
        ]);
        return;
      }
      const product = labProducts.find((item) => item.id === value);
      const quantity = product.type === "Package" ? 1 : 1;
      const total = product.price * quantity;
      form.setFields([
        {
          name: [`products`, name, "arches"],
          value: product.arches,
        },
        {
          name: [`products`, name, "netPrice"],
          value: product.price,
        },
        {
          name: [`products`, name, "newPrice"],
          value: product.price,
        },
        {
          name: [`products`, name, "quantity"],
          value: quantity,
        },
        {
          name: [`products`, name, "total"],
          value: total,
        },
        {
          name: [`products`, name, "free"],
          value: product.free,
        },
        {
          name: [`products`, name, "quantityType"],
          value: product.quantityType,
        },
        {
          name: [`products`, name, "currency"],
          value: product.currency,
        },
  
      ]);
      setSelectedLabProductName(value);
      calculateTotal();
    };

    const onQuantityChange = (value, name) => {
      const netPricePerUnit = form.getFieldValue([`products`, name, `netPrice`]);
      const totalPrice = netPricePerUnit * value;
      form.setFields([
        {
          name: [`products`, name, "quantity"],
          value: value,
        },
        {
          name: [`products`, name, "total"],
          value: totalPrice,
        },
      ]);
      calculateTotal();
    };

    const calculateTotal = () => {
      const values = form.getFieldsValue().products || [];
      const sum = calculateProductsTotal(values);
      setTotalSum(sum);
    };


    const onSaveDiscount = (name) => {
      const productPrice = form.getFieldValue(["products", name, "newPrice"]);
      const discountPercentage = form.getFieldValue([`products`, name, "discountPercentage"]) || 0;
      const discountAmount = (discountPercentage / 100) * productPrice;
      const discountPrice =  productPrice - discountAmount
      form.setFields([
        {
          name: [`products`, name, "netPrice"],
          value: discountPrice,
        },
      ]);
      setOpenDropdownKey(null)
      calculateTotal()
    };

    const handleFileUpload = (file, type) => {
      const formattedKey = formatKey(type);
      setUploadedFiles(prevFiles => ({
        ...prevFiles,
        [formattedKey]: file,
      }));
    };

    const handleFileRemove = type => {
      const formattedKey = formatKey(type);
      setUploadedFiles({ ...uploadedFiles, [formattedKey]: null });
    };

    const onClose = () => {
      setShow(false);
      setFromPatientJourneyButton(false);
      setFromOrderTab(false);
      form.resetFields();
    };
    const cancelSubmit = () =>{
      if (orderType === APPLIANCES_ORDER_KEY) {
        const hasFiles = Object.values(uploadedFiles).some(
          file => file !== null
        );
        if (hasFiles) {
          return showConfirm();
        } else {
          onClose();
        }
      } else {
        onClose();
      }
    }

    const handleConfirmCancel = () => {
      const cancelConfig = createConfirmConfig({
        title: "Close order form",
        content: "Any unsaved changes will be discarded. Do you want to proceed?",
        onOk: () => cancelSubmit(),
        onCancel: () => {},
        okText: "Ok",
      });
      modal.confirm(cancelConfig);
    };

    const handleCancel = () => {
      if (form.isFieldsTouched()) {
        handleConfirmCancel()
      } else {
        cancelSubmit()
      }
    };

    const showConfirm = () => {
      confirm({
        title: "Close Upload Scans",
        icon: <ExclamationCircleFilled />,
        content:
          "Any unsaved changes will be discarded. Do you want to proceed?",
        okButtonProps: {
          style: {
            width: "7rem",
            background: primaryColor,
            borderColor: primaryColor,
            borderRadius: "30px",
            color: "#fff",
          },
        },
        cancelButtonProps: {
          style: {
            width: "7rem",
            borderRadius: "30px",
          },
        },
        onOk() {
          onClose();
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    };

    // console.log(form.getFieldsValue());

    return (
      <div>
        <Modal
          okText="Place Order"
          width={1000}
          title={title}
          open={showModal}
          confirmLoading={loading}
          onOk={handleOk}
          onCancel={handleCancel}
          className="place-order-automation"
          okButtonProps={{
            style: { minWidth: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{
            style: { minWidth: "7rem", borderRadius: "30px" },
          }}
          zIndex={1000}
        >
          <Spin spinning={isLabInputSpinning}>
            <Form
              form={form}
              style={{
                width: "100%",
              }}
              name="complex-form"
              layout={"vertical"}
              onFinish={onFinish}
              initialValues={{
                // clinicAddress: defaultAddress?.id,
                patientId: patientId,
                // productType: selectedProductType,
                // productName: selectedProductName,
                sendTo: "clinic",
                // lab: selectedLab,
              }}
              className="labOrderModal-container"
            >
              <div>
                <Row gutter={[16, 16]}>
                  <Col  lg={12} md={24} sm={24}>
                    <Form.Item
                      label="Patient Id"
                      name="patientId"
                      className="inline-form-left"
                    >
                      <Input size="large" disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24} sm={24}>
                    <Form.Item
                      label="Patient Name"
                      name="patientName"
                      className="inline-form-left"
                    >
                      <Input size="large" disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
                {orderType === TREATMENT_ORDER_KEY && (
                  <Row gutter={[16, 16]}>
                    <Col lg={12} md={24} sm={24}>
                      <Form.Item
                        label="Order Placed Via"
                        name="orderVia"
                        className="inline-form-right"
                      >
                        <Select
                          placeholder="Select"
                          size="large"
                          style={{ width: "100%" }}
                          onChange={onOrderViaChange}
                        >
                          <Option value="paper">Paper</Option>
                          <Option value="text">Text</Option>
                          <Option value="email">Email</Option>
                          <Option value="phone call">Phone Call</Option>
                          <Option value="others">Others</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col lg={12} md={24} sm={24}>
                      <Form.Item
                        name="treatmentPlanVersion"
                        label="Choose TP Version"
                      >
                        <Select
                          disabled={isPhaseOrder}
                          onChange={onTPVersionChange}
                          placeholder="Select Treatment Version"
                        >
                          {treatmentVersionList?.map((item, index) => {
                            return (
                              <Option
                                className="treatment-selectbox-multi"
                                key={index}
                                value={item.value}
                              >
                                {item.label}{" "}
                                {item.approved ? <CheckCircleOutlined /> : ""}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {orderDescriptionShow ? (
                  <Row gutter={[16, 16]}>
                    <Col lg={24} md={24} sm={24}>
                      <Form.Item
                        name="othersDescription"
                        style={{ textAlign: "left" }}
                        label=""
                      >
                        <TextArea
                          placeholder="Write Details"
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
                <div className="dynamic-products">
                  <LabProductForm
                    isPhaseOrder={isPhaseOrder}
                    form={form}
                    labProducts={labProducts}
                    handleProductNameChange={handleProductNameChange}
                    onQuantityChange={onQuantityChange}
                    totalSum={totalSum}
                    onSaveDiscount={onSaveDiscount}
                    calculateTotal={calculateTotal}
                    openDropdownKey={openDropdownKey}
                    setOpenDropdownKey={setOpenDropdownKey}
                  />
                  {orderType === TREATMENT_ORDER_KEY && (
                    <>
                      <div className="border-line" />
                      <div style={{ width: "100%", margin: "10px 20px" }}>
                        <Form.Item
                          name="phasing"
                          // style={{ textAlign: "left", marginTop: "2rem" }}
                          label="Phasing"
                          disabled={isPhaseOrder}
                        >
                          <Radio.Group
                            onChange={e => {
                              // console.log(value)
                              setShowPhasing(e.target.value);
                            }}
                          >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      {showPhasing && (
                        <Phases
                          lowerLength={alignerNumbers.lower}
                          upperLength={alignerNumbers.upper}
                          form={form}
                        />
                      )}
                    </>
                  )}
                </div>
                <div style={{ marginTop: '20px'}}>
                <Form.Item
                  name="orderNote"
                  style={{ textAlign: "left" }}
                  label="Order note"
                >
                  <TextArea
                    placeholder="Write Note..."
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
                </div>
                <Form.Item
                  name="sendTo"
                  style={{ textAlign: "left", marginTop: "2rem" }}
                  label="Shipping Address"
                >
                  <Radio.Group onChange={onSendToChange}>
                    <Radio value="clinic">Same as clinician address</Radio>
                    <Radio style={{ marginLeft: "3rem" }} value="patient">
                      Same as patient address
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {sendTo === "patient" ? (
                  <div style={{ display: "flex", gap: "6px" }}>
                    <div>Patient Address:</div>
                    {patientAddressRef.current?.trim() ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleEdit}
                      >
                        <span>{patientAddressRef.current?.trim()}</span>
                        <CustomEditIcon />
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </div>
                ) : (
                  <div className="custom-select">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please choose a location",
                        },
                      ]}
                      label="Choose Location"
                      name="clinicAddress"
                    >
                      <Select
                        onChange={value => {
                          setAddressId(value);
                        }}
                      >
                        {clinicMultipleAddresses?.map((el, index) => {
                          return (
                            <Option value={el.id} key={index}>
                              <div className={styles["option-grid"]}>
                                <span className={styles["option-label"]}>
                                  {el.label}
                                </span>
                                <span className={styles["option-description"]}>
                                  {` ${el.address ? el.address : ""} ${
                                    el.county ? el.county : ""
                                  } ${el.country ? el.country : ""}${
                                    el.zipCode ? ", " : ""
                                  }${el.zipCode ? el.zipCode : ""} `}
                                </span>
                                <span className={styles["option-checkmark"]}>
                                  {addressId === el.id ? (
                                    <CheckOutlined />
                                  ) : null}
                                </span>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </div>

              <Spin spinning={fileLoader}>
                {orderType === APPLIANCES_ORDER_KEY ? (
                  <div style={{ padding: "0px 0px", width: "80%" }}>
                    <label>Scan Files (Optional)</label>
                    <Row gutter={16}>
                      {scanTypes.map(scan => (
                        <Col span={6} key={scan.type}>
                          <ScanUpload
                            scanType={scan.type}
                            scanLabel={scan.label}
                            treatmentName={treatmentType}
                            uploadedFile={
                              uploadedFiles[formatKey(scan.type)]?.file
                            }
                            onFileUpload={handleFileUpload}
                            onFileRemove={handleFileRemove}
                            patientId={patientId}
                            clinicId={clinic.clinic_id}
                            setFileLoader={setFileLoader}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}
              </Spin>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
);

export default LabOrderModal;
