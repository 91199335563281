import { useState, useEffect } from "react";
import { Upload, Popconfirm, message } from "antd";
import ScanFileIcon from "components/shared/Icon/ScanFileIcon";
import styles from "./scan.scss";
import { primaryColor } from "colors-config";
import { determineMimeType, getScanFileImageType } from "utils/prescription";
import {
  addConfirmScanFilesAPI,
  generateSignedUrlAPI,
  uploadFileToS3API,
} from "api/scans";
import { v4 as uuidv4 } from "uuid";
import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import FileViewModal from "components/shared/FileViewModal/FileViewModal";
import { getScanName } from "utils/order";
import { downloadS3File } from "api/patient";
import { PORTAL_NAME } from "constants";
import dayjs from 'dayjs';

const ScanUpload = ({
  scanType,
  scanLabel,
  treatmentName,
  onFileUpload,
  onFileRemove,
  uploadedFile,
  patientId,
  clinicId,
  prescriptionUpload,
  uploadedFiles,
  setUploadedFiles,
  setScansInfo,
  uploadConfirm,
  required
}) => {
  const [file, setFile] = useState();
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [iframeFile, setIframeFile] = useState(null);

  useEffect(() => {
    setFile(uploadedFile);
  }, [uploadedFile]);

  async function handleUpload({ file, onProgress, onSuccess, onError }) {
    try {
      const contentType = file.type || determineMimeType(file.name, file);
      if (!contentType) {
        message.error(`Unsupported file type`);
        return onError(new Error("Unsupported file type"));
      }

      const fileNameArray = file.name.split(".");
      fileNameArray[0] = fileNameArray[0] + "-" + uuidv4();
      const fileName = fileNameArray.join(".");
      const requestData = {
        fileName: fileName,
        contentType,
        imageView: getScanFileImageType(scanType),
        // contentType,
        patientId,
        // imageView: scanType,
        type: "file",
        model: "prescription",
        createdFrom: PORTAL_NAME.LAB,
        caseId: 1,
      };

      file.imageView = scanType;
      file.fileName = fileName;

      const { data: url } = await generateSignedUrlAPI(clinicId, requestData);
      await uploadFileToS3API(url, file, contentType, onProgress);
      setFile(file);
      onFileUpload(file, scanType);
      onSuccess("Upload successful");

      if (prescriptionUpload) {
        const addNewData = {
          notify: true,
          patientId,
          model: "prescription",
          createdFrom: PORTAL_NAME.LAB,
          type: "file",
          caseId: 1,
          fileName: fileName,
          contentType: file.type || determineMimeType(file.fileName, file),
          imageView: getScanFileImageType(scanType),
        };

        await uploadConfirm(addNewData)

        // const { data } = await addConfirmScanFilesAPI(clinicId, addNewData);
        // const finalRes = initializeUploadedFiles(data, uploadedFiles);
        // setUploadedFiles(finalRes);
        // const scanInfo = {
        //   uploadedBy: "lab",
        //   uploadedDate: dayjs().format('YYYY-MM-DD'),
        //   scanType: "prescription",
        // }
        // setScansInfo(scanInfo);
        // setFile(data);
      }
      message.success(`${fileName} file uploaded successfully.`);
    } catch (error) {
      console.error("Upload failed:", error);
      onError(error);
      message.error(`File upload failed: ${error.message}`);
    }
  }

  const initializeUploadedFiles = (newFile, existingFiles) => {
    const updatedFiles = { ...existingFiles };

    const formattedKey = newFile.patient_image_view;

    if (updatedFiles.hasOwnProperty(formattedKey)) {
      updatedFiles[formattedKey] = {
        ...newFile,
        uploadedFrom: newFile.createdFrom,
        uploadDate: newFile.createdAt,
      };
    }

    return updatedFiles;
  };

  const handleDelete = file => {
    setFile(null);
    onFileRemove(file);
  };

  const fileClickHandler = file => {
    setIframeFile(file);
    setShowIframeModal(true);
  };

  const handleOk = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };

  const handleCancel = () => {
    setShowIframeModal(false);
    setIframeFile(null);
  };
  const downloadHandler = file => {
    window.open(downloadS3File(file?.name));
    // console.log("file", file)
    // if (file?.hash && file?.meta?.extension) {
    //   const fileName = file.hash + "." + file.meta.extension;
    //   if (fileName) {
    //     window.open(downloadS3File(fileName));
    //   }
    // }
    // const fileName = file.resource_url?.split('/')[1];
    // if(fileName){
      
    //   window.open(downloadS3File(fileName));
    // }
  };

  const showRequired = () => {
    return required ? <span style={{ color: "red", fontSize:"14px", lineHeight: 1 }}>*</span> : "";
  };
  return (
    <div className="scan-file-upload-container">
      <p className="scan-name">
        <>
          {showRequired()}
          {getScanName(scanLabel)}
        </>
      </p>
      {file ? (
        <div className="scan-file-display">
          <ScanFileIcon
          // style={{ color: primaryColor }}
          // className="scan-file-icon"
          />
          <p className="fileName">{file.name}</p>
          <div className="fileMask">
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this scan?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(file)}
            >
              <DeleteOutlined style={{ fontSize: "20px" }} />
            </Popconfirm>
            {file.url && (
              <DownloadOutlined
                className={styles.downIcon}
                style={{ margin: "0.5rem", fontSize: "20px" }}
                onClick={() => downloadHandler(file)}
              />
            )}

            {file.url && (
              <EyeOutlined
                className={styles.viewIcon}
                onClick={() => fileClickHandler(file)}
                style={{ fontSize: "20px" }}
              />
            )}
          </div>
        </div>
      ) : (
        <Upload customRequest={handleUpload} className="scan-file-upload">
          <p className="ant-upload-drag-icon scan-upload-drag-icon">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="upload"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
            </svg>
          </p>
          <p className="ant-upload-text scan-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload>
      )}

      {showIframeModal && (
        <FileViewModal
          isModalVisible={showIframeModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          iframeFile={iframeFile}
        />
      )}
    </div>
  );
};

export default ScanUpload;
