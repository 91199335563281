import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_TPVIEWER_BACKEND_URL || "http://localhost:8000",
  timeout: 3600000,
});

export const getUploadSignedUrl = data => {
  return instance({
    method: "POST",
    url: "/api/tpviewerdraft/generateUploadSignedUrl",
    data,
  });
};

export const saveFiles = data =>
  instance({
    method: "POST",
    url: "/api/tpviewerdraft/addFilesToDraft",
    data,
  });

export const uploadService = async (signedUrl, file, onProgress) => {
  if (!signedUrl) {
    Promise.reject("no signed url provided");
  }
  // let contentType = "model/stl";
  // if (file.type.includes("obj")) {
  //   contentType = "model/obj";
  // } else if (file.type.includes("stl")) {
  //   contentType = "model/stl";
  // } else {
  //   contentType = file.type;
  // }

  const config = {
    headers: {
      "content-type": file.type,
    },

    onUploadProgress: event => {
      const percent = Math.round((event.loaded / event.total) * 100);
      if (onProgress) {
        onProgress({ percent });
      }
    },
  };

  const response = await axios.put(signedUrl, file, config);
  return response;
};


export const save3DLinkToTreatment = data =>
  instance({
    method: "POST",
    url: "/api/tpviewerTreatment/saveTPViewerLinkToTreatment",
    data,
  });


export const update3DLinkToTreatment = data =>
  instance({
    method: "POST",
    url: "/api/tpviewerTreatment/updateTPViewerLinkToTreatment",
    data,
  });

export const getTPDraftDetails = draftUniqueId =>
  instance({
    method: "GET",
    url: "/api/tpviewerdraft/getDraftDetails/" + draftUniqueId,
  });

export const getTPViewDetails = draftUniqueId =>
  instance({
    method: "GET",
    url: "/api/tpviewerTreatment/viewTPViewerDetails/" + draftUniqueId,
  });

export const convertObjToGLB = data => {
  return instance({
    method: "POST",
    url: "/api/tpviewerdraft/convertFile",
    data,
  });
};